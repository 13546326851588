<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass" jhiTranslate="vendor.employee.name">
                        Employee
                    </h5>
                    <!-- <button pButton pRipple label="Delete" icon="pi pi-trash" class="p-button-danger"
                          (click)="deleteSelectedAgencys()"
                          [disabled]="!selectedAgencys || !selectedAgencys.length"></button> -->
                </ng-template>
                <ng-template pTemplate="right">
                    <button pButton pRipple icon="pi pi-plus" class="p-button-secondary custom-button  new-button" (click)="openNew()"
                        styleClass="button">
                        <span class="button-label" jhiTranslate="common.newbutton"></span>
                    </button>
                    <!-- <p-fileUpload mode="basic" accept="image/*" [maxFileSize]="1000000" label="Import"
                          chooseLabel="Import" class="p-mr-2 p-d-inline-block"></p-fileUpload>
                      <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help"></button> -->
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="row">
        <div #customerTable id="entities" class="col-12 px-4">
            <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult"
                (searchParam)="onLazyLoad($event)" (emitRow)="onSelectRow($event)" [disableDeleteIcon]="true"
                [disableEditIcon]="false"></custom-filter-table>
        </div>
    </div>
    <p-dialog #dialog [(visible)]="employeeDialog" [style]="{ width: '850px' }" [modal]="true"
        styleClass="ib-dialog" (onHide)="hideDialog()">
        <p-header [ngClass]="fontService.headingClass">
        {{ "vendor.employee.add" | translate }}
      </p-header>
        <ng-template pTemplate="content">
            <div class="col-12">
                <div class="form-inner-panel">
                    <div class="row gy-2">
                        <div class="col-12">
                            <h6 [ngClass]="fontService.headingClass" jhiTranslate="vendor.employee.employeeDetails">
                                Employee Details
                            </h6>
                        </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" for="department" 
                                [ngClass]="fontService.labelClass" jhiTranslate="vendor.employee.employeeType">Employee Type</label>
                                <ng-select [appendTo]="'.p-dialog'" id="department" bindLabel="name" appearance="outline" [closeOnSelect]="true"
                                    [clearable]="true" class="form-control" [items]="employeeTypeList"
                                    (change)="onchangeEmployeeType()" [(ngModel)]="employee.employeeTypeDto"
                                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !employee.employeeTypeDto }">
                                </ng-select>
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" for="department"
                                [ngClass]="fontService.labelClass" jhiTranslate="vendor.employee.subEmployeeType">Sub Employee Type</label>
                                <ng-select [appendTo]="'.p-dialog'" id="department" bindLabel="name" appearance="outline" [closeOnSelect]="true"
                                    [clearable]="true" class="form-control" [items]="subEmployeeList"
                                    [(ngModel)]="employee.subEmployeeTypeDto"
                                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !employee.subEmployeeTypeDto }">                                    
                                </ng-select>
                            </div>
                            <div class="col-sm-6 col-md-4" *ngIf="employee?.subEmployeeTypeDto">
                                <label class="form-control-label req" for="name"
                                [ngClass]="fontService.labelClass" jhiTranslate="vendor.name">Name</label>
                                <input type="text" class="form-control" uppercase [(ngModel)]="employee.name" name="name"
                                    autocomplete="off" maxlength="60"
                                    oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                    onblur="this.value =this.value.trim();"
                                    style="text-transform:uppercase" id="name" 
                                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !employee.name }"/>
                            </div>
                            <div class="col-sm-6 col-md-4" *ngIf="employee?.subEmployeeTypeDto">
                                <label class="form-control-label req" for="name"
                                [ngClass]="fontService.labelClass" jhiTranslate="vendor.employee.grade">grade</label>
                                <input type="text" class="form-control" [(ngModel)]="employee.grade" name="name"
                                    onblur="this.value =this.value.trim();"
                                    autocomplete="off" maxlength="60" style="text-transform:uppercase" id="name"  
                                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !employee.grade }"/>
                            </div>
                            <div class="col-sm-6 col-md-4" *ngIf="employee?.subEmployeeTypeDto">
                                <label class="form-control-label req" for="name"
                                [ngClass]="fontService.labelClass" jhiTranslate="vendor.employee.gpfCpsNo">Gpf Cps No</label>
                                <input type="text" class="form-control" [(ngModel)]="employee.gpfCpsNo" name="name"
                                oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                                autocomplete="off" id="name" maxlength="25"   
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !employee.gpfCpsNo }"/>
                            </div>
                            <div class="col-sm-6 col-md-4" *ngIf="employee?.subEmployeeTypeDto">
                                <label class="form-control-label req" for="name"
                                [ngClass]="fontService.labelClass" jhiTranslate="vendor.employee.dutyPay">Duty Pay</label>
                                <input type="text" class="form-control" [(ngModel)]="employee.dutyPay" name="name"
                                    autocomplete="off" maxlength="25" id="name"
                                    oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" 
                                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !employee.dutyPay }"/>
                            </div>
                            <div class="col-sm-6 col-md-4" *ngIf="employee?.subEmployeeTypeDto">
                                <label class="form-control-label req" for="name"
                                [ngClass]="fontService.labelClass" jhiTranslate="vendor.employee.dearnessAllowance">Dearness Allowance</label>
                                <input type="text" class="form-control" [(ngModel)]="employee.dearnessAllowance"
                                    name="name" autocomplete="off" maxlength="25" id="name" 
                                    oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" 
                                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !employee.dearnessAllowance }"/>
                            </div>
                            <div class="col-sm-6 col-md-4" *ngIf="employee?.subEmployeeTypeDto">
                                <label class="form-control-label req" for="name"
                                [ngClass]="fontService.labelClass" jhiTranslate="vendor.employee.houseRentAllowance">House Rent Allowance</label>
                                <input type="text" class="form-control" [(ngModel)]="employee.houseRentAllowance"
                                    name="name" autocomplete="off" id="name" maxlength="25"
                                    oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" 
                                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !employee.houseRentAllowance }"/>
                            </div>
                            <div class="col-sm-6 col-md-4" *ngIf="employee?.subEmployeeTypeDto">
                                <label class="form-control-label req" for="name"
                                [ngClass]="fontService.labelClass" jhiTranslate="vendor.employee.medicalAllowance">Medical Allowance</label>
                                <input type="text" class="form-control" [(ngModel)]="employee.medicalAllowance"
                                    name="name" autocomplete="off" maxlength="25" id="name"
                                    oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" 
                                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !employee.medicalAllowance }"/>
                            </div>
                            <div class="col-sm-6 col-md-4" *ngIf="employee?.subEmployeeTypeDto">
                                <label class="form-control-label req" for="name"
                                [ngClass]="fontService.labelClass" jhiTranslate="vendor.employee.gpf">General Provident Fund</label>
                                <input type="text" class="form-control" [(ngModel)]="employee.generalProvidentFund"
                                    name="name" autocomplete="off" maxlength="25" id="name"
                                    oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" 
                                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !employee.generalProvidentFund }"/>
                            </div>
                            <div class="col-sm-6 col-md-4" *ngIf="employee?.subEmployeeTypeDto">
                                <label class="form-control-label req" for="name"
                                [ngClass]="fontService.labelClass" jhiTranslate="vendor.employee.newHealthInsurance">New Health Insurence</label>
                                <input type="text" class="form-control" [(ngModel)]="employee.newHealthInsurence"
                                    name="name" autocomplete="off" id="name" maxlength="25"
                                    oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" 
                                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !employee.newHealthInsurence }"/>
                            </div>
                            <div class="col-sm-6 col-md-4" *ngIf="employee?.subEmployeeTypeDto">
                                <label class="form-control-label req" for="name"
                                [ngClass]="fontService.labelClass" jhiTranslate="vendor.employee.familyBenefitFund">Family Benefit Fund</label>
                                <input type="text" class="form-control" [(ngModel)]="employee.familyBenefitFund"
                                    name="name" autocomplete="off" maxlength="25" id="name"
                                    oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" 
                                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !employee.familyBenefitFund }"/>
                            </div>
                            <div class="col-sm-6 col-md-4" *ngIf="employee?.subEmployeeTypeDto">
                                <label class="form-control-label req" for="name"
                                [ngClass]="fontService.labelClass" jhiTranslate="vendor.employee.nhisCorpusFund">Nhis Corpus Fund</label>
                                <input type="text" class="form-control" [(ngModel)]="employee.nhisCorpusFund"
                                    name="name" autocomplete="off" maxlength="25" id="name"
                                    oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" 
                                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !employee.nhisCorpusFund }"/>
                            </div>
                            <div class="col-sm-6 col-md-4" *ngIf="employee?.subEmployeeTypeDto">
                                <label class="form-control-label req" for="name"
                                [ngClass]="fontService.labelClass" jhiTranslate="vendor.employee.specialProvFund">Special Provident Fund</label>
                                <input type="text" class="form-control" [(ngModel)]="employee.specialProvidentFund"
                                    name="name" autocomplete="off" id="name" maxlength="25"
                                    oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" 
                                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !employee.specialProvidentFund }"/>
                            </div>
                            <div class="col-sm-6 col-md-4" *ngIf="employee?.subEmployeeTypeDto">
                                <label class="form-control-label" for="cca"
                                [ngClass]="fontService.labelClass" jhiTranslate="vendor.employee.cca">CCA</label>
                                <input type="text" class="form-control" [(ngModel)]="employee.cca"
                                    name="cca" autocomplete="off" maxlength="25" id="cca" 
                                    oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"/>
                            </div>
                            <div class="col-sm-6 col-md-4" *ngIf="employee?.subEmployeeTypeDto">
                                <label class="form-control-label" for="otherAllowances"
                                [ngClass]="fontService.labelClass" jhiTranslate="vendor.employee.otherAllowances">Other Allowances</label>
                                <input type="text" class="form-control" [(ngModel)]="employee.otherAllowances"
                                    name="otherAllowances" autocomplete="off" maxlength="25" id="otherAllowances" 
                                    oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" />
                            </div>
                            <div class="col-sm-6 col-md-4" *ngIf="employee?.subEmployeeTypeDto">
                                <label class="form-control-label" for="otherDeductions"
                                [ngClass]="fontService.labelClass" jhiTranslate="vendor.employee.otherDeductions">Other Deductions</label>
                                <input type="text" class="form-control" [(ngModel)]="employee.otherDeductions"
                                    name="otherDeductions" autocomplete="off" maxlength="25" id="otherDeductions" 
                                    oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"/>
                            </div>
                            <div class="col-sm-6 col-md-4" *ngIf="employee?.subEmployeeTypeDto">
                                <label class="form-control-label req" for="name"
                                [ngClass]="fontService.labelClass" jhiTranslate="vendor.employee.grossAmount">Gross Amount</label>
                                <input type="text" class="form-control" [(ngModel)]="employee.grossAmount" name="name"
                                    autocomplete="off" maxlength="25" id="name" 
                                    oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" 
                                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !employee.grossAmount }"/>
                            </div>
                            <div class="col-sm-6 col-md-4" *ngIf="employee?.subEmployeeTypeDto">
                                <label class="form-control-label req" for="name"
                                [ngClass]="fontService.labelClass" jhiTranslate="vendor.employee.totalDeduction">Total Deduction</label>
                                <input type="text" class="form-control" [(ngModel)]="employee.totalDeduction"
                                    name="name" autocomplete="off" maxlength="25" id="name" 
                                    oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" 
                                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !employee.totalDeduction }"/>
                            </div>
                            <div class="col-sm-6 col-md-4" *ngIf="employee?.subEmployeeTypeDto">
                                <label class="form-control-label req" for="name"
                                [ngClass]="fontService.labelClass" jhiTranslate="vendor.employee.netAmount">Net Amount</label>
                                <input type="text" class="form-control" [(ngModel)]="employee.netAmount" name="name"
                                    autocomplete="off" id="name" maxlength="25"
                                    oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" 
                                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !employee.netAmount }"/>
                            </div>
                            <div class="col-sm-6 col-md-4" *ngIf="employee?.subEmployeeTypeDto">
                                <label class="form-control-label req" for="name"
                                [ngClass]="fontService.labelClass" jhiTranslate="vendor.employee.escNet">Esc Net</label>
                                <input type="text" class="form-control" [(ngModel)]="employee.escNet" name="name"
                                    autocomplete="off" maxlength="25" id="name" 
                                    oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" 
                                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !employee.escNet }"/>
                            </div>
                            <div class="col-sm-6 col-md-4" *ngIf="employee?.subEmployeeTypeDto && employee?.employeeTypeDto?.name !== 'Time Scale Employee'">
                                <label class="form-control-label" for="name"
                                [ngClass]="fontService.labelClass" jhiTranslate="vendor.employee.consolidatePay">Consolidate Pay</label>
                                <input type="text" class="form-control" [(ngModel)]="employee.consolidatePay"
                                    name="name" autocomplete="off" maxlength="25" id="name" 
                                    oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"/>
                            </div>
                            <div class="col-sm-6 col-md-4" *ngIf="employee?.subEmployeeTypeDto && employee?.employeeTypeDto?.name !== 'Time Scale Employee'">
                                <label class="form-control-label" for="name"
                                [ngClass]="fontService.labelClass" jhiTranslate="vendor.employee.adhocIncrease">Adhoc Increase</label>
                                <input type="text" class="form-control" [(ngModel)]="employee.adhocIncrease" name="name"
                                    autocomplete="off" id="name" maxlength="25"
                                    oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" />
                            </div>
                            <div class="col-sm-6 col-md-4" *ngIf="employee?.subEmployeeTypeDto && employee?.employeeTypeDto?.name !== 'Time Scale Employee' && employee?.subEmployeeTypeDto?.name !== 'Thoomai Paniyalargal (Others)'">
                                <label class="form-control-label" for="name"
                                [ngClass]="fontService.labelClass" jhiTranslate="vendor.employee.additionalAllowance">Additional Allowance</label>
                                <input type="text" class="form-control" [(ngModel)]="employee.additionalAllowance"
                                    name="name" autocomplete="off" maxlength="25" id="name" 
                                    oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"/>
                            </div>
                            <div class="col-sm-6 col-md-4" *ngIf="employee?.subEmployeeTypeDto && employee?.employeeTypeDto?.name !== 'Time Scale Employee' && employee?.subEmployeeTypeDto?.name !== 'Thoomai Paniyalargal (Others)'">
                                <label class="form-control-label" for="name"
                                [ngClass]="fontService.labelClass" jhiTranslate="vendor.employee.powerPumpOpAllowance">Power Pump Operator Allowance</label>
                                <input type="text" class="form-control"
                                    [(ngModel)]="employee.powerPumpOperatorAllowance" name="name" autocomplete="off"
                                    maxlength="25" id="name" 
                                    oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"/>
                            </div>
                        </div>
                        <div class="row gy-2 mt-3">
                            <div class="col-12">
                              <h6 [ngClass]="fontService.headingClass" jhiTranslate="agency.account.detail.name">
                                Vendor Account Details
                              </h6>
                            </div>
                            <div class="col-sm-6 col-md-4">
                              <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.bank.name"
                                  for=" bankName">Bank Name</label>
                                  <ng-select [appendTo]="'.p-dialog'" id="department" bindLabel="name" appearance="outline" [closeOnSelect]="true"
                                  [clearable]="true" class="form-control" (change)="getBankBranchList()" [items]="bankList" [disabled]="readonly"
                                  [(ngModel)]="employee.bankName" [ngClass]="{ 'is-invalid': ngSelectInvalid && !employee.bankName }" >
                                  </ng-select>
                                
                              </div>
                            </div>
                            <div class="col-sm-6 col-md-4">
                              <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                jhiTranslate="agency.branch.name" for=" branchName">Branch Name</label>
                                <ng-select [appendTo]="'.p-dialog'" id="department" bindLabel="bankBranch" appearance="outline" [closeOnSelect]="true"
                                [clearable]="true" class="form-control" (search)="getBankBranchList($event)" [items]="bankBranchList" [disabled]="readonly"
                                [(ngModel)]="bankBranch" (change)="getBankIfsc()" [ngClass]="{ 'is-invalid': ngSelectInvalid && !employee.bankBranch}" >
                                </ng-select>
                            </div>
                            <div class="col-sm-6 col-md-4">
                              <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.ifscode"
                                for=" ifsc">IFSC Code</label>
                              <input type="text" class="form-control" name="ifsc" autocomplete="off" maxlength="11"
                                oninput="this.value = this.value.replace(/[^A-Za-z0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" uppercase [readonly]="readonly"
                                [(ngModel)]="employee.ifscCode" id="ifsc"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !employee.ifscCode }" maxlength="11"
                                (input)="validateIfsc()" (change)="accountDetailsChanged()"/>
                              <div *ngIf="ifscError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.validifsc">Please enter a valid
                                  IFSCode.</small>
                              </div>
                            </div>
                            <div class="col-sm-6 col-md-4">
                              <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.accountno"
                                for=" accountNo">Account Number</label>
                              <input type="text" class="form-control" name="accountNo" autocomplete="off" maxlength="17"
                                oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" style="text-transform:uppercase" [readonly]="readonly"
                                [(ngModel)]="employee.accountNumber"  (change)="accountDetailsChanged()" id="accountNo"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !employee.accountNumber }" />
                              
                            </div>
                            <div class="col-sm-6 col-md-4">
                              <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                jhiTranslate="vendor.bank.account.name" for=" bankAccountName">Bank Account Name As per
                                Passbook</label>
                              <input type="text" class="form-control" name="bankAccountName" autocomplete="off" maxlength="60"
                                oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" style="text-transform:uppercase" [readonly]="readonly"
                                [(ngModel)]="employee.bankAccName" id="bankAccountName"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !employee.bankAccName }" />
                            </div>
                            <div class="col-sm-6 col-md-4">
                              <label class="form-control-label" [ngClass]="fontService.labelClass" jhiTranslate="vendor.micr"
                                for=" micr">MICR</label>
                              <input type="text" class="form-control" name="micr" autocomplete="off" [readonly]="readonly"
                                [(ngModel)]="employee.micr" maxlength="9" id="micr" />
                            </div>
                            <div class="col-12 justify-content-center" >
                                <button pButton pRipple class="p-button-success custom-button" (click)="accountValidation()"
                                styleClass="button" [disabled]="readonly">
                                <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.verifyaccount">Verify Account</span>
                                </button>
                                <div *ngIf="accountInvalidError" class="text-danger">
                                    <small [ngClass]="fontService.smallClass">{{"error.accountinvaliderror" | translate}}</small>
                                  </div>
                                  <div *ngIf="ngSelectInvalid && !verifyClicked" class="text-danger">
                                    <small [ngClass]="fontService.smallClass" jhiTranslate="error.verifyaccount"> Please verify the account</small>
                                  </div>
                            </div>
                    </div>
                </div>
            </div>

        </ng-template>

        <ng-template pTemplate="footer">
            <button pButton pRipple icon="pi pi-times" class="p-button-text custom-button" (click)="hideDialog()">
                <span class="button-label" custom-button jhiTranslate="common.cancelbutton"></span>
            </button>
            <button pButton pRipple icon="pi pi-check" class="p-button-text custom-button" (click)="saveData()">
                <span class="button-label" jhiTranslate="common.savebutton"></span>
            </button>
        </ng-template>
    </p-dialog>
    <p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
</div>
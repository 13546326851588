<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass">
                        Migrate Vendor Payment Voucher
                    </h5>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="container-fluid pb-3">
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col">
                        <div class="card p-3">
                            <div class="row voucherfont">
                                <h6 [ngClass]="fontService.headingClass" jhiTranslate="payments.add.voucher.generation.name">
                                    Add Voucher Generation
                                </h6>
                                <div class="col-lg-2 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "payments.voucher.number.name" | translate }}:<br />{{ voucherGenerationDto?.vendorTransactionDto?.voucherNo }}
                                    </p>
                                </div>
                                <div class="col-lg-1 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "date.name" | translate }}:<br />{{ date | date:'dd/MM/yyyy'}}
                                    </p>
                                </div>
                                <div class="col-lg-2 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "implementing.agency.name" | translate }}:<br />{{ this.voucherGenerationDto.vendorTransactionDto .implementingAgencyDto.name }}
                                    </p>
                                </div>
                                <div class="col-lg-2 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "payments.gst.number.name" | translate }}:<br />{{ this.voucherGenerationDto.vendorTransactionDto .implementingAgencyDto.gstNo }}
                                    </p>
                                </div>
                                <div class="col-lg-2 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "payments.department.name" | translate }}:<br />{{ this.voucherGenerationDto.vendorTransactionDto .implementingAgencyDto.departmentDto.name }}
                                    </p>
                                </div>

                                <div class="col-lg-2 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "payments.scheme.name" | translate }}:<br />{{ this.voucherGenerationDto.vendorTransactionDto .implementingAgencyDto.schemeDto.name }}
                                    </p>
                                </div>

                            </div>
                            <div class="row voucherfont">
                                <div class="col-lg-2 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "payments.level.type.name" | translate }}: {{ levelMasterDto.name }}
                                    </p>
                                </div>
                                <!-- <div class="col-lg-2 col-md-6 col-sm-4">
                              <p *ngIf="this.finYearDto" [ngClass]="fontService.regularBoldClass">
                                  Fin Year: {{this.finYearDto.name}}
                              </p>
                          </div>                               -->
                                <div class="col-lg-2 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "payments.districtAllocation.amount" | translate }}: {{ this.voucherGenerationDto?.vendorTransactionDto .iaComponentLimitDto?.adminAllocationAmount | currency:'INR':'':'1.2-2':'en-IN' }}
                                    </p>
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "payments.fundAuthorization.amount" | translate }}: {{ this.voucherGenerationDto?.vendorTransactionDto .iaComponentLimitDto?.adminFundAuthorizationAmount | currency:'INR':'':'1.2-2':'en-IN' }}
                                    </p>
                                </div>
                                <div class="col-lg-2 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "payments.utilized.amount.name" | translate }}: {{ this.voucherGenerationDto?.vendorTransactionDto .iaComponentLimitDto?.adminUtilizedAmount | currency:'INR':'':'1.2-2':'en-IN' }}
                                    </p>
                                </div>
                                <div class="col-lg-2 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "payments.limit.amount.name" | translate }}: {{ this.limitAmount | currency:'INR':'':'1.2-2':'en-IN' }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="card p-3">
                    <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <label class="form-control-label req" for="finYear" [ngClass]="fontService.labelClass" jhiTranslate="iACompo.limitTrans.fin.year.name">Fin Year</label>
                            <ng-select id="finYear" bindLabel="name" [items]="this.finYear" appearance="outline" [(ngModel)]="this.voucherGenerationDto.vendorTransactionDto.finYearDto" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width" [disabled]="readonly"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !this.voucherGenerationDto.vendorTransactionDto.finYearDto }" (change)="onchangeFinYear()">
                            </ng-select>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">Component Type</label>
                            <ng-select id="scheme" [hideSelected]="true" [(ngModel)]="grantlevel" [items]="grantLevel" bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true" (change)="ongrantlevel()" [disabled]="readonly || !isEditable" class="form-control dropdown-width"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !grantlevel }">
                            </ng-select>
                        </div>
                        <div class="col-lg-3 col-md-6" *ngIf="grantlevel">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">Component</label>
                            <ng-select id="scheme" [hideSelected]="true" [(ngModel)]="componentMaster" [items]="componentMasterList" bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true" (change)="vendorFilter()" [disabled]="readonly || !isEditable" class="form-control dropdown-width"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !componentMaster }">
                            </ng-select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="componentMaster" class="row g-0">
            <div class="col-lg-5">
                <div class="card p-3 invoice-card">
                    <div class="row">
                        <div class="col-md-6">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="payments.vendor.name">Vendor Name</label
              >
              <ng-select
                id="scheme"
                [disabled]="readonly || !isEditable|| loadingMore"
                [(ngModel)]="selectedVendor"
                [items]="vendorList"
                bindLabel="name"
                appearance="outline"
                [searchable]="true"
                [closeOnSelect]="true"
                [clearable]="false"
                (search)="vendorFilter($event)"
                (change)="popupValue()"
                class="form-control dropdown-width" (scrollToEnd)="onScrollToEnd()"
                [ngClass]="{
                  'is-invalid': ngSelectInvalid && !selectedVendor.name
                }"
              >
              </ng-select>
            </div>
            <div class="col-md-6">
              <label
                class="form-control-label pt-md-0 pt-sm-2 pt-xs-2 req"
                for="scheme"
                [ngClass]="fontService.labelClass"
                jhiTranslate="payments.payment.approval.number.name"
                >Payment Approval No</label
              >
              <input
                type="text" [disabled]="!isEditable"
                class="form-control"
                autocomplete="off"
                oninput="this.value = this.value.replace(/^[^0-9]+|[^0-9-\/]+/g, '').replace(/(\..*)\./g, '$1');"
                maxlength="15"
                [(ngModel)]="
                  voucherGenerationDto.vendorTransactionDto
                    .paymentApprovalNumber
                "
                [ngClass]="{
                  'is-invalid':
                    ngSelectInvalid &&
                    !voucherGenerationDto.vendorTransactionDto
                      .paymentApprovalNumber
                }"
                name="name"
                [readonly]="readonly"
                id="levelType"
              />
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-3">
              <p [ngClass]="fontService.regularBoldClass">
                {{ "payments.vendor.id" | translate }}:<br />{{
                  selectedVendor.id
                }}
              </p>
            </div>

            <div class="col-md-3">
              <p [ngClass]="fontService.regularBoldClass">
                {{ "payments.vendor.gst" | translate }}:<br />{{
                  selectedVendor?.gstNo
                }}
              </p>
            </div>
            <div class="col-md-3">
              <p [ngClass]="fontService.regularBoldClass">
                {{ "payments.vendor.pan" | translate }}:<br />{{
                  selectedVendor?.panNo
                }}
              </p>
            </div>
            <div class="col-md-3">
              <p [ngClass]="fontService.regularBoldClass">
                  Vendor Account Number:<br />{{ selectedVendor?.accountNumber }}
              </p>
          </div>
          </div>
          <div class="row">
            <!-- <div class="col-md-"></div> -->
            <div *ngIf="selectedVendor.name" class="col-md-5">
              <div
                class=""
                [ngClass]="fontService.labelClass"
                jhiTranslate="payments.vendor.last.transaction.name"
              >
                last 5 Transaction
              </div>
              <button
                pButton
                pRipple
                (click)="getvendorTrans()"
                class="p-button-success custom-button"
                styleClass="button"
              >
                <span
                  class="button-label"
                  [ngClass]="fontService.buttonClass"
                  jhiTranslate="common.view"
                ></span>
              </button>
            </div>
            <div *ngIf="enableZonalButton" class="col-md-6">
              <label
                class="form-control-label req"
                for="scheme"
                [ngClass]="fontService.labelClass"
                >Zonal User</label
              >
              <ng-select
                id="scheme"
                [disabled]="readonly || !isEditable"
                [(ngModel)]="selectedZonalUser"
                [items]="zonalList"
                bindLabel="userName"
                appearance="outline"
                [searchable]="true"
                [closeOnSelect]="true"
                [clearable]="false"
                class="form-control dropdown-width"
               
              >
              </ng-select>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-5">
                <label class="form-control-label" for="utrNo"
                  [ngClass]="fontService.labelClass">UTR No/Cheque No</label>
                            <input type="text" [disabled]="!isEditable" class="form-control" autocomplete="off" oninput="this.value = this.value.replace(/[^A-Za-z0-9-_]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();" style="text-transform: uppercase"
                                maxlength="30" [(ngModel)]="voucherGenerationDto.vendorTransactionDto.utrNo" name="name" [readonly]="readonly" id="levelType" />
                        </div>
                        <div class="col-lg-3">
                            <label class="form-control-label req pt-lg-0 pt-sm-2 pt-xs-2" for="paymentDate" [ngClass]="fontService.labelClass">Payment Date</label>
                            <p-calendar [(ngModel)]="voucherGenerationDto.vendorTransactionDto.paymentDate" [maxDate]="maxDate" [disabled]="readonly || !isEditable" styleClass="datepicker" [ngClass]="{'is-invalid':ngSelectInvalid &&
                      !voucherGenerationDto.vendorTransactionDto.paymentDate}"></p-calendar>
                        </div>
                        <div class="col-lg-4">
                            <label class="form-control-label" for="journalNumber" [ngClass]="fontService.labelClass">Journal Number</label>
                            <input type="text" [disabled]="!isEditable" class="form-control" autocomplete="off" oninput="this.value = this.value.replace(/[^A-Za-z0-9-_]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();" style="text-transform: uppercase"
                                maxlength="30" [(ngModel)]="voucherGenerationDto.vendorTransactionDto.journalNumber" name="name" [readonly]="readonly" id="journalNumber" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-7">
                <div class="card p-3 invoice-card">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="row">
                                <div class="col-lg-7">
                                    <label class="form-control-label" for="scheme" [ngClass]="fontService.labelClass">Invoice Number</label
                  >
                  <input
                    type="text" [disabled]="!isEditable"
                    class="form-control"
                    autocomplete="off"
                    oninput="this.value = this.value.replace(/[^A-Za-z0-9-_]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                    onblur="this.value =this.value.trim();"
                    style="text-transform: uppercase"
                    maxlength="30"
                    [(ngModel)]="
                      voucherGenerationDto.vendorTransactionDto.invoiceNo
                    "
                    name="name"
                    [readonly]="readonly"
                    id="levelType"
                  />
                </div>
                <div class="col-lg-5">
                  <label
                    class="form-control-label pt-lg-0 pt-sm-2 pt-xs-2"
                    for="dateOfBirth"
                    [ngClass]="fontService.labelClass"
                    >Invoice Date</label
                  ><br />
                  <p-calendar
                    [(ngModel)]="
                      voucherGenerationDto.vendorTransactionDto.invoiceDate
                    "
                    [maxDate]="maxDate"
                    [disabled]="readonly || !isEditable"
                    styleClass="datepicker"
                  ></p-calendar>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-12">
                  <label
                    class="form-control-label pt-md-0 pt-sm-2 pt-xs-2 req"
                    for="schemeDescription"
                    [ngClass]="fontService.labelClass"
                    >Description</label
                  ><br />
                  <textarea
                    id="w3review" [disabled]="!isEditable"
                    [readonly]="readonly"
                    name="w3review"
                    rows="3"
                    [(ngModel)]="
                      voucherGenerationDto.vendorTransactionDto.nameOfWork
                    "
                    [ngClass]="{
                      'is-invalid':
                        ngSelectInvalid &&
                        !voucherGenerationDto.vendorTransactionDto.nameOfWork
                    }"
                    cols="100" maxlength="150"
                    oninput="this.value = this.value.replace(/[^A-Za-z0-9 ,-.]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                onblur="this.value =this.value.trim();"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <label
                class="form-control-label pt-md-0 pt-sm-2 pt-xs-2 req"
                for="scheme"
                [ngClass]="fontService.labelClass"
                >Invoice Upload</label
              >
              <div>
                <small class="mb-2" *ngIf="!readonly" [ngClass]="fontService.regularClass" jhiTranslate="funds.note">Note : Allowed file types are jpg, jpeg, png, pdf and
                  Maximum file size should be 1MB</small>
              </div>
              <p-fileUpload *ngIf="!readonly"
                (onUpload)="onUpload($event)"
                [customUpload]="true"
                [disabled]="isFileUploaded || !isEditable"
                (uploadHandler)="onUpload($event)"
                auto="true"
                multiple="false"
                [showUploadButton]="false"
                [showCancelButton]="false"
                chooseLabel="{{'common.upload'|translate}}"
              >
              </p-fileUpload>
              <div class="row mt-2" *ngIf="!isFileUploaded && ngSelectInvalid">
                <small class="text-danger">Upload the Certificate</small>
              </div>
              <div class="row mt-2" *ngIf="isFileUploaded">
                <div class="col">
                  <a [ngClass]="fontService.regularClass" class="cursor-pointer" (click)="viewDocument()">{{uFilename}}</a>
                  <i  class="pi pi-times add-margin" *ngIf="!readonly && isEditable" (click)="removeDocument()"></i>
                  <i  class="pi pi-download add-margin" (click)="downloadDocument()"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    
  </div>
  <div *ngIf="selectedVendor.name" class="row">
    <div class="col-md-12">
      <div class="card p-3">
        <div class="row">
          <div class="col-md-12 mt-2" *ngIf="selectedVendor.name">
            <p-table
              [value]="voucherGenerationDto.voucherBillDescDtoList"
              [resizableColumns]="true"
              responsiveLayout="scroll"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th [ngClass]="fontService.labelClass">
                    {{ "payments.table.sno" | translate }}
                  </th>
                  <th class="req" [ngClass]="fontService.labelClass">
                    {{ "payments.table.title" | translate }}
                  </th>                 
                  <th class="req" [ngClass]="fontService.labelClass">
                    {{ "payments.table.amount" | translate }} <br />
                    <span class="italic"
                      >({{ "payments.table.exclGst" | translate }})</span
                    >
                  </th>
                  <th
                    *ngIf="enableStateGst || enableIGst"
                    [ngClass]="fontService.labelClass"
                  >
                    {{ "payments.table.gst" | translate }}
                  </th>
                  <th
                    *ngIf="enableStateGst"
                    [ngClass]="fontService.labelClass"
                  >
                    {{ "payments.table.sgst" | translate }}
                  </th>
                  <th
                    *ngIf="enableStateGst"
                    [ngClass]="fontService.labelClass"
                  >
                    {{ "payments.table.cgst" | translate }}
                  </th>
                  <th *ngIf="enableIGst" [ngClass]="fontService.labelClass">
                    {{ "payments.table.igst" | translate }}
                  </th>
                  <th [ngClass]="fontService.labelClass">
                    {{ "payments.table.grossTotal" | translate }}
                  </th>
                  <th [ngClass]="fontService.labelClass">
                    {{ "payments.table.delete" | translate }}
                  </th>
                  <th style="text-align: right">
                    <button 
                      pButton
                      pRipple
                      icon="pi pi-plus"
                      class="p-button-success custom-button"
                      [disabled]="readonly || !isEditable"
                      styleClass="button"
                      (click)="addRow()"
                    >
                      <span
                        class="button-label"
                        [ngClass]="fontService.buttonClass"
                        jhiTranslate="common.row"
                      ></span>
                    </button>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-list let-index="rowIndex">
                <tr>
                  <td>{{ index + 1 }}</td>
                  <td>
                    <input
                      type="text" [disabled]="!isEditable"
                      class="form-control"
                      autocomplete="off"
                      oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                      onblur="this.value =this.value.trim();"
                      style="text-transform: uppercase"
                      (blur)="addDescritption(list.description)"
                      maxlength="30"
                      [(ngModel)]="list.description"
                      name="name"
                      id="levelType"
                      [ngClass]="{
                        'is-invalid': ngSelectInvalid && !list.description
                      }"
                      [readonly]="readonly"
                    />
                  </td>                  
                  <td>
                    <p-inputNumber
                      id="field_price" [disabled]="!isEditable"
                      inputId="locale-indian" mode="decimal" locale="en-IN"
                      [(ngModel)]="list.amount"
                      (ngModelChange)="calculatetotal(list)"
                      [readonly]="readonly"
                      maxlength="15"
                      [ngClass]="{
                        'is-invalid': ngSelectInvalid && !list.amount
                      }"
                    ></p-inputNumber>
                  </td>
                  <td *ngIf="enableStateGst || enableIGst">
                    <p-inputNumber
                      id="field_price"   [disabled]="!isEditable"                      
                      [max]="28"
                      [readonly]="readonly"
                      [(ngModel)]="list.gstPercentage"
                      mode="decimal"
                      [minFractionDigits]="1" 
                      [maxFractionDigits]="2"
                      (ngModelChange)="calculatetotal(list)"
                    ></p-inputNumber>
                  </td>
                  <td *ngIf="enableStateGst">
                    <input
                      type="text" [disabled]="!isEditable"
                      class="form-control small-box"
                      oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                      id="field_price"
                      [(ngModel)]="list.sgstPercentage"
                      [readonly]="true"
                    />
                  </td>
                  <td *ngIf="enableStateGst">
                    <input
                      type="text" [disabled]="!isEditable"
                      class="form-control small-box"
                      oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                      id="field_price"
                      [(ngModel)]="list.cgstPercentage"
                      mode="decimal"
                      [readonly]="true"
                    />
                  </td>
                  <td *ngIf="enableIGst">
                    <input
                      type="text" [disabled]="!isEditable"
                      class="form-control small-box"
                      oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                      id="field_price"
                      [(ngModel)]="list.gstPercentage"
                      [readonly]="true"
                    />
                  </td>
                  <td>
                    <p-inputNumber
                      type="text" [disabled]="!isEditable"
                      inputId="locale-indian" mode="decimal" locale="en-IN"
                      oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                      id="field_price"
                      [(ngModel)]="list.grossAmount"
                      [readonly]="true"
                    ></p-inputNumber>
                  </td>
                  <td>
                    <button
                      pButton
                      pRipple
                      type="button"
                      [disabled]="readonly || !isEditable"
                      icon="pi pi-trash"
                      class="p-button-rounded p-button-text p-button-danger"
                      (click)="delete(index)"
                    ></button>
                  </td>
                  <td></td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
        <div *ngIf="selectedVendor.name" class="row mt-3 tds">
          <div class="col-lg-6">
            <div class="row">
              <div class="col-sm-4">
                <label
                  class="form-control-label"
                  for="schemeDescription"
                  [ngClass]="fontService.labelClass"
                  >{{ "payments.amount.select.IT.name" | translate }}</label
                ><br />
                <p-radioButton
                  [disabled]="readonly || !isEditable"
                  name="option"
                  [value]="true"
                  [(ngModel)]="
                    voucherGenerationDto.vendorTransactionDto.tdsApplicable
                  "
                  label="{{ 'payments.amount.yes' | translate }}"
                ></p-radioButton>
                <p-radioButton
                  [disabled]="readonly || !isEditable"
                  name="option"
                  [value]="false"
                  [(ngModel)]="
                    voucherGenerationDto.vendorTransactionDto.tdsApplicable
                  "
                  label="{{ 'payments.amount.no' | translate }}"
                  (onClick)="
                    voucherGenerationDto.vendorTransactionDto.tdsPercentage = 0;
                    voucherGenerationDto.vendorTransactionDto.tdsAmount = 0;
                    calculateTotalAmount()
                  "
                ></p-radioButton>
              </div>

              <div
                class="col-sm-3"
                *ngIf="
                  voucherGenerationDto?.vendorTransactionDto?.tdsApplicable
                "
              >
                <label
                  class="form-control-label pt-xs-2"
                  for="scheme"
                  [ngClass]="fontService.labelClass"
                  jhiTranslate="payments.amount.IT%"
                  >IT%</label
                ><br />
                <p-inputNumber
                  class="full-width"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                  id="field_price"
                  [readonly]="readonly"
                  [min]="0"
                  [max]="28"
                  [(ngModel)]="
                    voucherGenerationDto.vendorTransactionDto.tdsPercentage
                  "
                  mode="decimal"
                  [minFractionDigits]="1" 
                  [maxFractionDigits]="2"
                  (ngModelChange)="calculateTds()"
                ></p-inputNumber>
              </div>
              <div
                class="col-sm-5"
                *ngIf="
                  voucherGenerationDto?.vendorTransactionDto?.tdsApplicable
                "
              >
                <label
                  class="form-control-label pt-xs-2"
                  for="scheme"
                  [ngClass]="fontService.labelClass"
                  jhiTranslate="payments.amount.IT.amount.name"
                  >IT Amount</label><br>
                                    <p-inputNumber class="full-width" inputId="locale-indian" mode="decimal" locale="en-IN" [(ngModel)]="
                    voucherGenerationDto.vendorTransactionDto.tdsAmount
                  " name="name" id="levelType" [readonly]="true"></p-inputNumber>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-sm-4">
                                    <label class="form-control-label pt-xs-2" for="schemeDescription" [ngClass]="fontService.labelClass">{{ "payments.amount.select.gst.name" | translate }}</label
                ><br />
                <p-radioButton
                  name="option"
                  [disabled]="readonly || !isEditable"
                  [value]="true"
                  [(ngModel)]="enableGstOnTds"
                  (onClick)="
                    voucherGenerationDto.vendorTransactionDto.gstOnTds = 2;
                    calculateTotalAmount()
                  "
                  label="{{ 'payments.amount.yes' | translate }}"
                ></p-radioButton>
                <p-radioButton
                  name="option"
                  [disabled]="readonly || !isEditable"
                  [value]="false"
                  [(ngModel)]="enableGstOnTds"
                  label="{{ 'payments.amount.no' | translate }}"
                  (onClick)="calculateTotalAmount()"
                ></p-radioButton>
              </div>
              <div class="col-sm-3" *ngIf="enableGstOnTds">
                <label
                  class="form-control-label pt-xs-2"
                  for="scheme"
                  [ngClass]="fontService.labelClass"
                  jhiTranslate="payments.amount.gstTds%"
                  >TDS on GST %</label
                ><br />
                <p-inputNumber
                  class="full-width"
                  inputId="locale-indian" mode="decimal" locale="en-IN"
                  [min]="0"
                  [max]="29"
                  [(ngModel)]="
                    voucherGenerationDto.vendorTransactionDto.gstOnTds
                  "
                  mode="decimal"
                  [minFractionDigits]="1" 
                  [maxFractionDigits]="2"
                  [readonly]="readonly"
                  (ngModelChange)="calculateTotalAmount()"
                  name="name"
                  id="levelType"
                ></p-inputNumber>
              </div>
              <div class="col-sm-5" *ngIf="enableGstOnTds">
                <label
                  class="form-control-label pt-xs-2"
                  for="scheme"
                  [ngClass]="fontService.labelClass"
                  jhiTranslate="payments.amount.gst.amount.name"
                  >TDS on GST Amount</label
                ><br>
                <p-inputNumber
                class="full-width"
                  inputId="locale-indian" mode="decimal" locale="en-IN"
                  [(ngModel)]="
                    voucherGenerationDto.vendorTransactionDto.gstOnTdsAmount
                  "
                  name="name"
                  id="levelType"
                  [readonly]="true"
                ></p-inputNumber>
              </div>
            </div>
            <!-- <div class="row mt-2">
              <div class="col-sm-4">
                <label
                  class="form-control-label pt-xs-2"
                  for="schemeDescription"
                  [ngClass]="fontService.labelClass"
                  >{{
                    "payments.amount.select.labour.name" | translate
                  }}</label
                ><br />
                <p-radioButton
                  name="option"
                  [disabled]="readonly || !isEditable"
                  [value]="true"
                  [(ngModel)]="enableLabourChess"
                  (onClick)="
                    voucherGenerationDto.vendorTransactionDto.labourCessPercentage = 1;
                    calculateTotalAmount()
                  "
                  label="{{ 'payments.amount.yes' | translate }}"
                ></p-radioButton>
                <p-radioButton
                  name="option"
                  [disabled]="readonly || !isEditable"
                  [value]="false"
                  [(ngModel)]="enableLabourChess"
                  label="{{ 'payments.amount.no' | translate }}"
                  (onClick)="voucherGenerationDto.vendorTransactionDto.labourCessPercentage = 0;
                  calculateTotalAmount()"
                ></p-radioButton>
              </div>

              <div class="col-sm-3" *ngIf="enableLabourChess">
                <label
                  class="form-control-label pt-xs-2"
                  for="scheme"
                  [ngClass]="fontService.labelClass"
                  jhiTranslate="payments.amount.labour.percentage.name"
                ></label
                ><br />
                <p-inputNumber
                  class="full-width"
                  [(ngModel)]="
                    voucherGenerationDto.vendorTransactionDto
                      .labourCessPercentage
                  "
                  mode="decimal"
                  [minFractionDigits]="1" 
                  [maxFractionDigits]="2"
                  name="name"
                  id="levelType"
                  [max]="28"
                  [readonly]="readonly"
                  (ngModelChange)="calculateTotalAmount()"
                ></p-inputNumber>
              </div>
              <div class="col-sm-5" *ngIf="enableLabourChess">
                <label
                  class="form-control-label pt-xs-2"
                  for="scheme"
                  [ngClass]="fontService.labelClass"
                  jhiTranslate="payments.amount.labour.amount.name"
                ></label><br>
                                    <p-inputNumber class="full-width" inputId="locale-indian" mode="decimal" locale="en-IN" [(ngModel)]="
                    voucherGenerationDto.vendorTransactionDto.labourCess
                  " [readonly]="true" name="name" id="levelType"></p-inputNumber>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-sm-4">
                                    <label class="form-control-label pt-xs-2" for="schemeDescription" [ngClass]="fontService.labelClass">{{
                    "payments.amount.select.withheld.name" | translate
                  }}</label
                ><br />
                <p-radioButton
                  name="option"
                  [value]="true"
                  [disabled]="readonly || !isEditable"
                  [(ngModel)]="enableSecurityDeposit"
                  (onClick)="
                    voucherGenerationDto.vendorTransactionDto.securityDeposit = 1;
                    calculateTotalAmount()
                  "
                  label="{{ 'payments.amount.yes' | translate }}"
                ></p-radioButton>
                <p-radioButton
                  name="option"
                  [value]="false"
                  [disabled]="readonly || !isEditable"
                  [(ngModel)]="enableSecurityDeposit"
                  label="{{ 'payments.amount.no' | translate }}"
                  (onClick)="
                    voucherGenerationDto.vendorTransactionDto.securityDeposit = 0;
                    calculateTotalAmount()
                  "
                ></p-radioButton>
              </div>

              <div class="col-sm-3" *ngIf="enableSecurityDeposit">
                <label
                  class="form-control-label pt-xs-2"
                  for="scheme"
                  [ngClass]="fontService.labelClass"
                  jhiTranslate="payments.amount.security.percentage.name"
                ></label
                ><br />
                <p-inputNumber
                  class="full-width"
                  [max]="28"
                  inputId="locale-indian" mode="decimal" locale="en-IN"
                  [(ngModel)]="
                    voucherGenerationDto.vendorTransactionDto.securityDeposit
                  "
                  mode="decimal"
                  [minFractionDigits]="1" 
                  [maxFractionDigits]="2"
                  name="name"
                  id="levelType"
                  [readonly]="readonly"
                  (ngModelChange)="calculateTotalAmount()"
                ></p-inputNumber>
              </div>
              <div class="col-sm-5" *ngIf="enableSecurityDeposit">
                <label
                  class="form-control-label pt-xs-2"
                  for="scheme"
                  [ngClass]="fontService.labelClass"
                  jhiTranslate="payments.amount.security.amount.name"
                ></label><br>
                                    <p-inputNumber class="full-width" inputId="locale-indian" mode="decimal" locale="en-IN" [(ngModel)]="
                    voucherGenerationDto.vendorTransactionDto
                      .securityDepositAmount
                  " name="name" id="levelType" [readonly]="true"></p-inputNumber>
                                </div>
                            </div> -->
                        </div>
                        <div class="col-lg-2 pt-lg-0 pt-xs-2 pt-sm-2">
                            <label class="form-control-label req" for="schemeDescription" [ngClass]="fontService.labelClass" jhiTranslate="payments.remarks.name">Remarks</label
            ><br />
            <textarea
              id="w3review"
              name="w3review"
              rows="3" [disabled]="!isEditable"
              [(ngModel)]="voucherGenerationDto.vendorTransactionDto.remark"
              [readonly]="
                enableSignButton ||
                voucherGenerationDto.vendorTransactionDto.signStatus
              "
              cols="100" maxlength="150"
              [ngClass]="{
                'is-invalid':
                  ngSelectInvalid &&
                  !voucherGenerationDto.vendorTransactionDto.remark
              }"
              oninput="this.value = this.value.replace(/[^A-Za-z0-9 ,-.]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                onblur="this.value =this.value.trim();"
            >
            </textarea>
          </div>

          <div class="col-lg-3 pt-lg-0 pt-xs-2 pt-sm-2">
            <table class="snippet">
              <tr
                *ngIf="
                  voucherGenerationDto.vendorTransactionDto.grossAmount !== 0
                "
              >
                <td [ngClass]="fontService.labelClass">
                  Gross Amount
                </td>
                <td style="width: 110px; text-align: right">
                  {{
                    voucherGenerationDto.vendorTransactionDto.grossAmount
                    | currency:'INR':'':'1.2-2':'en-IN' || "Nill"
                  }}
                </td>
              </tr>
              <tr
                *ngIf="
                  voucherGenerationDto.vendorTransactionDto.totalCgst !== 0
                "
              >
                <td [ngClass]="fontService.labelClass">CGST</td>
                <td style="width: 110px; text-align: right">
                  {{
                    voucherGenerationDto.vendorTransactionDto.totalCgst
                    | currency:'INR':'':'1.2-2':'en-IN'
                  }}
                </td>
              </tr>
              <tr
                *ngIf="
                  voucherGenerationDto.vendorTransactionDto.totalSgst !== 0
                "
              >
                <td [ngClass]="fontService.labelClass">SGST</td>
                <td style="width: 110px; text-align: right">
                  {{
                    voucherGenerationDto.vendorTransactionDto.totalSgst
                    | currency:'INR':'':'1.2-2':'en-IN'
                  }}
                </td>
              </tr>
              <tr
                *ngIf="
                  voucherGenerationDto.vendorTransactionDto.totalIgst !== 0
                "
              >
                <td [ngClass]="fontService.labelClass">IGST</td>
                <td style="width: 110px; text-align: right">
                  {{
                    voucherGenerationDto.vendorTransactionDto.totalIgst
                    | currency:'INR':'':'1.2-2':'en-IN'
                  }}
                </td>
              </tr>
              <tr
                *ngIf="
                  voucherGenerationDto.vendorTransactionDto.netAmount !== 0 &&
                  (enableStateGst || enableIGst)
                "
              >
                <td [ngClass]="fontService.labelClass">
                  {{ "payments.amount.total.amount.name" | translate }}
                </td>
                <td style="width: 110px; text-align: right">
                  {{
                    voucherGenerationDto.vendorTransactionDto.netAmount
                    | currency:'INR':'':'1.2-2':'en-IN'
                  }}
                </td>
              </tr>
              <tr
                *ngIf="
                  voucherGenerationDto.vendorTransactionDto.tdsAmount !== 0
                "
              >
                <td [ngClass]="fontService.labelClass">IT</td>
                <td style="width: 110px; text-align: right">
                  {{
                    voucherGenerationDto.vendorTransactionDto.tdsAmount
                    | currency:'INR':'':'1.2-2':'en-IN'
                  }}
                </td>
              </tr>
              <tr
                *ngIf="
                  voucherGenerationDto.vendorTransactionDto.gstOnTdsAmount !==
                  0
                "
              >
                <td [ngClass]="fontService.labelClass">
                  {{ "payments.amount.gst.amount.name" | translate }}
                </td>
                <td style="width: 110px; text-align: right">
                  {{
                    voucherGenerationDto.vendorTransactionDto.gstOnTdsAmount
                    | currency:'INR':'':'1.2-2':'en-IN'
                  }}
                </td>
              </tr>
              <tr
                *ngIf="
                  voucherGenerationDto.vendorTransactionDto.labourCess !== 0
                "
              >
                <td [ngClass]="fontService.labelClass">
                  {{ "payments.amount.labour.cess.name" | translate }}
                </td>
                <td style="width: 110px; text-align: right">
                  {{
                    voucherGenerationDto.vendorTransactionDto.labourCess
                    | currency:'INR':'':'1.2-2':'en-IN'
                  }}
                </td>
              </tr>
              <tr
                *ngIf="
                  voucherGenerationDto.vendorTransactionDto
                    .securityDepositAmount !== 0
                "
              >
                <td [ngClass]="fontService.labelClass">
                  {{ "payments.amount.security.deposit.name" | translate }}
                </td>
                <td style="width: 110px; text-align: right">
                  {{
                    voucherGenerationDto.vendorTransactionDto
                      .securityDepositAmount
                      | currency:'INR':'':'1.2-2':'en-IN'
                  }}
                </td>
              </tr>
              <tr *ngIf="totalDeduction !== 0">
                <td [ngClass]="fontService.labelClass">
                  {{
                    "payments.amount.total.deduction.amount.name" | translate
                  }}
                </td>
                <td style="width: 110px; text-align: right">
                  {{ totalDeduction | currency:'INR':'':'1.2-2':'en-IN' }}
                </td>
              </tr>
              <tr
                *ngIf="
                  voucherGenerationDto.vendorTransactionDto
                    .transactionAmount !== 0
                "
              >
                <td [ngClass]="fontService.labelClass">
                  {{ "payments.amount.transaction.name" | translate }}
                </td>
                <td style="width: 110px; text-align: right">
                  {{
                    voucherGenerationDto.vendorTransactionDto
                      .transactionAmount
                      | currency:'INR':'':'1.2-2':'en-IN'
                  }}
                </td>
              </tr>
            </table>
          </div>
        </div>
        <p-toolbar *ngIf="selectedVendor.name && !enableRevertButton && isEditable">
          <div class="p-toolbar-group-start"></div>
          <div class="p-toolbar-group-end">
            <button *ngIf="!enableSignButton && !readonly && isEditable" pButton pRipple class="p-button-success custom-button margin"
              (click)="genVocher()" styleClass="button">
              <span class="button-label" [ngClass]="fontService.buttonClass">Save Voucher</span>
            </button>
          </div>
        </p-toolbar>
      </div>
    </div>
  </div>
  <p-dialog
    [(visible)]="profileDialog"
    [style]="{ width: '1000px' }"
    [modal]="true"
    [baseZIndex]="1000"
    styleClass="ib-dialog"
  >
    <p-header [ngClass]="fontService.headingClass">
     {{ "payments.lastTransaction" | translate }}
    </p-header>
    
    <p-table *ngIf="!noRecord"
      [value]="searchResult.items"
      [resizableColumns]="true"
      styleClass="dynamic-table p-datatable-gridlines"
      responsiveLayout="scroll"
    >
      <ng-template pTemplate="header">
        <tr>
          <th [ngClass]="fontService.labelClass">
            {{ "payments.viewTable.voucherNo" | translate }}
          </th>
          <th [ngClass]="fontService.labelClass">
            {{ "payments.viewTable.vendorName" | translate }}
          </th>
          <th [ngClass]="fontService.labelClass">
            {{ "payments.viewTable.vendorAccount" | translate }}
          </th>
          <th [ngClass]="fontService.labelClass">
            {{ "payments.viewTable.transactionDate" | translate }}
          </th>
          <th [ngClass]="fontService.labelClass">
            {{ "payments.viewTable.rate" | translate }}
          </th>
          <th [ngClass]="fontService.labelClass">
            {{ "payments.viewTable.totTransAmount" | translate }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data let-index="rowIndex">
        <tr>
          <td>{{ data.vendorTransaction.voucherNo }}</td>
          <td>{{ data.vendorTransaction.commercialVendorName }}</td>
          <td>{{ data.vendorTransaction.commercialAccountNo }}</td>
          <td>{{ data.vendorTransaction.transDate | date : "shortDate" }}</td>
          <td>{{ data.vendorTransaction.grossAmount }}</td>
          <td>{{ data.vendorTransaction.transactionAmount }}</td>
        </tr>
      </ng-template>
    </p-table>
    <div *ngIf="noRecord">
      <h5 class="text-center"  [ngClass]="fontService.regularClass" jhiTranslate="error.norecord">No transactions found</h5>
    </div>
  </p-dialog>
  <p-dialog
    [(visible)]="viewDoc"
    [style]="{ width: '1000px', height: '800px' }"
    [modal]="true"
    [baseZIndex]="1000"
    styleClass="ib-dialog"
  >
  <ng-template pTemplate="body">
    <div style='position: relative; height: 100%;'>
      <ngx-extended-pdf-viewer *ngIf="vFileExt === 'pdf'" [src]="vFileUrl"></ngx-extended-pdf-viewer>
      <img *ngIf="vFileExt !== 'pdf'"  [src]="vFileUrl" style="display: block;" height="800px" />
    </div>
  </ng-template>
  </p-dialog>
import { WrongTransaction } from './wrong-transaction.model';
import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { filterQuery } from 'src/app/shared/common.model';
import { FontService } from 'src/app/shared/font-service/font.service';
import { VendorTransaction } from '../payment-voucher/payment-voucher.model';
import { PaymentService } from '../payment-voucher/payment-voucher.service';
import { NotificationService } from 'src/app/alert/notification.service';
import { CommercialVendors } from 'src/app/module/commercial-vendors/commercial-vendors';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';


@Component({
  selector: 'app-wrong-transaction',
  templateUrl: './wrong-transaction.component.html',
  styleUrls: ['./wrong-transaction.component.scss'],
})
export class WrongTransactionComponent implements OnInit {
  wrongTransaction: WrongTransaction;
  voucher: VendorTransaction;
  isFileUploaded: boolean;
  uFilename: string;
  vFileExt: any;
  vFileUrl: string;
  viewDoc: boolean;
  ngSelectInvalid: boolean;
  constructor(
    public fontService: FontService,
    protected paymentService: PaymentService,
    protected notificationService: NotificationService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private confirmationService: ConfirmationService,
  ) {}

  ngOnInit(): void {
    this.wrongTransaction = new WrongTransaction();
  }
  changeVoucherNo() {
    let query = new filterQuery();
    query.key = 'voucherNo';
    query.operation = 'equals';
    query.value = this.wrongTransaction.voucherNo;
    let filterProps = [];
    filterProps.push(query);
    query = new filterQuery();
    query.key = 'paymentMethod';
    query.operation = 'contains';
    query.value = 'WORK';
    filterProps.push(query);
    query = new filterQuery();
    query.key = 'activeFlag';
    query.operation = 'equals';
    query.value = 'YES';
    filterProps.push(query);
    let Query = {
      page: 0,
      size: 1,
    };
    this.paymentService.Validfilter({ ...Query }, filterProps).subscribe(
      (res: HttpResponse<VendorTransaction[]>) => {
        console.log('vendorTranasacton', res.body);
        if (
          res.body &&
          (res.body[0].transactionStatus === 'O.K.' ||
            res.body[0].transactionStatus === 'failed')
        ) {
          this.wrongTransaction.commercialVendor = new CommercialVendors();
          this.wrongTransaction.commercialVendor.id =
            res.body[0].commercialVendor;
          this.wrongTransaction.grossAmount = res.body[0].netAmount;
          this.wrongTransaction.transactionAmount =
            res.body[0].transactionAmount;
          this.wrongTransaction.levelMaster =
            res.body[0].iaComponentLimit.levelMaster;
          this.wrongTransaction.revertType = res.body[0].transactionStatus;
          this.voucher = res.body[0];
          console.log(this.wrongTransaction);
        } else {
          this.wrongTransaction = new WrongTransaction();
          this.voucher = null;
          this.notificationService.alertError('This Voucher is Pending', '');
        }
      },
      () => {}
    );
  }

  viewDocument() {
    this.paymentService
      .viewimage(this.wrongTransaction.mandatoryDocument)
      .subscribe((res) => {
        const filename = res.body.fileName;
        this.vFileExt = filename.split('.').pop();
        this.vFileUrl =
          'data:image/' + this.vFileExt + ';base64,' + res.body.fileType; // Replace with your Base64-encoded image string
        this.viewDoc = true;
      });
  }
  downloadImage(base64Data: string, filename: string) {
    const link = document.createElement('a');
    link.href = base64Data;
    link.download = filename;
    link.click();
  }
  downloadDocument() {
    this.paymentService
      .getimage(this.wrongTransaction.mandatoryDocument)
      .subscribe(
        (res) => {
          const filename = res.body.fileName;
          const fileExtension = filename.split('.').pop();

          if (fileExtension == 'pdf') {
            const base64Data =
              'data:application/pdf;base64,' + res.body.fileType; // Replace with your Base64-encoded image string
            this.downloadImage(base64Data, filename);
          } else {
            const base64Data =
              'data:image/' + fileExtension + ';base64,' + res.body.fileType; // Replace with your Base64-encoded image string
            this.downloadImage(base64Data, filename);
          }
        },
        (onError) => {}
      );
  }
  removeDocument() {
    this.paymentService
      .deleteimage(this.wrongTransaction.mandatoryDocument)
      .subscribe(
        (res) => {
          this.notificationService.alertSuccess('File Deleted', '');
          this.isFileUploaded = false;
          this.wrongTransaction.mandatoryDocument = null;
        },
        (error) => {
          this.notificationService.alertError('Error occured, try again', '');
        }
      );
  }

  onUpload($event) {
    const formData = new FormData();
    const i = $event.files.length - 1;
    const file = $event.files[i];
    const acceptedFormats = [
      'image/png',
      'image/jpeg',
      'image/jpg',
      'application/pdf',
    ];

    if (file && !acceptedFormats.includes(file.type)) {
      this.notificationService.alertError(
        'Invalid file format. Please upload a valid file.',
        ''
      );
      return;
    }
    const fileData = new Blob([$event.files[i]], {
      type: $event.files[i].type,
    });
    if ($event.files[i].type === 'application/pdf') {
      formData.append('file', fileData, `.pdf`);
    } else if ($event.files[i].type === 'image/png') {
      formData.append('file', fileData, `.png`);
    } else if ($event.files[i].type === 'image/jpeg') {
      formData.append('file', fileData, `.jpeg`);
    } else {
      formData.append('file', fileData, `.jpg`);
    }

    this.paymentService.uploadImage(formData).subscribe(
      (res: HttpResponse<any>) => {
        this.wrongTransaction.mandatoryDocument = res.body.fileName;
        this.uFilename = this.wrongTransaction.mandatoryDocument;
        this.isFileUploaded = true;
        this.notificationService.alertSuccess('File Uploaded Successfully', '');
      },
      () => {
        this.notificationService.alertError(
          'Error, please make sure file size is within 5MB and in supported format',
          ''
        );
      }
    );
  }

  save() {
    this.ngSelectInvalid = false;
    const isFormValid =
      this.wrongTransaction.mandatoryDocument &&
      this.wrongTransaction.transactionReferenceNumber &&
      this.wrongTransaction.snaTransactionDate &&
      this.wrongTransaction.description;
    if (!isFormValid && this.wrongTransaction.revertType === 'O.K.') {
      this.ngSelectInvalid = true;
      this.notificationService.alertError(
        'Please fill all the mandotory fields marked with *',
        ''
      );
      return;
    }
   
    this.confirmationService.confirm({
      message: 'Are you sure want to Revert this ' + this.wrongTransaction.voucherNo,
      header: 'Confirmation',

      accept: () => {
        this.spinner.show();
        this.paymentService.revertVoucher(this.wrongTransaction).subscribe(
          (res) => {
            this.spinner.hide();
            this.notificationService.alertSuccess('Record Updated Sucessfully', '');
            this.router.navigate(['/state-dashboard'], {});
          },
          (onError) => {
            if (onError.status == 400) {
              this.notificationService.alertError(onError.error.title, '');
            } else {
              this.notificationService.alertError('Error occured, try again', '');
            }
    
            this.spinner.hide();
          }
        );
      },
      reject: () => {},
    });
  }
    
  }


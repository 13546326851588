<div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <p-toolbar styleClass="toolbar">
          <ng-template pTemplate="left">
            <h5 [ngClass]="fontService.headingClass" jhiTranslate="tableHead.iACompo.districtAllocation.list">District Allocation List</h5>
          </ng-template>
          <ng-template pTemplate="right">
            <button pButton pRipple icon="pi pi-plus" class="p-button-secondary mr-2 custom-button  new-button" (click)="openNew()"
              styleClass="button">
              <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.newbutton">
              </span>
              </button>
              <button pButton pRipple icon="pi pi-plus" class="p-button-secondary custom-button  new-button" (click)="openEdit()"
              styleClass="button">
              <span class="button-label" [ngClass]="fontService.buttonClass">Edit/Update
              </span>
              </button>            
          </ng-template>
        </p-toolbar>
      </div>
    </div>
    <div class="row">
      <div #customerTable id="entities" class="col-12 px-4">
        <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" (searchParam)="onLazyLoad($event)"
          [disableDeleteIcon]="true" [disableEditIcon]="true" (emitRow)="onSelectRow($event)" [enableViewIcon]="true"></custom-filter-table>
      </div>
    </div>
    <p-dialog #dialog [(visible)]="limitSetDialog" [modal]="true" (onHide)="hideDialog()" styleClass="ib-dialog"
      [style]="{ width: '850px' }">
      <p-header [ngClass]="fontService.headingClass">
        {{"iACompo.limitTrans.distictAllocation"| translate}}
      </p-header>
      <ng-template pTemplate="content">
            <div class="row gy-2">
              <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" for="department" [ngClass]="fontService.labelClass"
                jhiTranslate="iACompo.limitTrans.department.name">Department</label>
                <ng-select [appendTo]="'.p-dialog'" id="department" [(ngModel)]="limitTransDto.departmentDto" [items]="departmentList"
                  bindLabel="name" appearance="outline" [closeOnSelect]="true" class="form-control" [clearable]="true"
                  [ngClass]="{ 'is-invalid': ngSelectInvalid && !limitTransDto.departmentDto }" [disabled]="viewOnly">
                </ng-select>
              </div>
              <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass"
                jhiTranslate="iACompo.limitTrans.scheme.name">Scheme</label>
                <ng-select [appendTo]="'.p-dialog'" id="scheme" [(ngModel)]="limitTransDto.schemeDto" [items]="schemeList" bindLabel="name"
                  appearance="outline" [closeOnSelect]="true" class="form-control" [clearable]="true" [disabled]="viewOnly"
                  [ngClass]="{ 'is-invalid': ngSelectInvalid && !limitTransDto.schemeDto }">
                </ng-select>
              </div>
              <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" for="gender" [ngClass]="fontService.labelClass"
                jhiTranslate="iACompo.limitTrans.fin.year.name">Fin Year</label>
                <ng-select [appendTo]="'.p-dialog'" id="gender" bindLabel="name" [items]="finYear" appearance="outline"
                  [(ngModel)]="limitTransDto.finYearDto" [closeOnSelect]="true" [clearable]="true" class="form-control"
                  [ngClass]="{ 'is-invalid': ngSelectInvalid && !limitTransDto.finYearDto }" [disabled]="viewOnly">
                </ng-select>
              </div>
              <div class="col-sm-6 col-md-4" *ngIf="limitTransDto.schemeDto && limitTransDto.finYearDto">
                <label class="form-control-label req" for="gender"  [ngClass]="fontService.labelClass"
                jhiTranslate="iACompo.limitTrans.levelType">Level Type</label>
                <ng-select [appendTo]="'.p-dialog'" id="gender" bindLabel="name" appearance="outline" [items]="levelTypeList"
                  [closeOnSelect]="true" [(ngModel)]="limitTransDto.levelTypeDto" [clearable]="true"
                  (change)="onLevelTypeChangeTrans();loadIA(limitTransDto.levelTypeDto.id)" class="form-control" [disabled]="viewOnly"
                  [ngClass]="{ 'is-invalid': ngSelectInvalid && !limitTransDto.levelTypeDto }">
                </ng-select>
              </div>
              <div class="col" *ngIf="limitTransDto.levelTypeDto?.name === 'District' || 
              limitTransDto.levelTypeDto?.name === 'Block' || limitTransDto.levelTypeDto?.name === 'Village'">
                              <label class="form-control-label" for="scheme" [ngClass]="fontService.labelClass"
                                  jhiTranslate="dashBoard.district">District</label>
                              <ng-select id="scheme" [(ngModel)]="districtListDto" [items]="this.districtLevelList"
                                  bindLabel="name" appearance="outline" (change)="onDistrictLevelChange();loadIA(districtListDto.id)"
                                  (search)="onLevelTypeChangeTrans($event)" [closeOnSelect]="true" [clearable]="false"
                                  class="form-control" [disabled]="viewOnly" [ngClass]="{ 'is-invalid': ngSelectInvalid && !districtListDto }">
                              </ng-select>
                          </div>
                          <div class="col" *ngIf="limitTransDto.levelTypeDto?.name === 'Block' || limitTransDto.levelTypeDto?.name === 'Village'">
                              <label class="form-control-label" for="scheme" [ngClass]="fontService.labelClass"
                                  jhiTranslate="dashBoard.block">Block</label>
                              <ng-select id="scheme" [(ngModel)]="BlockLevelDto" [items]="this.blockLevelList"
                                  bindLabel="name" appearance="outline" (change)="onBlockLevelChange();loadIA(BlockLevelDto.id)"
                                  (search)="onDistrictLevelChange($event)" [closeOnSelect]="true" [clearable]="false"
                                  class="form-control" [disabled]="viewOnly">
                              </ng-select>
                          </div>
                          <div class="col" *ngIf="limitTransDto.levelTypeDto?.name === 'Village'">
                              <label class="form-control-label" for="scheme" [ngClass]="fontService.labelClass"
                                  jhiTranslate="dashBoard.village">Village</label>
                              <ng-select id="scheme" [(ngModel)]="villageLevelDto" [items]="this.villageLevelList"
                                  bindLabel="name" appearance="outline" (change)="loadIA(villageLevelDto.id)" (search)="onBlockLevelChange($event)"
                                  [closeOnSelect]="true" [clearable]="false" class="form-control" [disabled]="viewOnly">
                              </ng-select>
                          </div>
              <div class="col-sm-6 col-md-4" *ngIf="limitTransDto.schemeDto && limitTransDto.finYearDto">
                <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass"
                jhiTranslate="iACompo.limitTrans.iA.name">Implementing Agency</label>
                <ng-select [appendTo]="'.p-dialog'" id="scheme" [(ngModel)]="limitTransDto.implementingAgencyDto" [items]="iaCompList"
                  bindLabel="name" appearance="outline" [searchable]="true" [closeOnSelect]="true" [clearable]="true"
                  (search)="onLevelTypeChangeTrans($event)" class="form-control" [disabled]="viewOnly"
                  [ngClass]="{ 'is-invalid': ngSelectInvalid && !limitTransDto.implementingAgencyDto }">
                </ng-select>
              </div>
              <div class="col-sm-6 col-md-4" *ngIf="edit">
                <label class="form-control-label" for="amount" [ngClass]="fontService.labelClass">District Allocation Amount</label>
                <input type="text" id="amount" name="amount" autocomplete="off"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                  class="form-control" [(ngModel)]="existingLimit"  disabled="true"/>
                </div>
              <div class="col-sm-6 col-md-4" *ngIf="edit">
                <label class="form-control-label" for="amount" [ngClass]="fontService.labelClass">Programme Fund Allocation Amount</label>
                <input type="text" id="amount" name="amount" autocomplete="off"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                  class="form-control" [(ngModel)]="existingProgrammeLimit"  disabled="true"/>
                </div>
                  <div class="col-sm-6 col-md-4" *ngIf="edit">
                    <label class="form-control-label" for="amount" [ngClass]="fontService.labelClass">Admin Fund Allocation Amount</label>
                    <input type="text" id="amount" name="amount" autocomplete="off"
                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                      class="form-control" [(ngModel)]="existingAdminLimit" disabled="true"/>
                    </div>
                  <div class="col-sm-6 col-md-4" *ngIf="edit">
                    <label class="form-control-label req" for="amount" [ngClass]="fontService.labelClass">Allocation Type</label>
                    <ng-select id="scheme" [items]="limitUpdateList" bindLabel="name" appearance="outline" [closeOnSelect]="true" class="form-control" 
                    [(ngModel)]="limitTransDto.allocationType" (change)="onlimitUpdate()" [clearable]="false" [ngClass]="{ 'is-invalid': ngSelectInvalid && !limitTransDto.allocationType }">
                    </ng-select>
                  </div>
                    <div class="col-sm-6 col-md-4">
                      <label class="form-control-label req" *ngIf="!edit" for="amount" [ngClass]="fontService.labelClass"> District Allocation Amount</label>
                      <label class="form-control-label req" *ngIf="edit" for="amount" [ngClass]="fontService.labelClass">Update District Allocation Amount</label>
                      <input type="text" id="amount" name="amount" autocomplete="off"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                        class="form-control" [(ngModel)]="limitTransDto.districtAllocationAmount" 
                        [ngClass]="{ 'is-invalid': ngSelectInvalid && !limitTransDto.districtAllocationAmount }"/>
                      </div>
                    <div class="col-sm-6 col-md-4">
                      <label class="form-control-label req"  *ngIf="!edit" for="amount" [ngClass]="fontService.labelClass">Programme Fund Allocation Amount</label>
                      <label class="form-control-label req"  *ngIf="edit" for="amount" [ngClass]="fontService.labelClass">Update Programme Fund Allocation Amount</label>
                      <input type="text" id="amount" name="amount" autocomplete="off"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                        class="form-control" [(ngModel)]="limitTransDto.programmeAllocationAmount" 
                        [ngClass]="{ 'is-invalid': ngSelectInvalid && !limitTransDto.programmeAllocationAmount }"/>
                      </div>
                        <div class="col-sm-6 col-md-4">
                          <label class="form-control-label req" *ngIf="!edit" for="amount" [ngClass]="fontService.labelClass">Admin Fund Allocation Amount</label>
                          <label class="form-control-label req" *ngIf="edit" for="amount" [ngClass]="fontService.labelClass">Update Admin Fund Allocation Amount</label>
                          <input type="text" id="amount" name="amount" autocomplete="off"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            class="form-control" [(ngModel)]="limitTransDto.adminAllocationAmount"  
                            [ngClass]="{ 'is-invalid': ngSelectInvalid && !limitTransDto.adminAllocationAmount }"/>
                          </div>
                    <div class="col-md-12">
                      <label class="form-control-label req" for="description" [ngClass]="fontService.labelClass"
                        jhiTranslate="funds.description">Description</label>
                      <textarea id="description" name="description" rows="2" [(ngModel)]="limitTransDto.description"
                      oninput="this.value = this.value.replace(/[^A-Za-z0-9 ,-.]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                  onblur="this.value =this.value.trim();" cols="100" [ngClass]="{'is-invalid':ngSelectInvalid && !limitTransDto.description}" [disabled]="viewOnly"></textarea>
                    </div>
                    <div class="col-md-12">
                      <label class="form-control-label req" for="upload" [ngClass]="fontService.labelClass">File Upload</label><br>
                      <small [ngClass]="fontService.regularClass" jhiTranslate="funds.note">Note : Allowed file
                          types are jpg, jpeg, png, pdf and
                          Maximum file size should be 1MB</small>
                  </div>
                  <div class="col-md-12" *ngIf="!isFile">
                      <p-fileUpload (onUpload)="onUploadfile1($event)" [customUpload]="true" auto="true" 
                      (uploadHandler)="onUploadfile1($event)" multiple="false" [showUploadButton]="false" [disabled]="isFile || viewOnly" [showCancelButton]="false" chooseLabel="{{'common.upload'|translate}}">
                      </p-fileUpload>
                  </div>
                  <div class="row mt-2" *ngIf="!isFile && ngSelectInvalid">
                    <small class="text-danger">Upload the File</small>
                  </div>
                  <div class="col" *ngIf="isFile">
                      <a class="cursor-pointer" (click)="viewDocument()">{{limitTransDto.fileName}}</a>
                      <i *ngIf="!viewOnly" class="pi pi-times add-margin" (click)="removeDocument()"></i>
                  </div>
                <div class="col-sm-12 col-md-12">
                      <label class="form-control-label" for="lbc" [ngClass]="fontService.labelClass">Do you want to add LBC?</label><br>
                      <p-radioButton name="option" [disabled]="true" 
                      [value]="true" [(ngModel)]="enableLbc" label="{{'payments.amount.yes'|translate}}" ></p-radioButton>
                      <p-radioButton name="option" [disabled]="true"  
                      [value]="false" [(ngModel)]="enableLbc" (onClick)="limitTransDto.lbcAmount=0;limitTransDto.lbcPercentage=0;" label="{{'payments.amount.no'|translate}}" ></p-radioButton>
                      <!-- <p-radioButton name="option" [disabled]="limitTransDto.levelTypeDto?.name === 'Block' || limitTransDto.levelTypeDto?.name === 'Village' ? true : null" 
                      [value]="true" [(ngModel)]="enableLbc" label="{{'payments.amount.yes'|translate}}" ></p-radioButton>
                      <p-radioButton name="option" [disabled]="limitTransDto.levelTypeDto?.name === 'Block' || limitTransDto.levelTypeDto?.name === 'Village' ? true : null"  
                      [value]="false" [(ngModel)]="enableLbc" (onClick)="limitTransDto.lbcAmount=0;limitTransDto.lbcPercentage=0;" label="{{'payments.amount.no'|translate}}" ></p-radioButton> -->
                </div>
                <div class="col-sm-6 col-md-4" *ngIf="enableLbc">
                  <label class="form-control-label" for="lbcPer" [ngClass]="fontService.labelClass">LBC %</label><br>
                  <input type="text" id="lbcPer" name="lbcPer" autocomplete="off" [(ngModel)]="limitTransDto.lbcPercentage" (input)="calcLbc()"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" 
                    class="form-control" [attr.disabled]="limitTransDto.levelTypeDto?.name === 'Block' || limitTransDto.levelTypeDto?.name === 'Village' ? true : null" />
                </div>
                <div class="col-sm-6 col-md-4" *ngIf="enableLbc">
                  <label class="form-control-label" for="lbcAmount" [ngClass]="fontService.labelClass">LBC Amount</label><br>
                  <input type="text" id="lbcAmount" name="lbcAmount" [(ngModel)]="limitTransDto.lbcAmount" disabled="true" class="form-control" />
                </div>
              </div>
            <div class="text-end">
            <button *ngIf="!viewOnly" pButton pRipple (click)="saveData()" icon="pi pi-check" class="p-button-text custom-button">
              <span
            class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.savebutton">
              </span>
            </button>
            </div>
      </ng-template>
    </p-dialog>
    <p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
    <p-dialog
      [(visible)]="viewDoc"
      [style]="{ width: '1000px', height: '800px' }"
      [modal]="true"
      [baseZIndex]="1000"
      styleClass="ib-dialog"
    >
    <ng-template pTemplate="body">
      <div style='position: relative; height: 100%;'>
      <ngx-extended-pdf-viewer *ngIf="vFileExt === 'pdf'" [src]="vFileUrl"></ngx-extended-pdf-viewer>
        <img alt="Uploaded File View" *ngIf="vFileExt !== 'pdf'"  [src]="vFileUrl" style="display: block;" height="800px" />
      </div>
    </ng-template>
    </p-dialog>
  </div>
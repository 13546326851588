<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass" >Work  Deductions</h5>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="input-row">
        <p-calendar [(ngModel)]="date" view="month" dateFormat="mm/yy" [readonlyInput]="true"></p-calendar>
        <button pButton pRipple label="Search" (click)="onSearch()" class="p-button-sm"></button>
    </div>
    <div class="row">
       
        <div #customerTable id="entities" class="col-md-12 px-4">
            <custom-filter-table [fileName]="'WorkDeductions'" [columnsValues]="cols" [filterSearchResult]="searchResult" (searchParam)="onLazyLoad($event)" ></custom-filter-table>
        </div>
            </div>
</div>


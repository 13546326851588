export const userRoles = {
roleMaker:'DMAKER',
roleChecker:'DC1',
roleApprover:'DC2',
roleState:'SA',
roleStateMaker:'SMAKER',
roleStateChecker:'SC1',
roleStateApprover:'SC2',
roleDistrictMonitor:'DM',
roleStateMonitor:'SM'
};
<div class="px-4  pt-2">
    <div class="col-lg-2 col-md-4">
        <ng-select id="gender" bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true" class="form-control" [items]="finYear" [(ngModel)]="finYearDto" (ngModelChange)="onFinyearChange(levelMasterId)">
        </ng-select>
    </div>
    <div class="row  g-3 mb-3 pt-8 pt-2">
        <div class="col-md-9">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-6">
                        <div class="card p-3" style="border-radius: 25px;box-shadow: 0px 20px 20px #E8E8E8;" (click)="voucherPendingPaid()">
                            <div class="row">
                                <h3>Voucher created and to be paid</h3>
                            </div>
                            <div class="row g-3 mb-3">
                                <div class="col-md-7">
                                    <div class="row g-3 mt-2">
                                        <div class="col-md-2"></div>
                                        <div class="col-md-9" style="display: inline-block; width: 180px; height: 180px; border: 20px solid #162a72; border-radius: 50%; text-align: center; padding-right: 10px;">
                                            <div style="text-align: center;padding-top: 40px;">
                                                <h3 style="color:#11263C;">Allocation</h3>
                                                <h1 style="color: #11263C;">{{formatCash(overAllDataCardDetails?.allocationAmount)}}</h1>
                                            </div>
                                        </div>
                                        <div class="col-md-2"></div>
                                    </div>
                                </div>
                                <div class="col-md-5">
                                    <div class="row g-3">
                                        <div class="col-md-12">
                                            <h6 style="font-size: 13px;">Gross Expenditure (Rs . In crore)</h6>
                                            <h1 style="color: #11263C;">{{formatCash(overAllDataCardDetails?.pendingGrossExpenditure)}}</h1>
                                        </div>
                                    </div>

                                    <div class="row g-3 mt-3">
                                        <div class="col-md-12">
                                            <h6 style="font-size: 13px;">Net Expenditure (Rs . In crore)</h6>
                                            <h1 style="color: #11263C;">{{formatCash(overAllDataCardDetails?.pendingNetExpenditure)}}</h1>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div style="padding-left: 2px;" class="col-md-6">
                        <div class="card p-3" style="border-radius: 25px;box-shadow: 0px 20px 20px #E8E8E8;" (click)="voucherPaidSuccess()">
                            <div class="row">
                                <h3>Payment Completed (as on date)</h3>
                            </div>
                            <div class="row g-3 mb-3">
                                <div class="col-md-7">
                                    <div class="row g-3 mt-2">
                                        <div class="col-md-2"></div>
                                        <div class="col-md-9" style="display: inline-block; width: 180px; height: 180px; border: 20px solid #162a72; border-radius: 50%; text-align: center; padding-right: 10px;">
                                            <div style="text-align: center;padding-top: 40px;">
                                                <h3 style="color:#11263C;">Allocation</h3>
                                                <h1 style="color: #11263C;">{{formatCash(overAllDataCardDetails?.allocationAmount)}}</h1>
                                            </div>
                                        </div>
                                        <div class="col-md-2"></div>
                                    </div>
                                </div>
                                <div class="col-md-5">
                                    <div class="row g-3">
                                        <div class="col-md-12">
                                            <h6 style="font-size: 13px;">Gross Expenditure (Rs . In crore)</h6>
                                            <h1 style="color: #11263C;">{{formatCash(overAllDataCardDetails?.successGrossExpenditure)}}</h1>
                                        </div>
                                    </div>

                                    <div class="row g-3 mt-3">
                                        <div class="col-md-12">
                                            <h6 style="font-size: 13px;">Net Expenditure (Rs . In crore)</h6>
                                            <h1 style="color: #11263C;">{{formatCash(overAllDataCardDetails?.successNetExpenditure)}}</h1>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mt-2">
                <div class="col-md-12">
                    <div class="card p-3" style="border-radius: 25px;box-shadow: 0px 20px 20px #E8E8E8;" (click)="loginWiseVoucher()">
                        <div class="row">
                            <h3>Bill pending status</h3>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="row">
                                    <h6 style="color:#E19133;">AO</h6>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-6">
                                        <h3 style="color: #FFFFFF;">
                                            <span style="background: #ED654C;padding:7px;border-radius: 10px;">{{overAllDataCardDetails?.aoCount}}</span>
                                        </h3>
                                        <h6 style="padding-top:5px;">Number of Bills</h6>
                                    </div>
                                    <div class="col-md-6">
                                        <h3 style="color: #FFFFFF;">
                                            <span style="background: #ED654C;padding:7px;border-radius: 10px;">{{formatCash(overAllDataCardDetails?.aoExpenditure)}}</span>
                                        </h3>
                                        <h6 style="padding-top:5px;">Amount (Rs. In crore)</h6>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="row">
                                    <h6 style="color:#845EBC;">PD/AC(Dev.)</h6>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-6">
                                        <h3 style="color: #FFFFFF;">
                                            <span style="background: #ED654C; padding:7px;border-radius: 10px;">{{overAllDataCardDetails?.pdCount}}</span>
                                        </h3>
                                        <h6 style="padding-top:5px;">Number of Bills</h6>
                                    </div>
                                    <div class="col-md-6">
                                        <h3 style="color: #FFFFFF;">
                                            <span style="background: #ED654C; padding:7px;border-radius: 10px;">{{formatCash(overAllDataCardDetails?.pdExpenditure)}}</span>
                                        </h3>
                                        <h6 style="padding-top:5px;">Amount (Rs. In crore)</h6>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="row">
                                    <h6 style="color:#1570EF;">District Collector</h6>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-6">
                                        <h3 style="color: #FFFFFF;">
                                            <span style="background: #ED654C;padding:7px;border-radius: 10px;">{{overAllDataCardDetails?.collectorCount}}</span>
                                        </h3>
                                        <h6 style="padding-top:5px;">Number of Bills</h6>
                                    </div>
                                    <div class="col-md-6">
                                        <h3 style="color: #FFFFFF;">
                                            <span style="background: #ED654C;padding:7px;border-radius: 10px;">{{formatCash(overAllDataCardDetails?.collectorExpenditure)}}</span>
                                        </h3>
                                        <h6 style="padding-top:5px;">Amount (Rs. In crore)</h6>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mt-2">
                <div class="card p-3" style="border-radius: 25px; box-shadow: 0px 20px 20px #E8E8E8;" (click)="finalBillVoucher()">
                    <div class="row">
                        <h3>Final bill paid works so far</h3>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="row">
                                <h6 style="color:#E19133">Allocation</h6>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-6">
                                    <h3 style="color: #FFFFFF;">
                                        <span style="background: #1570EF;padding:7px;border-radius: 10px;">{{overAllDataCardDetails?.workCount}}</span>
                                    </h3>
                                    <h6 style="padding-top:5px;">Number of Works</h6>
                                </div>
                                <div class="col-md-6">
                                    <h3 style="color: #FFFFFF;">
                                        <span style="background: #1570EF;padding:7px;border-radius: 10px;">{{formatCash(overAllDataCardDetails?.workAllocation)}}</span>
                                    </h3>
                                    <h6 style="padding-top:5px;">Amount (Rs. In crore)</h6>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="row">
                                <h6 style="color:#845EBC;">Final bill paid so far</h6>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-6">
                                    <h3 style="color: #FFFFFF;">
                                        <span style="background: #1570EF;padding:7px;border-radius: 10px;">{{overAllDataCardDetails?.workFinalBillCount}}</span>
                                    </h3>
                                    <h6 style="padding-top:5px;">Number of Works</h6>
                                </div>
                                <div class="col-md-6">
                                    <h3 style="color: #FFFFFF;">
                                        <span style="background: #1570EF;padding:7px;border-radius: 10px;">{{formatCash(overAllDataCardDetails?.workFinalBillExpenditure)}}</span>
                                    </h3>
                                    <h6 style="padding-top:5px;">Amount (Rs. In crore)</h6>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="row">
                                <div class="col-md-6">

                                </div>
                            </div>
                            <div class="row mt-5">
                                <div class="col-md-6">
                                    <h3 style="color: #FFFFFF;">
                                        <span style="background: #1570EF;padding:7px;border-radius: 10px;">{{overAllDataCardDetails?.finalBillPercentage.toFixed(2)}}%</span>
                                    </h3>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>


        </div>
        <div class="col-md-3">
            <div class="col-md-12">
                <div class="card p-3" style="border-radius: 25px;box-shadow: 0px 20px 20px #E8E8E8;" (click)="zeroBillVoucher()">
                    <div class="row">
                        <h3>Zero Expenditure</h3>
                    </div>
                    <div class="row">
                        <h6 style="color:#E19133;">Allocation</h6>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <h1 style="color: #11263C;">{{overAllDataCardDetails?.workCount}}</h1>
                            <h6>Number of Works</h6>
                        </div>
                        <div class="col-md-6">
                            <h1 style="color: #11263C;">{{formatCash(overAllDataCardDetails?.workAllocation)}}</h1>
                            <h6>Amount (Rs. In crore)</h6>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <h6 style="color:#845EBC;">Zero Payment</h6>
                        <h1 style="color: #11263C;">{{overAllDataCardDetails?.zeroBillPayment}}</h1>
                        <h6>Number of Works</h6>
                    </div>
                    <div class="row mt-2">
                        <div class="col-md-1">
                        </div>
                        <div style="background: #ED654C;border-radius: 10px;" class="col-md-10">
                            <div class="row mt-2">

                                <div style="text-align: center;" class="col-md-12">
                                    <h6 style="color: #FFFFFF;">% of Zero Payment</h6>
                                    <h1 style="color: #FFFFFF;">{{(overAllDataCardDetails?.zeroBillPercentage).toFixed(2)}}%</h1>
                                </div>

                            </div>
                        </div>
                        <div class="col-md-1">
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-md-12 mt-2">
                <div class="card p-3" style="border-radius: 25px; box-shadow: 0px 20px 20px #E8E8E8;" (click)="thirtyDaysExpenditure()">
                    <div class="row">
                        <h3>Last 30 days Expenditure</h3>
                    </div>
                    <div class="row">
                        <h6 style="color:#E19133;">Allocation</h6>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <h1 style="color: #11263C;">{{overAllDataCardDetails?.totalVoucherCount}}</h1>
                            <h6>Number of Bills</h6>
                        </div>
                        <div class="col-md-6">
                            <h1 style="color: #11263C;">{{formatCash(overAllDataCardDetails?.totalExpenditure)}}</h1>
                            <h6>Amount (Rs. In crore)</h6>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <h6 style="color:#845EBC;">Last 30 days expenditure</h6>
                        <div class="col-md-6">
                            <h1 style="color: #11263C;">{{overAllDataCardDetails?.lastThirtyDaysVoucherCount}}</h1>
                            <h6>Number of Bills</h6>
                        </div>
                        <div class="col-md-6">
                            <h1 style="color: #11263C;">{{formatCash(overAllDataCardDetails?.lastThirtyDaysExpenditure)}}</h1>
                            <h6>Amount (Rs. In crore)</h6>
                        </div>

                    </div>
                    <div class="row mt-2">
                        <div class="col-md-1"></div>
                        <div style="background: #ED654C;border-radius: 15px;" class="col-md-10">
                            <div class="row mt-2">

                                <div style="text-align: center;" class="col-md-12">
                                    <h6 style="color: #FFFFFF;">Expenditure in last 30 days</h6>
                                    <h1 style="color: #FFFFFF;">{{(overAllDataCardDetails?.expenditurePercentage).toFixed(2)}}%</h1>
                                </div>

                            </div>
                        </div>
                        <div class="col-md-1"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<p-dialog [(visible)]="vouchercreated" [style]="{ width: '1350px', height: '3000px' }" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog">
    <div>
        <p-toolbar styleClass="toolbar">
            <ng-template pTemplate="left">
                <div *ngIf="!levelMasterId" class="row">
                    <div class="col-sm">
                        <label class="form-control-label" for="scheme">District</label>
                        <ng-select id="scheme" bindLabel="name" [(ngModel)]="district" [items]="this.districtLevelList" appearance="outline" [closeOnSelect]="true" (change)="voucherPendingPaid(district.id)" [clearable]="false" class="form-control">
                        </ng-select>
                    </div>
                    <div class="col-sm mt-4">
                        <p-button (click)="this.district=null;voucherPendingPaid()" styleClass="p-button-sm p-button-secondary me-4 custom-button">
                            <span class="button-label">Clear</span>
                        </p-button>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="right">
                <p-button (click)="exportExcel( 'unpaid')" styleClass="p-button-sm p-button-success me-4 custom-button">
                    <span class="button-label" jhiTranslate="common.exportexcel"></span>
                </p-button>
            </ng-template>
        </p-toolbar>

        <p-table #reportTable [value]="voucherDetails?.data" id="reportTable" styleClass="p-datatable-gridlines" [resizableColumns]="true" responsiveLayout="scroll">
            <ng-template pTemplate="header">
                <tr>
                    <th colspan="5" style="text-align: center;">Voucher created and to be paid</th>
                    <th></th>
                </tr>
                <tr>
                    <th style="text-align: center;">S.No</th>
                    <th style="text-align: center;">District</th>
                    <th style="text-align: center;">Allocation<br>(Rs. In crore)</th>
                    <th style="text-align: center;">Gross Expenditure<br>(Rs . In crore)</th>
                    <!-- <th style="text-align: center;">% of expenditure (Gross <br>Expenditure/ Allocation)</th> -->
                    <th style="text-align: center;">Net Expenditure<br> (Rs . In crore)</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-list let-index="rowIndex">
                <tr>
                    <td style="text-align: center;">{{index+1}}</td>
                    <td style="text-align: center;">{{list?.name}}</td>
                    <td style="text-align: center;">{{formatCash(list?.allocationAmount)}}</td>
                    <td style="text-align: center;">{{formatCash(list?.grossExpenditure)}}</td>
                    <!-- <td style="text-align: center;">{{list?.percentage}}%</td> -->
                    <td style="text-align: center;">{{formatCash(list?.netExpenditure)}}</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="footer">
                <tr>

                    <td colspan="2" style="text-align: center;">Total</td>
                    <td style="text-align: center;">{{formatCash(voucherDetails?.totalData?.allocationAmount)}}</td>
                    <td style="text-align: center;">{{formatCash(voucherDetails?.totalData?.grossExpenditure)}}</td>
                    <!-- <td style="text-align: center;">{{voucherDetails?.totalData?.percentage}}%</td> -->
                    <td style="text-align: center;">{{formatCash(voucherDetails?.totalData?.netExpenditure)}}</td>

                </tr>
            </ng-template>
        </p-table>
    </div>
</p-dialog>

<p-dialog [(visible)]="voucherpaid" [style]="{ width: '1350px', height: '3000px' }" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog">
    <div>
        <p-toolbar styleClass="toolbar">
            <ng-template pTemplate="left">
                <div *ngIf="!levelMasterId" class="row">
                    <div class="col-sm">
                        <label class="form-control-label" for="scheme">District</label>
                        <ng-select id="scheme" bindLabel="name" [(ngModel)]="district" [items]="this.districtLevelList" appearance="outline" [closeOnSelect]="true" (change)="voucherPaidSuccess(district.id)" [clearable]="false" class="form-control">
                        </ng-select>
                    </div>
                    <div class="col-sm mt-4">
                        <p-button (click)="this.district=null;voucherPaidSuccess()" styleClass="p-button-sm p-button-secondary me-4 custom-button">
                            <span class="button-label">Clear</span>
                        </p-button>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="right">
                <p-button (click)="exportExcel('paid')" styleClass="p-button-sm p-button-success me-4 custom-button">
                    <span class="button-label" jhiTranslate="common.exportexcel"></span>
                </p-button>
            </ng-template>
        </p-toolbar>
        <p-table #paidReportTable [value]="voucherpaidDetails?.data" id="paidReportTable" styleClass="p-datatable-gridlines" [resizableColumns]="true" responsiveLayout="scroll">
            <ng-template pTemplate="header">
                <tr>
                    <th colspan="5" style="text-align: center;">Successful payments made so far</th>
                    <th></th>
                </tr>
                <tr>
                    <th style="text-align: center;">S.No</th>
                    <th style="text-align: center;">District</th>
                    <th style="text-align: center;">Allocation<br>(Rs. In crore)</th>
                    <th style="text-align: center;">Gross Expenditure<br>(Rs . In crore)</th>
                    <!-- <th style="text-align: center;">% of expenditure (Gross <br>Expenditure/ Allocation)</th> -->
                    <th style="text-align: center;">Net Expenditure<br> (Rs . In crore)</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-list let-index="rowIndex">
                <tr>
                    <td style="text-align: center;">{{index+1}}</td>
                    <td style="text-align: center;">{{list?.name}}</td>
                    <td style="text-align: center;">{{formatCash(list?.allocationAmount)}}</td>
                    <td style="text-align: center;">{{formatCash(list?.grossExpenditure)}}</td>
                    <!-- <td style="text-align: center;">{{list?.percentage}}%</td> -->
                    <td style="text-align: center;">{{formatCash(list?.netExpenditure)}}</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="footer">
                <tr>

                    <td colspan="2" style="text-align: center;">Total</td>
                    <td style="text-align: center;">{{formatCash(voucherpaidDetails?.totalData?.allocationAmount)}}</td>
                    <td style="text-align: center;">{{formatCash(voucherpaidDetails?.totalData?.grossExpenditure)}}</td>
                    <!-- <td style="text-align: center;">{{voucherpaidDetails?.totalData?.percentage}}%</td> -->
                    <td style="text-align: center;">{{formatCash(voucherpaidDetails?.totalData?.netExpenditure)}}</td>

                </tr>
            </ng-template>
        </p-table>
    </div>
</p-dialog>

<p-dialog [(visible)]="loginWiseVoucherShow" [style]="{ width: '1350px', height: '3000px' }" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog">
    <div>
        <p-toolbar styleClass="toolbar">
            <ng-template pTemplate="left">
                <div *ngIf="!levelMasterId" class="row">
                    <div class="col-sm">
                        <label class="form-control-label" for="scheme">District</label>
                        <ng-select id="scheme" bindLabel="name" [(ngModel)]="district" [items]="this.districtLevelList" appearance="outline" [closeOnSelect]="true" (change)="loginWiseVoucher(district.id)" [clearable]="false" class="form-control">
                        </ng-select>
                    </div>
                    <div class="col-sm mt-4">
                        <p-button (click)="this.district=null;loginWiseVoucher()" styleClass="p-button-sm p-button-secondary me-4 custom-button">
                            <span class="button-label">Clear</span>
                        </p-button>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="right">
                <p-button (click)="exportExcel('voucherPendingBills')" styleClass="p-button-sm p-button-success me-4 custom-button">
                    <span class="button-label" jhiTranslate="common.exportexcel"></span>
                </p-button>
            </ng-template>
        </p-toolbar>
        <p-table #voucherBillReportTable [value]="loginWiseDetails?.data" id="voucherBillReportTable" styleClass="p-datatable-gridlines" [resizableColumns]="true" responsiveLayout="scroll">
            <ng-template pTemplate="header">
                <tr>
                    <th colspan="8" style="text-align: center;">Login wise pending bills</th>
                </tr>
                <tr>
                    <th rowspan="3" style="text-align: center;">S.No</th>
                    <th rowspan="3" style="text-align: center;">District</th>
                </tr>
                <tr>
                    <th style="text-align: center;" colspan="2">AO Login</th>
                    <th style="text-align: center;" colspan="2">PD/AC(Dev.) Login</th>
                    <th style="text-align: center;" colspan="2">District Collector Login</th>
                </tr>
                <tr>
                    <th style="text-align: center;">Number of Bills</th>
                    <th style="text-align: center;">Amount<br>(Rs. In crore)</th>
                    <th style="text-align: center;">Number of Bills</th>
                    <th style="text-align: center;">Amount<br>(Rs. In crore)</th>
                    <th style="text-align: center;">Number of Bills</th>
                    <th style="text-align: center;">Amount<br>(Rs. In crore)</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-list let-index="rowIndex">
                <tr>
                    <td style="text-align: center;">{{index+1}}</td>
                    <td style="text-align: center;">{{list?.name}}</td>
                    <td style="text-align: center;">{{list?.aoCount}}</td>
                    <td style="text-align: center;">{{formatCash(list?.aoAmount)}}</td>
                    <td style="text-align: center;">{{list?.pdCount}}</td>
                    <td style="text-align: center;">{{formatCash(list?.pdAmount)}}</td>
                    <td style="text-align: center;">{{list?.collectorCount}}</td>
                    <td style="text-align: center;">{{formatCash(list?.collectorAmount)}}</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="footer">
                <tr>

                    <td colspan="2" style="text-align: center;">Total</td>
                    <td style="text-align: center;">{{loginWiseDetails?.totalData?.aoCount}}</td>
                    <td style="text-align: center;">{{formatCash(loginWiseDetails?.totalData?.aoAmount)}}</td>
                    <td style="text-align: center;">{{loginWiseDetails?.totalData?.pdCount}}</td>
                    <td style="text-align: center;">{{formatCash(loginWiseDetails?.totalData?.pdAmount)}}</td>
                    <td style="text-align: center;">{{loginWiseDetails?.totalData?.collectorCount}}</td>
                    <td style="text-align: center;">{{formatCash(loginWiseDetails?.totalData?.collectorAmount)}}</td>

                </tr>
            </ng-template>
        </p-table>
    </div>
</p-dialog>
<p-dialog [(visible)]="finalBillShow" [style]="{ width: '1350px', height: '3000px' }" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog">
    <div>
        <p-toolbar styleClass="toolbar">
            <ng-template pTemplate="left">
                <div *ngIf="!levelMasterId" class="row">
                    <div class="col-sm">
                        <label class="form-control-label" for="scheme">District</label>
                        <ng-select id="scheme" bindLabel="name" [(ngModel)]="district" [items]="this.districtLevelList" appearance="outline" [closeOnSelect]="true" (change)="finalBillVoucher(district.id)" [clearable]="false" class="form-control">
                        </ng-select>
                    </div>
                    <div class="col-sm mt-4">
                        <p-button (click)="this.district=null;finalBillVoucher()" styleClass="p-button-sm p-button-secondary me-4 custom-button">
                            <span class="button-label">Clear</span>
                        </p-button>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="right">
                <p-button (click)="exportExcel('final')" styleClass="p-button-sm p-button-success me-4 custom-button">
                    <span class="button-label" jhiTranslate="common.exportexcel"></span>
                </p-button>
            </ng-template>
        </p-toolbar>
        <p-table #finalBillReportTable [value]="finalBillDetails?.data" id="finalBillReportTable" styleClass="p-datatable-gridlines" [resizableColumns]="true" responsiveLayout="scroll">
            <ng-template pTemplate="header">
                <tr>
                    <th colspan="8" style="text-align: center;">Final bill paid works so far</th>
                </tr>
                <tr>
                    <th style="text-align: center;" rowspan="3">S.No</th>
                    <th style="text-align: center;" rowspan="3">District</th>
                    <th style="text-align: center;" colspan="2">Allocation</th>
                    <th style="text-align: center;" colspan="2">Final bill paid so far</th>
                    <th style="text-align: center;" rowspan="3">% of Final bills <br>paid</th>

                </tr>
                <tr>
                    <th style="text-align: center;">No. of works</th>
                    <th style="text-align: center;">Amount (Rs. In crore)</th>
                    <th style="text-align: center;">No. of works</th>
                    <th style="text-align: center;">Amount (Rs. In crore)</th>
                </tr>

            </ng-template>
            <ng-template pTemplate="body" let-list let-index="rowIndex">
                <tr>
                    <td style="text-align: center;">{{index+1}}</td>
                    <td style="text-align: center;">{{list?.name}}</td>
                    <td style="text-align: center;">{{list?.aoCount}}</td>
                    <td style="text-align: center;">{{formatCash(list?.aoAmount)}}</td>
                    <td style="text-align: center;">{{list?.pdCount}}</td>
                    <td style="text-align: center;">{{formatCash(list?.pdAmount)}}</td>
                    <td style="text-align: center;">{{list?.collectorAmount}}%</td>

                </tr>
            </ng-template>
            <ng-template pTemplate="footer">
                <tr>

                    <td colspan="2" style="text-align: center;">Total</td>
                    <td style="text-align: center;">{{finalBillDetails?.totalData?.aoCount}}</td>
                    <td style="text-align: center;">{{formatCash(finalBillDetails?.totalData?.aoAmount)}}</td>
                    <td style="text-align: center;">{{finalBillDetails?.totalData?.pdCount}}</td>
                    <td style="text-align: center;">{{formatCash(finalBillDetails?.totalData?.pdAmount)}}</td>
                    <td style="text-align: center;">{{finalBillDetails?.totalData?.collectorAmount}}%</td>


                </tr>
            </ng-template>
        </p-table>
    </div>
</p-dialog>
<p-dialog [(visible)]="zerobillPayment" [style]="{ width: '1350px', height: '3000px' }" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog">
    <div>
        <p-toolbar styleClass="toolbar">
            <ng-template pTemplate="left">
                <div *ngIf="!levelMasterId" class="row">
                    <div class="col-sm">
                        <label class="form-control-label" for="scheme">District</label>
                        <ng-select id="scheme" bindLabel="name" [(ngModel)]="district" [items]="this.districtLevelList" appearance="outline" [closeOnSelect]="true" (change)="zeroBillVoucher(district.id)" [clearable]="false" class="form-control">
                        </ng-select>
                    </div>
                    <div class="col-sm mt-4">
                        <p-button (click)="this.district=null;zeroBillVoucher()" styleClass="p-button-sm p-button-secondary me-4 custom-button">
                            <span class="button-label">Clear</span>
                        </p-button>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="right">
                <p-button (click)="exportExcel('zero')" styleClass="p-button-sm p-button-success me-4 custom-button">
                    <span class="button-label" jhiTranslate="common.exportexcel"></span>
                </p-button>
            </ng-template>
        </p-toolbar>
        <p-table #zeroBillReportTable [value]="zeroBillDetails?.data" id="zeroBillReportTable" styleClass="p-datatable-gridlines" [resizableColumns]="true" responsiveLayout="scroll">
            <ng-template pTemplate="header">
                <tr>
                    <th colspan="8" style="text-align: center;">Zero bill paid works</th>
                </tr>
                <tr>
                    <th style="text-align: center;" rowspan="3">S.No</th>
                    <th style="text-align: center;" rowspan="3">District</th>
                    <th style="text-align: center;" colspan="2">Allocation</th>
                    <th style="text-align: center;" colspan="1">Zero Payment</th>
                    <th style="text-align: center;" rowspan="3">% of Zero <br> Payment</th>

                </tr>
                <tr>
                    <th style="text-align: center;">No. of works</th>
                    <th style="text-align: center;">Amount (Rs. In crore)</th>
                    <th style="text-align: center;">No. of works</th>
                </tr>

            </ng-template>
            <ng-template pTemplate="body" let-list let-index="rowIndex">
                <tr>
                    <td style="text-align: center;">{{index+1}}</td>
                    <td style="text-align: center;">{{list?.name}}</td>
                    <td style="text-align: center;">{{list?.aoCount}}</td>
                    <td style="text-align: center;">{{formatCash(list?.aoAmount)}}</td>
                    <td style="text-align: center;">{{list?.pdCount}}</td>

                    <td style="text-align: center;">{{list?.collectorAmount}}%</td>

                </tr>
            </ng-template>
            <ng-template pTemplate="footer">
                <tr>
                    <td colspan="2" style="text-align: center;">Total</td>
                    <td style="text-align: center;">{{zeroBillDetails?.totalData?.aoCount}}</td>
                    <td style="text-align: center;">{{formatCash(zeroBillDetails?.totalData?.aoAmount)}}</td>
                    <td style="text-align: center;">{{zeroBillDetails?.totalData?.pdCount}}</td>
                    <td style="text-align: center;">{{zeroBillDetails?.totalData?.collectorAmount}}%</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-dialog>
<p-dialog [(visible)]="lastThirtyDaysExpenditure" [style]="{ width: '1350px', height: '3000px' }" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog">
    <div>
        <p-toolbar styleClass="toolbar">
            <ng-template pTemplate="left">
                <div *ngIf="!levelMasterId" class="row">
                    <div class="col-sm">
                        <label class="form-control-label" for="scheme">District</label>
                        <ng-select id="scheme" bindLabel="name" [(ngModel)]="district" [items]="this.districtLevelList" appearance="outline" [closeOnSelect]="true" (change)="thirtyDaysExpenditure(district.id)" [clearable]="false" class="form-control">
                        </ng-select>
                    </div>
                    <div class="col-sm mt-4">
                        <p-button (click)="this.district=null;thirtyDaysExpenditure()" styleClass="p-button-sm p-button-secondary me-4 custom-button">
                            <span class="button-label">Clear</span>
                        </p-button>
                    </div>
                </div>

            </ng-template>
            <ng-template pTemplate="right">
                <p-button (click)="exportExcel('lastThirty')" styleClass="p-button-sm p-button-success me-4 custom-button">
                    <span class="button-label" jhiTranslate="common.exportexcel"></span>
                </p-button>
            </ng-template>
        </p-toolbar>
        <p-table #lastThirtyReportTable [value]="lastThirtyDaysExpenditureDetails?.data" id="lastThirtyReportTable" styleClass="p-datatable-gridlines" [resizableColumns]="true" responsiveLayout="scroll">
            <ng-template pTemplate="header">
                <tr>
                    <th colspan="8" style="text-align: center;">Last 30 days expenditure details</th>
                </tr>
                <tr>
                    <th style="text-align: center;" rowspan="3">S.No</th>
                    <th style="text-align: center;" rowspan="3">District</th>
                    <th style="text-align: center;" colspan="2">Allocation</th>
                    <th style="text-align: center;" colspan="2">Last 30 days expenditure</th>
                    <th style="text-align: center;" rowspan="3">% of <br> expenditure in <br> last 30 days
                    </th>

                </tr>
                <tr>
                    <th style="text-align: center;">No. of Bills</th>
                    <th style="text-align: center;">Amount (Rs. In crore)</th>
                    <th style="text-align: center;">No. of Bills</th>
                    <th style="text-align: center;">Amount (Rs. In crore)</th>
                </tr>

            </ng-template>
            <ng-template pTemplate="body" let-list let-index="rowIndex">
                <tr>
                    <td style="text-align: center;">{{index+1}}</td>
                    <td style="text-align: center;">{{list?.name}}</td>
                    <td style="text-align: center;">{{list?.aoCount}}</td>
                    <td style="text-align: center;">{{formatCash(list?.aoAmount)}}</td>
                    <td style="text-align: center;">{{list?.pdCount}}</td>
                    <td style="text-align: center;">{{formatCash(list?.pdAmount)}}</td>
                    <td style="text-align: center;">{{list?.collectorAmount}}%</td>

                </tr>
            </ng-template>
            <ng-template pTemplate="footer">
                <tr>
                    <td colspan="2" style="text-align: center;">Total</td>
                    <td style="text-align: center;">{{lastThirtyDaysExpenditureDetails?.totalData?.aoCount}}</td>
                    <td style="text-align: center;">{{formatCash(lastThirtyDaysExpenditureDetails?.totalData?.aoAmount)}}</td>
                    <td style="text-align: center;">{{lastThirtyDaysExpenditureDetails?.totalData?.pdCount}}</td>
                    <td style="text-align: center;">{{formatCash(lastThirtyDaysExpenditureDetails?.totalData.pdAmount)}}</td>
                    <td style="text-align: center;">{{lastThirtyDaysExpenditureDetails?.totalData?.collectorAmount}}%</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-dialog>
<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass" jhiTranslate="nav.user.list">User List</h5>
                </ng-template>
                <ng-template pTemplate="right">
                    <button *ngIf="isStateRole" pButton pRipple icon="pi pi-plus" class="p-button-secondary custom-button  new-button" (click)="openNew()" styleClass="button">
                        <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.newbutton"></span>
                    </button>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="row">
        <div #customerTable id="entities" class="col-12 px-4">
            <custom-filter-table [fileName]="'Users'" [columnsValues]="cols" [filterSearchResult]="searchResult" (searchParam)="onLazyLoad($event)" [disableDeleteIcon]="true" [showProfileButton]="false" [disableEditIcon]="true" [enableActivateButton]="isStateRole"
                [enableDeactivateButton]="isStateRole" [enableuserUnlock]="isStateRole" (emitRow)="onSelectRow($event)" [enableViewIcon]="true"></custom-filter-table>

        </div>
    </div>
    <p-dialog [(visible)]="userDialog" [style]="{width: '800px',height:'500px'}" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog" (onHide)="hideDialog()">
        <p-header [ngClass]="fontService.headingClass">
            {{"agency.zonal.add"|translate}}
        </p-header>
        <ng-template pTemplate="content">
            <div class="col-12">
                <div class="form-inner-panel">
                    <div class="row gy-2">
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="userName" [ngClass]="fontService.labelClass" jhiTranslate="userList.name">User Name</label>
                            <input type="text" class="form-control" maxlength="60" autocomplete="off" oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();" style="text-transform:uppercase"
                                name="userName" id="userName" [(ngModel)]="user.userName" [readonly]="user.id" required autofocus [disabled]="viewOnly" [ngClass]="{ 'is-invalid': ngSelectInvalid && !user.userName }" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" for="mobileNo" [ngClass]="fontService.labelClass" jhiTranslate="userList.mobile.number">Mobile No</label>
                            <input type="text" class="form-control" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" maxlength="10" autocomplete="off" name="mobileNo" id="mobileNo" [disabled]="viewOnly" [(ngModel)]="user.mobile" (input)="validateMobile()"
                                [ngClass]="{ 'is-invalid': showMobileError}" />
                            <div *ngIf="showMobileError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.validmobile">Please enter
                                        a valid Mobile Number.</small>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" for="email" [ngClass]="fontService.labelClass" jhiTranslate="userList.email">E-mail</label>
                            <input type="text" class="form-control" [(ngModel)]="user.email" (input)="validateEmail()" autocomplete="off" maxlength="60" name="email" id="email" [ngClass]="{ 'is-invalid': emailError}" [disabled]="viewOnly" />
                            <div *ngIf="emailError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.validemail">Please enter a
                                        valid Email Address.</small>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="roleName" [ngClass]="fontService.labelClass" jhiTranslate="userList.role">Role</label>
                            <ng-select [appendTo]="'.p-dialog'" id="department" [(ngModel)]="user.roleDto" [items]="role" bindLabel="name" appearance="outline" (change)="changeRole()" [closeOnSelect]="true" [disabled]="viewOnly" class="form-control" [clearable]="true" [ngClass]="{ 'is-invalid': ngSelectInvalid && !user.roleDto }">
                            </ng-select>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="departCode" [ngClass]="fontService.labelClass" jhiTranslate="userList.levelType.name">Level Type</label>
                            <ng-select [appendTo]="'.p-dialog'" id="scheme" [(ngModel)]="levelTypeDto" [items]="levelType" bindLabel="name" appearance="outline" (change)="onLevelType()" [disabled]="true" [closeOnSelect]="true" [clearable]="true" class="form-control" [ngClass]="{ 'is-invalid': ngSelectInvalid && !levelTypeDto }">
                            </ng-select>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="userList.state.name">State</label>
                            <ng-select [appendTo]="'.p-dialog'" id="scheme" [(ngModel)]="stateListDto" [items]="stateList" bindLabel="name" appearance="outline" (change)="onStateLevelChange()" [disabled]="viewOnly" [closeOnSelect]="true" [clearable]="true" class="form-control" [ngClass]="{ 'is-invalid': ngSelectInvalid && !stateListDto }">
                            </ng-select>
                        </div>
                        <!-- <div class="col-sm-6 col-md-4 d-flex flex-column">
                            <label class="form-control-label" for="department">Department</label>
                            <ng-select [appendTo]="'.p-dialog'" id="department" [(ngModel)]="user.departmentDto" [items]="departmentList"
                                bindLabel="name" appearance="outline" [closeOnSelect]="true" class="form-control"
                                [clearable]="true">
                            </ng-select>
                        </div>
                        <div class="col-sm-6 col-md-4 d-flex flex-column">
                            <label class="form-control-label" for="scheme">Scheme</label>
                            <ng-select [appendTo]="'.p-dialog'" id="scheme" [(ngModel)]="user.schemeDto" [items]="schemeList" bindLabel="name"
                                appearance="outline" [closeOnSelect]="true" [clearable]="true" class="form-control">
                            </ng-select>
                        </div> -->
                        <div class="col-sm-6 col-md-4" *ngIf="levelTypeDto?.name === 'District' || levelTypeDto?.name === 'Block' || levelTypeDto?.name === 'Village'">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="userList.district.name">District</label>
                            <ng-select [appendTo]="'.p-dialog'" id="scheme" [(ngModel)]="districtListDto" [items]="this.districtLevelList" bindLabel="name" appearance="outline" (change)="onDistrictLevelChange()" (search)="onStateLevelChange($event)" [closeOnSelect]="true" [disabled]="viewOnly"
                                [clearable]="true" class="form-control" [ngClass]="{ 'is-invalid': ngSelectInvalid && !districtListDto }">
                            </ng-select>
                        </div>
                        <div class="col-sm-6 col-md-4" *ngIf="levelTypeDto?.name === 'Block' || levelTypeDto?.name === 'Village'">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="userList.block.name">Block</label>
                            <ng-select [appendTo]="'.p-dialog'" id="scheme" [(ngModel)]="BlockLevelDto" [items]="blockLevelList" bindLabel="name" appearance="outline" (change)="onBlockLevelChange()" [disabled]="viewOnly" (search)="onDistrictLevelChange($event)" [closeOnSelect]="true"
                                [clearable]="true" class="form-control" [ngClass]="{ 'is-invalid': ngSelectInvalid && !BlockLevelDto }">
                            </ng-select>
                        </div>
                        <div *ngIf="levelTypeDto?.name === 'Village'" class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="userList.village.name">Village</label>
                            <ng-select [appendTo]="'.p-dialog'" id="scheme" [items]="villageLevelList" bindLabel="name" appearance="outline" (search)="onBlockLevelChange($event)" (change)="onVillageLevelChange()" [(ngModel)]="villageLevelDto" [closeOnSelect]="true" [clearable]="true"
                                class="form-control" [ngClass]="{ 'is-invalid': ngSelectInvalid && !villageLevelDto }" [disabled]="viewOnly">
                            </ng-select>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <label class="form-control-label req" for="schemeDescription" [ngClass]="fontService.labelClass" jhiTranslate="userList.select.option" [ngClass]="{ 'is-invalid': ngSelectInvalid && !selectOption }">
                                    Select Option</label><br>
                            <p-radioButton name="option" [disabled]="user.id || viewOnly" inputId="option1" value="Zonal" [(ngModel)]="selectOption"></p-radioButton>
                            <label for="option1" [ngClass]="fontService.labelClass" jhiTranslate="userList.zone.name">Zonal</label> &nbsp;&nbsp;
                            <p-radioButton [disabled]="user.id || viewOnly" name="option" inputId="option2" value="ia" [(ngModel)]="selectOption">
                            </p-radioButton>
                            <label for="option2" [ngClass]="fontService.labelClass" jhiTranslate="userList.implementing.agency.name">Zonal</label>
                        </div>
                        <div *ngIf="selectOption === 'Zonal'" class="col-sm-6 col-md-4 d-flex flex-column">
                            <label class="form-control-label req" for="levelType" [ngClass]="fontService.labelClass" jhiTranslate="userList.zonal.name">Zonal Name</label>
                            <ng-select [appendTo]="'.p-dialog'" id="scheme" [items]="zonalList" bindLabel="name" [(ngModel)]="zonalDto" appearance="outline" [closeOnSelect]="true" [clearable]="true" class="form-control" [ngClass]="{ 'is-invalid': ngSelectInvalid && !zonalDto }"
                                [disabled]="viewOnly">
                            </ng-select>
                        </div>
                        <div *ngIf="selectOption === 'ia'" class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="userList.implementing.agency.name">Implementing Agency</label>
                            <ng-select [appendTo]="'.p-dialog'" id="scheme" [items]="iaLIst" [(ngModel)]="iaDto" [disabled]="viewOnly" bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true" class="form-control" [ngClass]="{ 'is-invalid': ngSelectInvalid && !iaDto }">
                            </ng-select>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="password" [ngClass]="fontService.labelClass" jhiTranslate="userList.password">Password</label>
                            <input type="password" class="form-control" maxlength="20" autocomplete="off" [disabled]="viewOnly" name="password" id="password" [readonly]="user.id" [(ngModel)]="user.password" [ngClass]="{ 'is-invalid': ngSelectInvalid && !user.password }" />
                        </div>
                    </div>
                    <!-- <div class="row">
                           
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" for="password" [ngClass]="fontService.labelClass"
                                jhiTranslate="userList.password">Password</label>
                                <input type="password" class="form-control" maxlength="20" autocomplete="off"
                                    name="password" id="password" [readonly]="user.id" [(ngModel)]="user.password" 
                                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !user.password }"/>
                            </div>
                        </div> -->
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <button pButton pRipple icon="pi pi-times" class="p-button-text custom-button" (click)="hideDialog()"><span
                class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.cancelbutton">
            </span></button>
            <button *ngIf="!viewOnly" pButton pRipple icon="pi pi-check" class="p-button-text custom-button" (click)="saveUser()">
                <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.newbutton">
                </span>
            </button>
        </ng-template>
    </p-dialog>
    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
    <p-dialog [(visible)]="userPasswordDialog" [style]="{ width: '500px', height: '150px' }" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog">
        <p-header [ngClass]="fontService.headingClass">
            Reset Password is:
        </p-header>
        <div class="form-group mb-4 eye-container">
            <input id="password" name="password" autocomplete="off" id="password" class="form-control full" [(ngModel)]="userResetPassword" [type]="hide ? 'password' : 'text'" readonly>
            <i class="pi pi-eye eye" *ngIf="hide" (click)="hide=false"></i>
            <i class="pi pi-eye-slash eye" *ngIf="!hide" (click)="hide=true"></i>
        </div>
    </p-dialog>
</div>
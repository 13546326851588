<div class="bg right-align-container">
    <div class="right-container">
        <img class="tn-logo" src="/./assets/img/ib-rdp-tng-logo.svg" alt="Image">
        <div class="heading text-center">
            <h1 class="text-center size">
                Department of Rural Development and <br /> Panchayat Raj
            </h1>
        </div>
        <div class="col-7 mx-auto login-form ">
            <div class="font-login mb-2" style="color:#455a64">Reset Password</div>
            <div class="login-content">
                <form class="form" role="form" (ngSubmit)="submit()" [formGroup]="resetPwdForm">
                    <div class="form-group mb-4 eye-container">
                        <input id="password" name="password" autocomplete="off" formControlName="password" pattern="^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=])(?=\S+$).{8,16}$" [type]="phide ? 'password' : 'text'" required class="form-control full" placeholder="NEW PASSWORD">
                        <i class="pi pi-eye" *ngIf="phide" (click)="phide=false"></i>
                        <i class="pi pi-eye-slash" *ngIf="!phide" (click)="phide=true"></i>
                    </div>
                    <div class="form-group mb-4 eye-container">
                        <input id="confirmPassword" name="confirmPassword" autocomplete="off" formControlName="confirmPassword" class="form-control full" pattern="^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=])(?=\S+$).{8,16}$" required placeholder="CONFIRM NEW PASSWORD"
                            [type]="cphide ? 'password' : 'text'">
                        <i class="pi pi-eye" *ngIf="cphide" (click)="cphide=false"></i>
                        <i class="pi pi-eye-slash" *ngIf="!cphide" (click)="cphide=true"></i>
                    </div>
                    <div *ngIf="pwdMismatch" class="text-danger mb-2">
                        <small>New Password and Confirm New Password should be same. Please re-enter</small>
                    </div>
                    <div *ngIf="!resetPwdForm.valid && this.resetPwdForm.get('password')!.value" class="text-danger mb-2">
                        <small>Passwords must contain at least one uppercase,one lowercase, one special characters, one Number ,minimum 8 characters and maximun 16 characters</small>
                    </div>
                    <div class="mb-4">
                        <button type="submit" [disabled]="!resetPwdForm.valid" class="btn btn-color">Submit</button>
                    </div>
                    <div class="footer-logo">
                        <button type="button" (click)="navToLogin()" class="btn btn-link btn-sm">Back to Login</button>
                    </div>
                </form>
            </div>
        </div>
        <div class="row mt-2 align-items-center">
            <div class="col-7">
                <img class="mgsmt-logo" src="/./assets/img/mgsmt-logo.jpg" alt="Image">
            </div>
            <div class="col-5">
                <img class="ib-logo" src="/./assets/img/indian-bank-logo 1.png" alt="Image">
            </div>
        </div>
    </div>
</div>
import { filterQuery } from './../../shared/common.model';
import {
  ComponentDto,
  ComponentType,
} from './../../module/component-type/component-type';
import { LevelType } from './../../module/level-type/level-type';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SchemeList } from 'src/app/module/scheme-list/scheme-list';
import { SchemeListService } from 'src/app/module/scheme-list/scheme-list.service';
import { LevelTypeService } from 'src/app/module/level-type/level-type.service';
import { ComponentTypeService } from 'src/app/module/component-type/component-type.service';
import { CommercialVendorService } from 'src/app/module/commercial-vendors/commercial-vendors.service';
import {
  CommercialVendorsDto,
  Vendor,
} from 'src/app/module/commercial-vendors/commercial-vendors';
import {
  paymentVoucherGen,
  VoucherBillDescDto,
  vendorTransactionDto,
  VendorTransaction,
  ComponentMasterDto,
} from '../payment-voucher/payment-voucher.model';
import { PaymentService } from '../payment-voucher/payment-voucher.service';
import { DepartmentListService } from 'src/app/module/department-list/department-list.service';
import { DepartmentList } from 'src/app/module/department-list/department-list';
import { NgxSpinnerService } from 'ngx-spinner';
import { SearchResult } from 'src/app/shared/models/search-result';

import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { Dialog } from 'primeng/dialog';
import { NotificationService } from 'src/app/alert/notification.service';
import { NavbarComponent } from 'src/app/layout/navbar/navbar.component';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { UserService } from 'src/app/module/user/user.service';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { FinYear } from 'src/app/module/limit-translist/limit-translist.module';
import { LimitTransListService } from 'src/app/module/limit-translist/limit-translist.service';
import { ProjectService } from 'src/app/project/project.service';
import { Project } from 'src/app/project/project.model';
import { environment } from 'src/environments/environment';
import { userRoles } from 'src/app/shared/models/user-role-code';
import { UserRoleService } from 'src/app/shared/service/user-role.service';

@Component({
  selector: 'app-migrate-vendor-payment-voucher',
  templateUrl: './migrate-vendor-payment-voucher.component.html',
  styleUrls: ['./migrate-vendor-payment-voucher.component.scss']
})
export class MigrateVendorPaymentVoucherComponent implements OnInit {

  @ViewChild('dialog') dialog: Dialog;
  @ViewChild('formData') formData;
  navbarComponent!: NavbarComponent;
  enableStateGst: boolean;
  enableIGst: boolean;
  departmentList: DepartmentList[];
  pdfString: any;
  schemeList: SchemeList[];
  selectedVendor: CommercialVendorsDto;
  levelType: LevelType[];
  grantLevel: ComponentType;
  grantlevel: any;
  levelMasterDto: LevelType;
  voucherGenerationDto: paymentVoucherGen;
  componentMaster: ComponentMasterDto;
  componentMasterList: any;
  date: Date;
  voucherNo: string;
  vendorList: Vendor[];
  projectList:Project[];
  selectedProject:any;
  selectedscheme: any;
  profileDialog: boolean = false;
  searchResult: SearchResult<VendorTransaction> =
    new SearchResult<VendorTransaction>();
  totalDeduction: number;
  dropDownLabel: any;
  maxDate = new Date();
  amount: number;
  description: string;
  signButtonName: any;
  noRecord: boolean;
  isFileUploaded:boolean;
  uFilename:string;
  viewDoc:boolean=false;
  vFileExt:string;
  vFileUrl:string;
  finYear: FinYear[];
  currentPage: number = 0;
  loadingMore: boolean = false;
  userRoles=userRoles;
  cols = [
    {
      field: 'voucherNo',
      header: 'Voucher No',
      isFilterable: false,
      isSelectcolumn: true,
    },
    {
      field: 'commercialVendorName',
      header: 'Vendor Name',
      isFilterable: false,
      isSelectcolumn: true,
    },
    {
      field: 'commercialAccountNo',
      header: 'Vendor Account',
      isFilterable: false,
      isSelectcolumn: true,
    },
    {
      field: 'transDate',
      header: 'Transaction Date',
      isSortable: true,
      isSelectcolumn: true,
      type: 'date',
    },
    {
      field: 'grossAmount',
      header: 'Rate',
      jhiTranslate: 'flowtechApp.customers.industryType',
      isSelectcolumn: true,
      isFilterable: false,
    },
    {
      field: 'transactionAmount',
      header: 'Total Transction Amount',
      jhiTranslate: 'flowtechApp.customers.industryType',
      isSelectcolumn: true,
      isFilterable: false,
    },
  ];

  // validation fields properties
  public ifscError = false;
  public panError = false;

  //required fields property
  ngSelectInvalid: boolean = false;
  filterProps: any;
  securityDeposit: number;
  enableGstOnTds: boolean;
  uploadedFiles: any;
  enableLabourChess: boolean;
  enableSecurityDeposit: boolean;
  roleCode: any;
  readonly: boolean;
  enableRevertButton: boolean;
  param1: any;
  param2: any;
  param3: any;
  enableSignButton: boolean;
  downloadButton: boolean;
  paymentSucessDialog: boolean;
  paymentSucessText: any;
  enablesign: boolean = false;
  enableRePaymentButton: boolean;
  disablepaymentButton: boolean;
  buttonText: string;
  checkstatusButton: boolean;
  interactionId: any;
  checkAmount: boolean;
  zonalList: LevelType;
  selectedZonalUser: any;
  enableZonalButton: boolean;
  enableApproved: boolean;
  finy: any;
  limitAmount: number;
  projectLimitAmount: number;
  isEditable: boolean = true;
  finYearDto: FinYear;
  gstOnTdsAmount: number;
  filterTimeout: any;

  constructor(
    protected activatedRoute: ActivatedRoute,
    private schemeListService: SchemeListService,
    private levelTypeService: LevelTypeService,
    private componentTypeService: ComponentTypeService,
    private commercialVendorService: CommercialVendorService,
    private paymentService: PaymentService,
    private router: Router,
    private departmentListService: DepartmentListService,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,
    private loginService: SigninService,
    private userService: UserService,
    private http: HttpClient,
    private sessionStorageService: SessionStorageService,
    private localStorageService: LocalStorageService,
    private limitTransListService:LimitTransListService,
    private projectService:ProjectService,
    private userRoleService:UserRoleService
  ) {
    if (this.formData) {
      this.formData.valueChanges.subscribe((event) => {});
    }
    this.translateService.onLangChange.subscribe((event) => {
      const lang = this.translateService.currentLang;
      this.dropDownLabel = lang === 'en' ? 'name' : 'nameTn';
    });
  }

  onchangeFinYear(){
    this.limitTransListService.getAmountAndLBC({
      levelMasterId: this.roleCode.levelMaster.id,
      schemeId: this.voucherGenerationDto.vendorTransactionDto.implementingAgencyDto.schemeDto.id,
      finYearId: this.voucherGenerationDto.vendorTransactionDto.finYearDto.id,
    })
    .subscribe(
      (res: HttpResponse<any>) => {
        this.voucherGenerationDto.vendorTransactionDto.iaComponentLimitDto =res.body;
        this.limitAmount= this.voucherGenerationDto.vendorTransactionDto.iaComponentLimitDto.adminFundAuthorizationAmount-
        this.voucherGenerationDto.vendorTransactionDto.iaComponentLimitDto.adminUtilizedAmount;
      },
      () => {}
    );
  }

  async ngOnInit(): Promise<void> {
    await this.getRole();

        this.paymentService
          .getByImplemeting(this.roleCode.implementingAgencyId)
          .subscribe(
            (res: HttpResponse<any>) => {
              this.voucherGenerationDto.vendorTransactionDto.implementingAgencyDto =
                res.body;
                this.limitTransListService.getFinYear().subscribe(
                  (res: HttpResponse<FinYear[]>) => {
                    this.finYear = res.body;
                  },
                  () => {}
                );
              
              this.levelTypeService
                .getbyId(
                  this.voucherGenerationDto.vendorTransactionDto
                    .implementingAgencyDto.leveltype
                )
                .subscribe(
                  (res: HttpResponse<LevelType>) => {
                    this.levelMasterDto = res.body;
                  },
                  () => {}
                );
            },
            () => {}
          );
        if (
          this.roleCode.role.code === userRoles.roleMaker ||
          this.roleCode.role.code === userRoles.roleStateMaker ||
          this.roleCode.role.code === 'VMAKER' ||
          this.roleCode.role.code === 'BMAKER'
        ) {
          if (this.roleCode.role.code === 'BMAKER') {
            this.paymentService
              .getZonalCheckers(this.roleCode.levelMaster.id)
              .subscribe(
                (res: HttpResponse<LevelType>) => {
                  this.zonalList = res.body;
                },
                () => {}
              );
          }
          this.signButtonName = 'Perform e-Sign Administrative Officer';
        } else if (
          
          this.roleCode.role.code === userRoles.roleChecker ||
          this.roleCode.role.code === userRoles.roleStateChecker ||
          this.roleCode.role.code === 'BC1' ||
          this.roleCode.role.code === 'VC1' ||
          this.roleCode.role.code === 'ZC1'
        ) {
          this.enableApproved=true;
          if (this.roleCode.role.code === 'BC1' || this.roleCode.role.code === 'ZC1') {
            this.checkAmount = true;
          }
          this.signButtonName = 'Perform e-Sign Project Director';
          this.enableRevertButton = true;
          this.readonly = true;
        } else if (
          this.roleCode.role.code === userRoles.roleApprover ||
          this.roleCode.role.code === userRoles.roleStateApprover ||
          this.roleCode.role.code === 'BC2' ||
          this.roleCode.role.code === 'VC2'
        ) {
          this.enableApproved=true;
          this.signButtonName = 'Perform e-Sign District Collector';
          this.enableRevertButton = true;
          this.downloadButton = true;
          this.readonly = true;
        }
        if (
          this.roleCode.role.code === 'BMAKER' ||
          this.roleCode.role.code === 'BC1' ||
          this.roleCode.role.code === 'BC2' ||
          this.roleCode.role.code === 'ZC1'
        ) {
          this.enableZonalButton = true;
        }
     
  
    if (this.activatedRoute.snapshot.queryParamMap.get('error')){
      this.notificationService.alertError('Error Occurred: '+this.activatedRoute.snapshot.queryParamMap.get('error'),'');
    }
    if (this.activatedRoute.snapshot.queryParamMap.get('id')) {
      this.spinner.show();
      this.paymentService
        .updatedVocher(
          Number((this.activatedRoute.snapshot.queryParamMap.get('id')))
        )
        .subscribe(
          (res: HttpResponse<paymentVoucherGen>) => {
            this.voucherGenerationDto = res.body;
            this.isEditable=this.voucherGenerationDto.vendorTransactionDto.isEditable=="True"?true:false;
            if(this.voucherGenerationDto.vendorTransactionDto.invoiceImage)
            {
              this.uFilename=this.voucherGenerationDto.vendorTransactionDto.invoiceImage;
              this.isFileUploaded=true;
            }
            this.limitAmount= this.voucherGenerationDto.vendorTransactionDto.iaComponentLimitDto.adminFundAuthorizationAmount-
                      this.voucherGenerationDto.vendorTransactionDto.iaComponentLimitDto.adminUtilizedAmount;
            this.spinner.hide();
            if (
              this.checkAmount &&
              res.body.vendorTransactionDto.transactionAmount <= 500000 &&
              res.body.vendorTransactionDto.signatureVicePresident
            ) {
              res.body.vendorTransactionDto.signaturePresident = 'signed';
            }
            if (res.body?.vendorTransactionDto?.zonalChecker) {
              this.userService
              .getUser(res.body?.vendorTransactionDto?.zonalChecker)
              .subscribe(
                (res: HttpResponse<any>) => {
                  this.selectedZonalUser = res.body;
                },
                () => {}
              );
            }
            
            if (this.voucherGenerationDto.vendorTransactionDto)
              if (this.voucherGenerationDto.vendorTransactionDto.signStatus) {
                this.readonly = true;
              }
            this.date = new Date(
              this.voucherGenerationDto.vendorTransactionDto.transDate
            );
            this.voucherGenerationDto.vendorTransactionDto.paymentDate=
            new Date(this.voucherGenerationDto.vendorTransactionDto.paymentDate);
            this.securityDeposit =
              this.voucherGenerationDto.vendorTransactionDto.securityDepositAmount;
            if (this.voucherGenerationDto.vendorTransactionDto.gstOnTdsAmount) {
              this.enableGstOnTds = true;
            } else {
              this.enableGstOnTds = false;
            }
            if (
              this.voucherGenerationDto.vendorTransactionDto
                .securityDepositAmount
            ) {
              this.enableSecurityDeposit = true;
            } else {
              this.enableSecurityDeposit = false;
            }
            if (this.voucherGenerationDto.vendorTransactionDto.labourCess) {
              this.enableLabourChess = true;
            } else {
              this.enableLabourChess = false;
            }
            this.totalDeduction =
              this.voucherGenerationDto.vendorTransactionDto.totlaInRupees;
            this.selectedVendor =
              this.voucherGenerationDto.vendorTransactionDto.commercialVendorDto;
              if (
                this.voucherGenerationDto.vendorTransactionDto.commercialVendorDto.gstNo
              ) {
              if (this.selectedVendor?.gstNo?.slice(0, 2) === '33') {
              this.enableStateGst = true;
              this.enableIGst = false;
            } else {
              this.enableIGst = true;
              this.enableStateGst = false;
            }
          }
            if (this.voucherGenerationDto.vendorTransactionDto.tdsAmount) {
              this.voucherGenerationDto.vendorTransactionDto.tdsApplicable =
                true;
            } else {
              this.voucherGenerationDto.vendorTransactionDto.tdsApplicable =
                false;
            }
            if (this.voucherGenerationDto.vendorTransactionDto.invoiceDate) {
              this.voucherGenerationDto.vendorTransactionDto.invoiceDate =
                new Date(
                  this.voucherGenerationDto.vendorTransactionDto.invoiceDate
                );
            }
            this.grantlevel =
              this.voucherGenerationDto.vendorTransactionDto.componentTypeDto;
              this.componentMaster=this.voucherGenerationDto.vendorTransactionDto.componentMaster;
            this.levelMasterDto =
              this.voucherGenerationDto.vendorTransactionDto.iaComponentLimitDto.levelTypeDto;
            this.levelTypeService
              .getbyId(
                this.voucherGenerationDto.vendorTransactionDto
                  .implementingAgencyDto.leveltype
              )
              .subscribe(
                (res: HttpResponse<LevelType>) => {
                  this.levelMasterDto = res.body;
                },
                () => {}
              );
              this.componentTypeService.filter().subscribe(
                (res: HttpResponse<any>) => {
                  this.grantLevel = res.body.filter(item=>item.name==='Admin Fund');
                  this.grantlevel=this.grantLevel[0];
                  this.ongrantlevel();
                    },
                    () => {}
                  );

            
           
              this.levelTypeService
              .getbyId(
                this.voucherGenerationDto.vendorTransactionDto
                  .implementingAgencyDto.leveltype
              )
              .subscribe(
                (res: HttpResponse<LevelType>) => {
                  this.levelMasterDto = res.body;
                  
                  this.onLevelType();
                },
                () => {}
                
              );
          },
          () => {}
        );
      if (
        this.roleCode.role.code === userRoles.roleApprover ||
        this.roleCode.role.code === userRoles.roleStateApprover ||
        this.roleCode.role.code === 'BC2' ||
        this.roleCode.role.code === 'VC2'
      ) {
        if (
          this.voucherGenerationDto.vendorTransactionDto.signaturePresident !==
          null
        ) {
          this.enablesign = true;
        }
      }
    }
    if (!this.activatedRoute.snapshot.queryParamMap.get('id')) {
      this.voucherGenerationDto = new paymentVoucherGen();
      this.voucherGenerationDto.vendorTransactionDto =
        new vendorTransactionDto();
      this.voucherGenerationDto.vendorTransactionDto.commercialVendorDto =
        new CommercialVendorsDto();
      this.enableGstOnTds = false;
      this.enableLabourChess = false;
      this.enableSecurityDeposit = false;
      this.selectedVendor = new CommercialVendorsDto();
      this.voucherGenerationDto.vendorTransactionDto =
        new vendorTransactionDto();
      this.voucherGenerationDto.vendorTransactionDto.tdsApplicable = false;
      this.voucherGenerationDto.vendorTransactionDto.componentTypeDto =
        new ComponentDto();
      this.voucherGenerationDto.vendorTransactionDto.voucherNo =
        this.activatedRoute.snapshot.queryParamMap.get('VoucherNo');
      this.date = new Date();
    }
    this.componentTypeService.filter().subscribe(
      (res: HttpResponse<any>) => {
        this.grantLevel = res.body.filter(item=>item.name==='Admin Fund');
        this.grantlevel=this.grantLevel[0];
        this.ongrantlevel();
          },
          () => {}
        );

    this.departmentListService.filter().subscribe(
      (res: HttpResponse<DepartmentList[]>) => {
        this.departmentList = res.body;
      },
      () => {}
    );
    this.schemeListService.filter().subscribe(
      (res: HttpResponse<SchemeList[]>) => {
        this.schemeList = res.body;
      },
      () => {}
    );
    
      
  }

  ngAfterViewInit() {
    this.updateDialogFont();
    if (this.formData) {
      this.formData.valueChanges.subscribe((event) => {});
    }
  }

  getRole(): Promise<any> {
    return new Promise<any>((resolve) => {
      this.loginService.getuserDto().subscribe(
        (res)=>{
          this.roleCode = res.body;
          resolve(this.roleCode)
        },
        (onError) => {
          resolve(null);
        }
      );
    });
  }
  

  ongrantlevel(){
    this.componentMaster=null;
    if(this.voucherGenerationDto.vendorTransactionDto.componentMaster){
      this.componentMaster=this.voucherGenerationDto.vendorTransactionDto.componentMaster;
      }
    this.componentTypeService.findbyMasterId(this.grantlevel.id).subscribe(
      (res: HttpResponse<ComponentType>) => {
        this.componentMasterList = res.body;
      },
      () => {}
    );
  }
  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    this.dropDownLabel = lang === 'en' ? 'name' : 'nameTn';
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }

  onchangeSchema() {
    this.levelTypeService.filter().subscribe(
      (res: HttpResponse<LevelType[]>) => {
        this.levelType = res.body;
      },
      () => {}
    );
  }

  onLevelType() {
    this.componentTypeService.filter().subscribe(
      (res: HttpResponse<any>) => {
        this.grantLevel = res.body.filter(item=>item.name==='Admin Fund');
        this.grantlevel=this.grantLevel[0];
        this.ongrantlevel();
          },
          () => {}
        );
  }
  viewDocument(){
    this.paymentService.viewimage(this.voucherGenerationDto.vendorTransactionDto.invoiceImage)
    .subscribe((res)=>
    {
      const filename = res.body.fileName; 
      this.vFileExt  = filename.split('.').pop();
      this.vFileUrl = "data:image/"+this.vFileExt+";base64,"+res.body.fileType; // Replace with your Base64-encoded image string
      this.viewDoc=true;
  });
}
  downloadImage(base64Data: string, filename: string) {
    const link = document.createElement('a');
    link.href = base64Data;
    link.download = filename;
    link.click();
  }
  downloadDocument(){
  this.paymentService.getimage(this.voucherGenerationDto.vendorTransactionDto.invoiceImage)
  .subscribe((res)=>
  {
    const filename = res.body.fileName; 
    const fileExtension = filename.split('.').pop();

    if(fileExtension=='pdf')
    {
    const base64Data = "data:application/pdf;base64,"+res.body.fileType; // Replace with your Base64-encoded image string
    this.downloadImage(base64Data, filename);
  }
    else{
    const base64Data = "data:image/"+fileExtension+";base64,"+res.body.fileType; // Replace with your Base64-encoded image string
    this.downloadImage(base64Data, filename);
    }
  },
  (onError)=>
  {

  })
  }
  removeDocument(){
    this.paymentService.deleteimage(this.voucherGenerationDto.vendorTransactionDto.invoiceImage)
    .subscribe((res)=>{
    this.notificationService.alertSuccess("File Deleted",'');
    this.isFileUploaded=false;
    this.voucherGenerationDto.vendorTransactionDto.invoiceImage=null;
    },
    (error)=>{
    this.notificationService.alertError("Error occured, try again",'');
    });
  }

  onUpload($event) {
    const formData = new FormData();
    const i=$event.files.length-1;
    const file = $event.files[i];
    const acceptedFormats = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];

    if (file && !acceptedFormats.includes(file.type)) {
      this.notificationService.alertError('Invalid file format. Please upload a valid file.','');
      return;
    }
    const fileData = new Blob([$event.files[i]], {
      type: $event.files[i].type,
    });
    if ($event.files[i].type === 'application/pdf') {
      formData.append('file', fileData, `.pdf`);
    } else if ($event.files[i].type === 'image/png') {
      formData.append('file', fileData, `.png`);
    } else if ($event.files[i].type === 'image/jpeg') {
      formData.append('file', fileData, `.jpeg`);
    } else {
      formData.append('file', fileData, `.jpg`);
    }

    this.paymentService.uploadImage(formData).subscribe(
      (res: HttpResponse<any>) => {
        this.voucherGenerationDto.vendorTransactionDto.invoiceImage =
          res.body.fileName;
          this.uFilename=this.voucherGenerationDto.vendorTransactionDto.invoiceImage;
          this.isFileUploaded=true;
        this.notificationService.alertSuccess('File Uploaded Successfully', '');
      },
      () => {
        this.notificationService.alertError('Error, please make sure file size is within 5MB and in supported format','');
      }
    );

    // Call the backend endpoint to upload the file
    // Use HttpClient to make an HTTP POST request
  }
  

  getActionUrl(){
    this.localStorageService.store('refresh', 'Yes');
    return environment.CDAC_URL;

  }

  vendorFilter($event?) {
    if (this.filterTimeout) {
      clearTimeout(this.filterTimeout);
    }
    if ($event) {
      if($event.term){
        this.filterTimeout = setTimeout(() => {
      let filterquery = [];
      let filter = new filterQuery();
      filter.key = 'name';
      filter.operation = 'contains';
      filter.value = $event.term.toUpperCase();
      filterquery.push(filter);
      filterquery.push({
        key: 'activeFlag',
        operation: 'equals',
        value: 'YES',
      });
      filterquery.push({
        key: 'approve',
        operation: 'equals',
        value: 'YES',
      });
      this.commercialVendorService
        .filter({ size: 10, page: 0 }, filterquery)
        .subscribe(
          (res: HttpResponse<any>) => {
            this.vendorList = res.body;
          },
          () => {}
        );
      }, 2000);
    }
    } else {
      let filter = [];
    filter.push({
      key: 'activeFlag',
      operation: 'equals',
      value: 'YES',
    });
    filter.push({
      key: 'approve',
      operation: 'equals',
      value: 'YES',
    });
      this.commercialVendorService.filter({ size: 10, page: 0 }, filter).subscribe(
        (res: HttpResponse<any>) => {
          this.vendorList = res.body;
        },
        () => {}
      );
    }
  }

  loadMoreItems(page: number) {
    const filter = [
      {
        key: 'activeFlag',
        operation: 'equals',
        value: 'YES',
      },
      {
        key: 'approve',
        operation: 'equals',
        value: 'YES',
      },
    ];
  
    const paginationOptions = { size: 10, page };
  
    return this.commercialVendorService.filter(paginationOptions, filter);
  }
  
    onScrollToEnd(){
      if (!this.loadingMore) {
        this.loadingMore = true;
        this.currentPage++;
  
        this.loadMoreItems(this.currentPage).subscribe(
          (res: HttpResponse<any>) => {
            const additionalItems = res.body;
  
            this.vendorList = this.vendorList.concat(additionalItems);
  
            this.loadingMore = false;
          },
          () => {
            this.loadingMore = false;
          }
        );
      }
    }

  popupValue() {
    this.voucherGenerationDto.vendorTransactionDto.gstOnTdsAmount = 0;
    this.voucherGenerationDto.voucherBillDescDtoList = [];
    let voucherList = new VoucherBillDescDto();
    voucherList.amount = 0;
    voucherList.gstPercentage = 0;
    voucherList.paymentType=null;
    this.voucherGenerationDto.voucherBillDescDtoList.push(voucherList);
    this.voucherGenerationDto.vendorTransactionDto.commercialVendorDto =
      new CommercialVendorsDto();
    this.voucherGenerationDto.vendorTransactionDto.commercialVendorDto.id =
      this.selectedVendor.id;
    this.voucherGenerationDto.vendorTransactionDto.commercialVendorDto.gstNo =
      this.selectedVendor.gstNo;

    this.voucherGenerationDto.vendorTransactionDto.commercialVendorDto.panNo =
      this.selectedVendor.panNo;
    if (this.selectedVendor.gstNo === null) {
      this.enableStateGst = false;
      this.enableIGst = false;
      this.enableGstOnTds = false;
    }
    if (
      this.voucherGenerationDto.vendorTransactionDto.commercialVendorDto.gstNo
    ) {
      if (this.selectedVendor.gstNo.slice(0, 2) === '33') {
        this.enableStateGst = true;
        this.enableIGst = false;
      } else {
        this.enableIGst = true;
        this.enableStateGst = false;
      }
      this.voucherGenerationDto.vendorTransactionDto.gstOnTds = 2;
    }
    this.getSercurityDesposit();
    this.calculateTotalAmount();
  }
  addRow() {
    this.voucherGenerationDto.voucherBillDescDtoList = this.voucherGenerationDto
      .voucherBillDescDtoList
      ? this.voucherGenerationDto.voucherBillDescDtoList
      : [];
    let voucherList = new VoucherBillDescDto();
    voucherList.amount = 0;
    voucherList.gstPercentage = 0;
    voucherList.paymentType=null;
    this.voucherGenerationDto.voucherBillDescDtoList.push(voucherList);
  }
  calculatetotal(gst: VoucherBillDescDto) {
    
    if (gst.amount === null) {
      gst.amount = 0;
    }
    this.amount = gst.amount;

    gst.grossAmount = gst.amount;
    let totalAmount = gst.amount;

    let totalvalue = Number(((gst.gstPercentage / 100) * totalAmount).toFixed());
    gst.gstAmount = totalvalue;
    if (this.enableStateGst) {
      gst.cgstPercentage = Number((gst.gstPercentage / 2).toFixed());
      gst.sgstPercentage = Number((gst.gstPercentage / 2).toFixed());
      gst.cgstAmount = Number((totalvalue / 2).toFixed());
      gst.sgstAmount = Number((totalvalue / 2).toFixed());
    } else {
      gst.igstPercentage = gst.gstPercentage;
      gst.igstAmount = totalvalue;
    }
    gst.grossAmount = totalAmount + totalvalue;

    this.calculateTotalAmount();
    this.calculateTds();
  }
  delete(index: number) {
    this.voucherGenerationDto.voucherBillDescDtoList.splice(index, 1);
    if (this.voucherGenerationDto.voucherBillDescDtoList.length === 0) {
      this.voucherGenerationDto.vendorTransactionDto.grossAmount = null;
      this.voucherGenerationDto.vendorTransactionDto.totalCgst = null;
      this.voucherGenerationDto.vendorTransactionDto.totalSgst = null;
      this.voucherGenerationDto.vendorTransactionDto.totalIgst = null;
      this.voucherGenerationDto.vendorTransactionDto.netAmount = null;
      this.voucherGenerationDto.vendorTransactionDto.gstOnTdsAmount = 0;
      this.totalDeduction = null;
      this.voucherGenerationDto.vendorTransactionDto.tdsAmount = 0;
      this.voucherGenerationDto.vendorTransactionDto.gstOnTdsAmount = 0;
      this.voucherGenerationDto.vendorTransactionDto.labourCess = 0;
      this.voucherGenerationDto.vendorTransactionDto.securityDepositAmount = 0;

      this.voucherGenerationDto.vendorTransactionDto.transactionAmount = null;
    } else {
      this.calculateTotalAmount();
      this.calculateTds();
    }
  }

  getSercurityDesposit() {
    let query = new filterQuery();
    query.key = 'commercialVendor';
    query.operation = 'equals';
    query.value = this.selectedVendor.id.toString();
    this.filterProps = this.filterProps ? this.filterProps : [];
    this.filterProps.push(query);
    query = new filterQuery();
    query.key = 'activeFlag';
    query.operation = 'equals';
    query.value = 'YES';
    this.filterProps.push(query);
    let Query = {
      page: 0,
      size: 1,
    };
    this.paymentService.filter({ ...Query }, this.filterProps).subscribe(
      (res: HttpResponse<VendorTransaction[]>) => {
        if (res.body) {
          // this.voucherGenerationDto.vendorTransactionDto.securityDeposit = 2;
        } else {
          // this.voucherGenerationDto.vendorTransactionDto.securityDeposit = 0;
        }
      },
      () => {}
    );
  }

  calculateTds() {
    this.voucherGenerationDto.vendorTransactionDto.tdsAmount = Number(
      (
        (this.voucherGenerationDto.vendorTransactionDto.tdsPercentage / 100) *
        this.voucherGenerationDto.vendorTransactionDto.grossAmount
      ).toFixed()
    );
    this.gstOnTdsAmount = Number(
      (
        (this.voucherGenerationDto.vendorTransactionDto.gstOnTds / 100) *
        this.voucherGenerationDto.vendorTransactionDto.grossAmount
      ).toFixed()
    );
    this.voucherGenerationDto.vendorTransactionDto.gstOnTdsAmount = this.convertToEvenNumber(this.gstOnTdsAmount);
    this.calculateTotalAmount();
  }

  convertToEvenNumber(num: number): number {
    if (num % 2 !== 0) {
        num++;
    }
    return num;
}

  validatePan() {
    this.panError = !this.validationService.isPanValid(
      this.selectedVendor.panNo
    );
  }
 
  addDescritption(list: string) {
    this.description = list;
  }

  genVocher() {
    this.ngSelectInvalid = false;
    const isFormValid =
      this.levelMasterDto &&
      this.componentMaster &&
      this.grantlevel &&
      this.selectedVendor.name &&
      this.selectedVendor.id &&
      this.selectedVendor.panNo &&
      this.voucherGenerationDto.vendorTransactionDto
        .paymentApprovalNumber &&
      this.voucherGenerationDto.vendorTransactionDto.nameOfWork &&
      this.voucherGenerationDto.vendorTransactionDto.remark &&
      this.isFileUploaded &&
      this.voucherGenerationDto.vendorTransactionDto.finYearDto &&
      this.voucherGenerationDto.vendorTransactionDto.paymentDate &&
      this.voucherGenerationDto.vendorTransactionDto.transactionAmount;

    if (!isFormValid) {
      this.ngSelectInvalid = true;
      this.notificationService.alertError("Please fill all the mandotory fields marked with *",'');
      return;
    }
    if(this.roleCode?.role?.code === 'BMAKER' && !this.selectedZonalUser){
      this.notificationService.alertError('Please Select the Zonal User','')
      return;
    }
    if(this.voucherGenerationDto.vendorTransactionDto.transactionAmount <= 0){
      this.notificationService.alertError('Transaction Amount should be greater than 0','');
      return;
    }
    if((this.roleCode?.role?.code === 'DMAKER' || this.roleCode?.role?.code === 'SMAKER') && this.voucherGenerationDto.vendorTransactionDto.transactionAmount>this.limitAmount){
      this.notificationService.alertError('Limit amount exceeded, Transaction amount should be within Authorized limit amount: Rs. '+this.limitAmount,'')
      return;
    }
    this.voucherGenerationDto.voucherBillDescDtoList.forEach((element)=>{
      const isVal=element.description && element.amount ;
      if (!isVal) {
        this.ngSelectInvalid = true;     
      }
      });
      if(this.ngSelectInvalid){
        return;
      }
    this.ngSelectInvalid = false;
    if (this.selectedZonalUser?.id) {
      this.voucherGenerationDto.vendorTransactionDto.zonalChecker =
        this.selectedZonalUser?.id;
    }
    this.voucherGenerationDto.vendorTransactionDto.paymentMethod="MigrateVendor";
    this.spinner.show();
    if (
      this.voucherGenerationDto.vendorTransactionDto.securityDepositAmount ===
        null ||
      this.voucherGenerationDto.vendorTransactionDto.securityDepositAmount === 0
    ) {
      this.voucherGenerationDto.vendorTransactionDto.securityDeposit = 0;
    }
    if (
      this.voucherGenerationDto.vendorTransactionDto.gstOnTdsAmount === null ||
      this.voucherGenerationDto.vendorTransactionDto.gstOnTdsAmount === 0
    ) {
      this.voucherGenerationDto.vendorTransactionDto.gstOnTds = 0;
    }
    this.voucherGenerationDto.vendorTransactionDto.commercialVendorDto =
      this.selectedVendor;
    this.voucherGenerationDto.vendorTransactionDto.componentTypeDto =
      this.grantlevel;
      this.voucherGenerationDto.vendorTransactionDto.componentMaster=this.componentMaster;
    this.voucherGenerationDto.vendorTransactionDto.invoiceAmount =
      this.voucherGenerationDto.vendorTransactionDto.netAmount;
    this.voucherGenerationDto.vendorTransactionDto.personalFlag = 'No';
    this.voucherGenerationDto.vendorTransactionDto.levelTypeId =
      this.levelMasterDto.id;

      this.paymentService.saveMigrateWorkPayment(this.voucherGenerationDto).subscribe(
        (response: any) => {
        this.readonly = true;
        this.spinner.hide();
        this.router.navigate(['/MigrateVendorPayment']);
        this.notificationService.alertSuccess('Saved Successfully', '');
      },
      (error) => {
        this.spinner.hide();
        if (error.status == 400)
        {
          this.notificationService.alertError('Due to insufficient limit, You are not eligible to generate Voucher','');

        } else {
          this.notificationService.alertError('An unexpected error occurred.'+error,'');
        }
      }
    );
  }

  submit(){
  }

  calculateTotalAmount() { 
    if (this.voucherGenerationDto?.voucherBillDescDtoList.length !== 0) {
      if (!this.voucherGenerationDto.vendorTransactionDto.tdsAmount) {
        this.voucherGenerationDto.vendorTransactionDto.tdsAmount = 0;
      }

      let initaiTotal = 0;
      let intitalCgst = 0;
      let intitalSgst = 0;
      let intitalTotalWithTax = 0;
      let initialIgst = 0;
      this.voucherGenerationDto.vendorTransactionDto.securityDepositAmount = 0;
      this.voucherGenerationDto.vendorTransactionDto.labourCess = 0;
      this.voucherGenerationDto?.voucherBillDescDtoList.forEach((element) => {
        initaiTotal += element.amount;
        if (element.sgstPercentage) {
          intitalCgst += element.cgstAmount;
          intitalSgst += element.sgstAmount;
        }
        if (element.igstPercentage) {
          initialIgst += element.igstAmount;
        }
        intitalTotalWithTax += element.grossAmount;
      });
      this.voucherGenerationDto.vendorTransactionDto.grossAmount = initaiTotal;
      this.voucherGenerationDto.vendorTransactionDto.totalCgst = intitalCgst;
      this.voucherGenerationDto.vendorTransactionDto.totalSgst = intitalSgst;
      this.voucherGenerationDto.vendorTransactionDto.totalIgst = initialIgst;
      this.voucherGenerationDto.vendorTransactionDto.netAmount =
        intitalTotalWithTax;
      this.voucherGenerationDto.vendorTransactionDto.gstOnTdsAmount = 0;
      if (
        this.voucherGenerationDto.vendorTransactionDto.grossAmount &&
        this.enableGstOnTds
      ) {
        this.gstOnTdsAmount = Number(
          (
            (this.voucherGenerationDto.vendorTransactionDto.gstOnTds / 100) *
            this.voucherGenerationDto.vendorTransactionDto.grossAmount
          ).toFixed()
        );
    this.voucherGenerationDto.vendorTransactionDto.gstOnTdsAmount = this.convertToEvenNumber(this.gstOnTdsAmount);
      }
      if (this.enableSecurityDeposit && this.voucherGenerationDto.vendorTransactionDto.netAmount) {
        this.voucherGenerationDto.vendorTransactionDto.securityDepositAmount =
          Number(
            (
              (this.voucherGenerationDto.vendorTransactionDto.securityDeposit /
                100) *
              this.voucherGenerationDto.vendorTransactionDto.netAmount
            ).toFixed()
          );
      }
      if (this.enableLabourChess && this.voucherGenerationDto.vendorTransactionDto.grossAmount) {
        this.voucherGenerationDto.vendorTransactionDto.labourCess = Number(
          (
            (this.voucherGenerationDto.vendorTransactionDto
              .labourCessPercentage /
              100) *
            this.voucherGenerationDto.vendorTransactionDto.grossAmount
          ).toFixed()
        );
      }
      this.totalDeduction =
        this.voucherGenerationDto.vendorTransactionDto.labourCess +
        this.voucherGenerationDto.vendorTransactionDto?.tdsAmount +
        this.voucherGenerationDto.vendorTransactionDto.securityDepositAmount +
        this.voucherGenerationDto.vendorTransactionDto.gstOnTdsAmount;
      this.voucherGenerationDto.vendorTransactionDto.transactionAmount =
        this.voucherGenerationDto.vendorTransactionDto.netAmount -
        this.totalDeduction;
    }
  }

  getvendorTrans() {
    if (this.selectedVendor) {
      this.paymentService
        .getListVendorTransction(this.selectedVendor.id)
        .subscribe(
          (res: any) => {
            this.noRecord = true;
            this.searchResult.items = res.body ?? [];
            if (this.searchResult.items.length > 0) {
              this.noRecord = false;
            }
            this.searchResult.total = 5;
            this.searchResult = { ...this.searchResult };
            this.profileDialog = true;
          },
          () => {}
        );
    }
  }

  redirect() {
    this.router.navigate(['/MigrateVendorPaymentVoucher'], {
      queryParams: {
        id:String(this.voucherGenerationDto.vendorTransactionDto.id),
      },
    });
  }
}




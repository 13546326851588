
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FontService } from 'src/app/shared/font-service/font.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserContext, UserRoleService } from 'src/app/shared/service/user-role.service';
import {DashboardCard, FundTransactions, ProjectSummary, ProjectSummaryByLevel} from '../dashboard';
import { FundsService } from 'src/app/module/funds/funds.service';
import { Funds } from 'src/app/module/funds/funds';
import enIN from '@angular/common/locales/en-IN';
import {registerLocaleData } from '@angular/common';
import { LevelMaster } from 'src/app/module/level-master/level-master';
import { DashboardService } from '../dashboard.service';
import { filterQuery } from 'src/app/shared/common.model';
import { LimitSetService } from 'src/app/module/limit-set/limit-set.service';
import { LimitSet } from 'src/app/module/limit-set/limit-set';
import { userRoles } from 'src/app/shared/models/user-role-code';
import { LimitTransListService } from 'src/app/module/limit-translist/limit-translist.service';
import { FinYear } from 'src/app/module/limit-translist/limit-translist.module';

@Component({
  selector: 'app-district-dashboard',
  templateUrl: './district-dashboard.component.html',
  styleUrls: ['./district-dashboard.component.scss']
})
export class DistrictDashboardComponent implements OnInit {

  
  dashboardCardDetails:DashboardCard;
  fundTransactions:FundTransactions;
  activeIndex = 0;
  fundLimitActiveIndex = 0;
  exprLimitActiveIndex = 0;
  balLimitActiveIndex = 0;
  workActiveIndex = 0;
  limitFundByYear: any;
  fundExpenditure: FundTransactions;
  fundBalance: FundTransactions;
  expenditureByYear: any;
  balanceByYear: any;
  projectSummary: ProjectSummary;
  projectSummaryByLevel:ProjectSummaryByLevel;
  levelMasterId:number;
  funds: LimitSet[];
  userContextSubscription: Subscription;
  filterProps: any[];
  isStateLogin: boolean;
  fundSuccessfullTransaction: FundTransactions;
  successExpenditureByYear: any;
  successExprLimitActiveIndex: number;
  finYear: FinYear[];
  finYearDto: FinYear;
  
  constructor(private dashboardService:DashboardService, private fundsService: FundsService,
    public fontService:FontService, public userRoleService:UserRoleService,
    public limitSetService:LimitSetService,private limitTransListService: LimitTransListService) { 
    registerLocaleData(enIN);
    }

  
  
  ngOnInit(): void {
    this.userContextSubscription=this.userRoleService.getuserContext().subscribe(
      (res)=>{
        this.levelMasterId = res.levelMaster.id;
        if (res.role.code == userRoles.roleStateMaker ||
          res.role.code == userRoles.roleStateChecker ||
          res.role.code == userRoles.roleStateApprover) {
            this.isStateLogin=true;
      } 
    },
    (error)=>{
      
    })
 
    this.limitTransListService.getFinYear().subscribe(
      (res: HttpResponse<FinYear[]>) => {
        this.finYear = res.body;
        this.finYearDto = this.finYear[0];
        this.onFinyearChange();
         
      },
      () => { }
    );
   
    
  }

  onFinyearChange() {
    this.dashboardService.getDashboardCardDetails(this.levelMasterId,this.finYearDto.id).subscribe(
      (res)=>{
        this.dashboardCardDetails=res.body;
        this.activeIndex = 0;
        this.onCardTabChange();
      },
      (error)=>{}
    )
   
  }
  getFunds() {
    let query = {
		  page: 0,
		  size: 10,
		};
    this.filterProps = [];
    let filquery = new filterQuery();
    filquery.key = 'levelMaster.id';
    filquery.operation = 'equals';
    filquery.value =this.levelMasterId ;
    this.filterProps.push(filquery);
    filquery = new filterQuery();
    filquery.key = 'finYear.id';
    filquery.operation = 'equals';
    filquery.value =this.finYearDto.id ;
    this.filterProps.push(filquery);
    
		this.limitSetService
		  .filter({ ...query},this.filterProps)
		  .subscribe(
			(res: HttpResponse<LimitSet[]>) => {
        this.funds = res.body;
      },
      () => {
       
      }
    );
  }

  loadfundLimitDistrict(id){
    this.fundLimitActiveIndex=1;
    this.dashboardService.limitFundByYear(id,this.levelMasterId).subscribe(
      (res)=>{
        this.limitFundByYear=res.body;
      },
      (error)=>{}
    )
    
  }

  loadExpenditureDistrict(id){
    if(!this.isStateLogin){
      this.exprLimitActiveIndex=1;
      this.dashboardService.expenditureByYear(id,this.levelMasterId).subscribe(
        (res)=>{
          this.expenditureByYear=res.body;
        },
        (error)=>{}
      )
    }
  }

  loadSuccessExpenditureDistrict(id){
    this.successExprLimitActiveIndex=1;
    this.dashboardService.SuccessTransactionExpenditureByYear(id,this.levelMasterId).subscribe(
      (res)=>{
        this.successExpenditureByYear=res.body;
      },
      (error)=>{}
    )
  }

  loadBalanceDistrict(id){
    if(!this.isStateLogin){
      this.balLimitActiveIndex=1;
      this.dashboardService.balanceByYear(id,this.levelMasterId).subscribe(
        (res)=>{
          this.balanceByYear=res.body;
        },
        (error)=>{}
      )
    }
    
  }

  loadWorkDistrict(id){
    this.workActiveIndex=1;
    this.dashboardService.getProjectSummaryByLevel(id,this.levelMasterId).subscribe(
      (res)=>{
        this.projectSummaryByLevel=res.body;
      },
      (error)=>{}
    )
    
  }

  onCardTabChange(){
    switch (this.activeIndex) {
      case 0:
        this.getFunds();
      break;
      case 1:
        this.loadfundLimitDistrict(this.finYearDto.id);
      break;
      case 2:
        this.loadWorkDistrict(this.finYearDto.id);
      break;
      case 3:
        this.loadExpenditureDistrict(this.finYearDto.id);
      break;
      case 4:
        this.loadSuccessExpenditureDistrict(this.finYearDto.id);
      break;
      case 5:
        this.loadBalanceDistrict(this.finYearDto.id);
      break;
        
    }
    this.fundLimitActiveIndex=0;
    this.limitFundByYear=null;
    this.exprLimitActiveIndex=0;
    this.successExprLimitActiveIndex=0;
    this.expenditureByYear=null;
    this.balLimitActiveIndex=0;
    this.balanceByYear=null;
    this.workActiveIndex=0;
    this.projectSummaryByLevel=null;
    
  }

  onfundLimitTabChange(event: any){
    if (event.index === 0){
      this.limitFundByYear=null;
    }
  }
  onExpenditureTabChange(event: any){
    if (event.index === 0){
      this.expenditureByYear=null;
    }
  }
  onSuccessExpenditureTabChange(event: any){
    if (event.index === 0){
      this.successExpenditureByYear=null;
    }
  }
  onBalanceTabChange(event: any){
    if (event.index === 0){
      this.balanceByYear=null;
    }
  }
  onWorkTabChange(event: any){
    if (event.index === 0){
      this.projectSummaryByLevel=null;
    }
  }

  formatCash = n => {
    return +(n / 1e7).toFixed(3);
  };
  
  formatCashLakh = n => {
    return +(n / 1e5).toFixed(3) ;
  }; 

  ngOnDestroy() {
    // Unsubscribe to prevent memory leaks
    if (this.userContextSubscription) {
      this.userContextSubscription.unsubscribe();
    }
  }
}



import { Component, OnInit } from '@angular/core';
import { StatutoryPaymentService } from './statutory-payment.service';
import { FontService } from 'src/app/shared/font-service/font.service';
import { Statutory } from './statutory-payment.model';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-statutory-payment',
  templateUrl: './statutory-payment.component.html',
  styleUrls: ['./statutory-payment.component.scss']
})
export class StatutoryPaymentComponent implements OnInit {
  statutoryData:Statutory;
  cols:any;
  date: Date;
  constructor(public fontService:FontService,
    public statutoryService:StatutoryPaymentService,
    private spinner: NgxSpinnerService,
    private datePipe: DatePipe,
    ) { }

  ngOnInit(): void {
   
    this.cols = [
      { field: 'workId', header: 'workId' },
      { field: 'vendorName', header: 'vendorName' },
      { field: 'panNo', header: 'panNo' },
      { field: 'gstNo', header: 'gstNo' },
      { field: 'gstOnTDS', header: 'gstOnTDS' },
      { field: 'itAmount', header: 'itAmount' },
      { field: 'labourCess', header: 'labourCess' }
  ];
    
    }

    onSearch(){
      this.spinner.show();
      let month=this.date.getMonth();
      let year=this.date.getFullYear();
      const lastDay = new Date(year, month+1, 0).getDate();
      let startDate=this.datePipe.transform(new Date(year, month, 1), 'yyyy-MM-dd HH:mm:ss.SSS');
      let endDate=this.datePipe.transform(new Date(year, month+1, 0, 23, 59, 59, 999), 'yyyy-MM-dd HH:mm:ss.SSS');
      this.statutoryService.getStatutoryPayment(startDate,endDate)
        .subscribe(
          (res) => {
            this.spinner.hide();
            this.statutoryData=res.body;
      },
      (error)=>{
        this.spinner.hide();
      });
    }

    export(){
            let element = document.getElementById('statutoryDataTable');
            const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            XLSX.writeFile(wb, 'StatutoryReport.xlsx');
      }
}

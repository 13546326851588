import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StatutoryAccountDetail, StatutoryDeductionType } from './statutory-account-model';
import { createRequestOption } from 'src/app/auth/request/request-util';

@Injectable({
  providedIn: 'root'
})
export class StatutoryAccountService {

  protected saveStatutoryDetailUrl = environment.SERVER_API_URL+('api/saveStatutoryDetail')
  protected getAllStatutoryDetailsUrl = environment.SERVER_API_URL+('api/getAllStatutoryDetails')
  protected getAllDeductionTypesUrl = environment.SERVER_API_URL+('api/getAllDeductionTypes')
  protected getStatutoryDetailByIdUrl = environment.SERVER_API_URL+('api/getStatutoryDetailById')
  protected approveStatutoryAccountDetailsUrl = environment.SERVER_API_URL+('api/approveStatutoryAccountDetails')
  protected rejectStatutoryAccountDetailsUrl = environment.SERVER_API_URL+('api/rejectStatutoryAccountDetails')
  protected getAllDeductionTypesByFilterUrl = environment.SERVER_API_URL+('api/getAllDeductionTypesByFilter')

  constructor(protected http: HttpClient) { }
  getAllStatutoryDetailsfilter(req?: any,filter?:any): Observable<any> {
    if(filter === null){
      filter = []
    }
    const options = createRequestOption(req);
      return this.http
      .post<StatutoryAccountDetail[]>(this.getAllStatutoryDetailsUrl,filter,{ params:options, observe: 'response' })
  }

  save(data:StatutoryAccountDetail){
      return this.http
      .post<StatutoryAccountDetail>(this.saveStatutoryDetailUrl,data, {observe: 'response' })
  }

  getAllDeductionTypes(){
    return this.http
    .post<StatutoryDeductionType>(this.getAllDeductionTypesUrl,{observe: 'response' })
}

getStatutoryDetailById(id: number){
  return this.http.get<StatutoryDeductionType>(
    `${this.getStatutoryDetailByIdUrl}?id=${id}`,
    { observe: 'response' }
  );
}

approve(id:number): Observable<any> {
  return this.http.post(`${this.approveStatutoryAccountDetailsUrl}?id=${id}`,null,{responseType: 'text'});
}

reject(id:number,isReject:boolean,rejectReason?:string): Observable<any> {
  return isReject?this.http.post(`${this.rejectStatutoryAccountDetailsUrl}?id=${id}&rejectReason=${rejectReason}&isReject=${isReject}`,null,{responseType: 'text'}):this.http.post(`${this.rejectStatutoryAccountDetailsUrl}?id=${id}&isReject=${isReject}`,null,{responseType: 'text'});
}

getAllDeductionTypesByFilter(req?: any,filter?:any){
  if(filter === null){
    filter = []
  }
  const options = createRequestOption(req);
    return this.http
    .post<StatutoryDeductionType[]>(this.getAllDeductionTypesByFilterUrl,filter,{ params:options, observe: 'response' })
}

}

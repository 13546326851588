import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
import { User, Role, userDto, LoginDto } from './user';
export type EntityArrayResponseType = HttpResponse<User[]>;
@Injectable()
export class UserService {
    
    protected resourceFliterUrl = environment.SERVER_API_URL+('api/getAdminUserByFilter');
    protected roleUrl = environment.SERVER_API_URL+('api/roleList');
    protected getUserDtoUrl = environment.SERVER_API_URL+('api/getAdminUserDtoObject')
    protected resourceSaveUrl = environment.SERVER_API_URL+('api/SaveAdminUser');
    protected updateSaveUrl = environment.SERVER_API_URL+('api/updateUser');
    protected resourceEditUrl = environment.SERVER_API_URL+('api/getAdminUser');
    protected resourceDeleteUrl = environment.SERVER_API_URL+('api/deleteUser');
    protected userActivateUrl = environment.SERVER_API_URL+('api/userActivate');
    protected userDeactivateUrl = environment.SERVER_API_URL+('api/userDeactivate');
    protected userEnableDisableUrl = environment.SERVER_API_URL+('api/userEnableDisable');
    protected userUnlockUrl = environment.SERVER_API_URL+('api/userUnlock');

    constructor(private http: HttpClient) { }

   

    filter(req?: any,filter?:any): Observable<EntityArrayResponseType> {
      if(filter === null){
        filter = []
      }
      const options = createRequestOption(req);
        return this.http
        .post<User[]>(this.resourceFliterUrl,filter, { params:options, observe: 'response' })
    }



    saveUser(user?:userDto): Observable<any> {
      return this.http
      .post<userDto[]>(this.resourceSaveUrl,user, {observe: 'response' })
    }

    updateUser(user?:userDto): Observable<any> {
      return this.http
      .post<userDto[]>(this.updateSaveUrl,user, {observe: 'response' })
    }

    deleteUser(user?:LoginDto): Observable<any> {
      return this.http
      .post<userDto[]>(this.resourceDeleteUrl,user, {observe: 'response' })
    }

    getRole(): Observable<any> {
      // const options = createRequestOption(req);
      return this.http
        .get<Role[]>(this.roleUrl, {  observe: 'response' });
    }

    getUserDto(): Observable<any> {
      // const options = createRequestOption(req);
      return this.http
        .get<userDto>(this.getUserDtoUrl, {  observe: 'response' });
    }

    getUser(id: number): Observable<HttpResponse<any>> {
      return this.http.get<userDto>(`${this.resourceEditUrl}?id=${id}`, { observe: 'response' });
    }

    userActivate(userId: number): any {
      return this.http.get(`${this.userActivateUrl}?userId=${userId}`, { responseType: 'text' });
    }

    userDeactivate(userId: number): any{
      return this.http.get(`${this.userDeactivateUrl}?userId=${userId}`, { responseType: 'text' });
    }

    userEnableDisable(req): any{
      const options = createRequestOption(req);
      return this.http.get(this.userEnableDisableUrl, {params:options, responseType: 'text' });
    }

    userUnlock(userId: number): any{
      return this.http.get(`${this.userUnlockUrl}?userId=${userId}`, { responseType: 'text' });
    }


  
}
import { SchemeList } from './../scheme-list/scheme-list';
import { DepartmentList } from './../department-list/department-list';
import { RoleDto } from "../limit-set/limit-set"
import { LevelMasterDto } from '../level-master/level-master';

export interface User {
    id: number
    userName: string
    password: string
    mobile: string
    email: string
    resetPassword: string
    profileStatus: string
    loginAttempt: number
    role: Role
    resetDate: any
    createdOn: string
    updatedOn: string
    createdBy: number
    updatedBy: number
    activeFlag: string
}

export class Role {
  id: number
  code: string
  name: string
  createdOn: string
  updatedOn: string
}



export class userDto{
  id: number;
  userName: string;
  password: string;
  mobile: string;
  email: string;
  activeFlag: string;
  userActiveFlag: string;
  resetPassword: string;
  loginAttempt: number;
  roleDto: Role;
  resetDate: Date;
  createdOn: Date;
  updatedOn: Date;
  createdBy: number;
  updatedBy: number;
  schemeName: string;
  departmentDto: DepartmentList;
  schemeDto: SchemeList;
  implementingAgencyId: number;
  levelMaster: LevelMasterDto;
  keyCloakUuid: string;
  levelId: number;
  zonalName:string

}

export class LoginDto {
  username: string;
  password: string;
  refreshToken: string;
  keyCloakUserId: string;
}
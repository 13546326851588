import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { LevelType } from 'src/app/module/level-type/level-type';
import { LevelTypeService } from 'src/app/module/level-type/level-type.service';
import { PaymentService } from '../payment-voucher/payment-voucher.service';
import { filterQuery } from 'src/app/shared/common.model';
import { BulkVoucher, ParentVoucher, ParentVoucherBillDesc, VendorTransaction, vendorTransactionDto } from '../payment-voucher/payment-voucher.model';
import { Subscription } from 'rxjs/internal/Subscription';
import { UserRoleService } from 'src/app/shared/service/user-role.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FontService } from 'src/app/shared/font-service/font.service';
import { LocalStorageService } from 'ngx-webstorage';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/alert/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-bluk-payment-voucher',
  templateUrl: './bluk-payment-voucher.component.html',
  styleUrls: ['./bluk-payment-voucher.component.scss']
})
export class BlukPaymentVoucherComponent implements OnInit {
  userContextSubscription: Subscription;
  roleCode: any;
  levelMasterDto: LevelType;
  filterProps: any;
  vendorList: VendorTransaction[];
  bulkVoucher:BulkVoucher;
  param1: any;
  param2: any;
  param3: any;
  enableSignButton: boolean;
  schemeName: any;
  departmentName: any;
  paymentSucessDialog: boolean;
  paymentSucessText: string;
  paymentMethod: string;
  rejectReason:string;
  reasonDialog:boolean;
  requiredError: boolean;
  currentvendorId:number;

  constructor(
    private levelTypeService: LevelTypeService,
    private paymentService: PaymentService,
    private userRoleService: UserRoleService,
    public activatedRoute:ActivatedRoute,
    public fontService: FontService,
    protected router: Router,
    private localStorageService: LocalStorageService,
    protected notificationService: NotificationService,
    private spinner: NgxSpinnerService,

  ) { }

  ngOnInit() {
    if (this.activatedRoute.snapshot.queryParamMap.get('response')){
      this.paymentSucessDialog = true;
      this.paymentSucessText = this.activatedRoute.snapshot.queryParamMap.get('response');
    }
    if (this.activatedRoute.snapshot.queryParamMap.get('error')){
      this.notificationService.alertError('Error Occurred: '+this.activatedRoute.snapshot.queryParamMap.get('error'),'');
    }
    this.paymentMethod = this.activatedRoute.snapshot.queryParamMap.get('type');
    if (!this.activatedRoute.snapshot.queryParamMap.get('id')) {
      this.bulkVoucher = new BulkVoucher();
      this.bulkVoucher.parentVoucher = new ParentVoucher();
      this.bulkVoucher.parentVoucher.voucherNumber =
      this.activatedRoute.snapshot.queryParamMap.get('VoucherNo');
      this.bulkVoucher.parentVoucher.transactionDate = new Date();
    this.userContextSubscription = this.userRoleService.getuserContext().subscribe(
      res => {
        console.log('resss',res)
      this.roleCode = res;
      this.bulkVoucher.parentVoucher.levelMaster = this.roleCode.levelMaster
      this.paymentService
      .getByImplemeting(this.roleCode.implementingAgencyId)
      .subscribe(
        (res: HttpResponse<any>) => {
          this.bulkVoucher.parentVoucher.implementingAgency =
            res.body;
            this.departmentName = res.body.departmentDto.name;
            this.schemeName = res.body.schemeDto.name;
        },
        () => {}
      );
      },
      () => {}
    );
    this.getVoucher();
    }
    else if (this.activatedRoute.snapshot.queryParamMap.get('id')) {
      this.spinner.show();
      this.paymentService
      .getParentVocherbyId(Number(this.activatedRoute.snapshot.queryParamMap.get('id')))
      .subscribe(
        (res: HttpResponse<BulkVoucher>) => {
          this.bulkVoucher =res.body;
          this.departmentName = res.body.parentVoucher.implementingAgency.department.name;
          this.schemeName = res.body.parentVoucher.implementingAgency.scheme.name;
          if(this.bulkVoucher.parentVoucher.signStatus){
            this.enableSignButton = true;
            this.spinner.hide();
          }
          else{
            this.spinner.hide();
          }
        },
        () => {}
      );
    }
   
  }

  revert(id) {
    this.spinner.show();
    this.paymentService
      .revertTranaction(
        Number(id)
      )
      .subscribe(
        (res: HttpResponse<any>) => {
          this.spinner.hide();
          this.notificationService.alertSuccess('Revert Successfully','');
          this.getVoucher();
        },
        () => {
          this.notificationService.alertSuccess('Error','');
          this.spinner.hide();
        }
      );
  }

  rejectDialog(){
    if(!this.rejectReason){
      this.requiredError=true;
      return;
    }
    else{
      this.reasonDialog=false;
      this.reject();
    }
  }

  reject() {
    this.spinner.show();
    this.paymentService.deletedVocher(
        this.currentvendorId,this.rejectReason)
      .subscribe(
        (res: HttpResponse<any>) => {
          this.spinner.hide();
          this.getVoucher();
          this.currentvendorId = null;
        },
        () => {
          this.spinner.hide();
          this.notificationService.alertError('Unexpected error occured!!','');
        }
      );
  }

  submitForm(){
    this.spinner.show();
    this.paymentService
      .getfile(this.bulkVoucher.parentVoucher.voucherNumber)
    .subscribe(
      (res: HttpResponse<any>) => {
        console.log('ress',res.body);
        this.spinner.hide();
        this.param1 = res.body.paramOne;
        this.param2 = res.body.paramTwo;
        this.param3 = res.body.paramThree;
        if(!this.bulkVoucher.parentVoucher.id){
          this.router.navigate(['/blukpaymentVoucher'], {
            queryParams: {
              id: res.body.paramFour
            },
          });
        }
        this.localStorageService.store('refresh', 'Yes');
        const formElement = document.createElement('form');
        formElement.method = 'POST';
        formElement.action = environment.CDAC_URL;
        let inputElement = document.createElement('input');
        inputElement.type = 'hidden';
        inputElement.name = "eSignRequest";
        inputElement.value = res.body.paramOne;
        formElement.appendChild(inputElement);
        inputElement = document.createElement('input');
        inputElement.type = 'hidden';
        inputElement.name = "aspTxnID";
        inputElement.value = res.body.paramTwo;
        formElement.appendChild(inputElement);
        inputElement = document.createElement('input');
        inputElement.type = 'hidden';
        inputElement.name = "Content-Type";
        inputElement.value = res.body.paramThree;
        formElement.appendChild(inputElement);
        document.body.appendChild(formElement);
        formElement.submit();
      },
      (error) => {
        this.spinner.hide();
        
      }
    );
    
  }

  

  getVoucher() {
    this.spinner.show();
    this.paymentService.getSign2PendingVoucher({levelMasterId:this.roleCode.levelMaster.id,paymentMethod:this.paymentMethod}).subscribe(
      (res: HttpResponse<VendorTransaction[]>) => {
        this.spinner.hide();
        this.vendorList = res.body;
        this.bulkVoucher.parentVoucherBillDescList = [];
        this.vendorList.forEach(element => {
          const subList = new ParentVoucherBillDesc();
          element.isDefault = true;
          subList.childVoucherNumber = element.voucherNo;
          subList.netAmount = element.netAmount;
          subList.vendorTransactionId = element.id;
          subList.transactionAmount = element.transactionAmount;
          if(this.paymentMethod === 'Work'){
            subList.workId = element.workId;
            subList.workName = element.nameOfWork;
          }
          subList.vendorAccountNumber = element.commercialAccountNo;
          subList.vendorIFSCCode = element.commercialIfscode;
          subList.vendorId = element.commercialVendor.id;
          subList.vendorName = element.commercialVendorName;
          this.bulkVoucher.parentVoucherBillDescList.push(subList);
        });
      },
      (err) => {
       
      }
    );
  }

  VoucherCheckStatus(element:VendorTransaction){
   
    if(element.isDefault){
      this.bulkVoucher.parentVoucherBillDescList =  this.bulkVoucher.parentVoucherBillDescList ?  this.bulkVoucher.parentVoucherBillDescList : [];
      const subList = new ParentVoucherBillDesc();
      element.isDefault = true;
      subList.childVoucherNumber = element.voucherNo;
      subList.netAmount = element.netAmount;
      subList.vendorTransactionId = element.id;
      subList.transactionAmount = element.transactionAmount;
      subList.workId = element.workId;
      subList.workName = element.nameOfWork;
      subList.vendorAccountNumber = element.commercialAccountNo;
      subList.vendorIFSCCode = element.commercialIfscode;
      subList.vendorId = element.commercialVendor.id;
      subList.vendorName = element.commercialVendorName;
      this.bulkVoucher.parentVoucherBillDescList.push(subList);
    }
    else{
      const index = this.bulkVoucher.parentVoucherBillDescList.findIndex((item) => item.vendorTransactionId === element.id);
      this.bulkVoucher.parentVoucherBillDescList.splice(index, 1);
     
    }
    console.log('this.vendor',this.bulkVoucher.parentVoucherBillDescList);

  }

  viewVoucherDetails(id) {
    const queryParams = { id:id,view:true };
    let urlTree:any
    if(this.paymentMethod === 'Work'){
    urlTree = this.router.createUrlTree(['/PaymentVoucher'], { queryParams });
    }
    else{
      urlTree = this.router.createUrlTree(['/VendorPaymentVoucher'], { queryParams });
    }
    const url = this.router.serializeUrl(urlTree);
    // Open the link in a new tab
    window.open(url, '_blank');
  }

  genVocher(){
    this.spinner.show();
    if(this.paymentMethod === 'Work'){
      this.bulkVoucher.parentVoucher.paymentMethod = 'Work';
    }
    else{
      this.bulkVoucher.parentVoucher.paymentMethod = 'Vendor';
    }
    
    this.paymentService.genBulkVocher(this.bulkVoucher).subscribe(
      (res) => {
       this.enableSignButton = true;
       this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  getActionUrl(){
    this.localStorageService.store('refresh', 'Yes');
    return environment.CDAC_URL;
  }

  getfile() {
    this.paymentService
      .getfile(this.bulkVoucher.parentVoucher.voucherNumber)
      .subscribe(
        (res: HttpResponse<any>) => {
          this.spinner.hide();
          this.param1 = res.body.paramOne;
          this.param2 = res.body.paramTwo;
          this.param3 = res.body.paramThree;
          this.enableSignButton = true;
          if(!this.bulkVoucher.parentVoucher.id){
            this.router.navigate(['/blukpaymentVoucher'], {
              queryParams: {
                id: res.body.paramFour
              },
            });
          }
          // add more parameters as needed
        },
        () => {}
      );
  }

  getPdf() {
    this.paymentService
      .getPdfFile(this.bulkVoucher.parentVoucher.voucherNumber,false)
      .subscribe(
        (response: any) => {
          const blob = new Blob([response.body], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'file.pdf';
          document.body.appendChild(link);
          window.open(url, '_blank');
          link.click();
          URL.revokeObjectURL(url);
          // this.spinner.hide();
          // this.router.navigate(['payment']);
          // this.getfile();
          // this.notificationService.alertSuccess('Saved Successfully', '');
        },
        (err) => {
          // this.spinner.hide();
          // this.notificationService.alertError('Error!', '');
        }
      );
  }

  redirect() {
    this.router.navigate(['/blukpaymentVoucher'], {
      queryParams: {
        id:String(this.bulkVoucher.parentVoucher.id),
      },
    });
  }

}

<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass">Statutory Account Configuration</h5>
                </ng-template>
                <ng-template pTemplate="right">
                    <button *ngIf="!isChecker" pButton pRipple icon="pi pi-plus" class="p-button-secondary custom-button  new-button" (click)="openNew()" styleClass="button">
              <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.newbutton">
              </span>
              </button>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="row">
        <div #customerTable id="entities" class="col-12 px-4">
            <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" (searchParam)="onLazyLoad($event)" [levelMasterId]="levelMasterId" [disableDeleteIcon]="true" [disableEditIcon]="true" [disableVendorEdit]="allowEdit?false:true" [enableViewIcon]="true"
                [disableVendorDelete]="allowEdit?false:true" [enableApproveButton]="enableApprove" (emitRow)="onSelectRow($event)"></custom-filter-table>
        </div>
    </div>
    <p-dialog #dialog [(visible)]="configureDialog" [modal]="true" (onHide)="hideDialog()" styleClass="ib-dialog" [style]="{ width: '850px' }">
        <p-header [ngClass]="fontService.headingClass">
            Configure Statutory Account
        </p-header>
        <ng-template pTemplate="content">
            <div class="row gy-2 mt-2">
                <div class="col-sm-6 col-md-4">
                    <label class="form-control-label req" [ngClass]="fontService.labelClass" for=" statutoryType">Statutory Type</label>
                    <ng-select [appendTo]="'.p-dialog'" id="statutoryType" bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true" class="form-control" [items]="statutoryDeductionList" [disabled]="viewOnly" [(ngModel)]="stautoryAccount.deductionType"
                        [ngClass]="{ 'is-invalid': ngSelectInvalid && !stautoryAccount.deductionType }" (change)="onDeductionTypeChange()">
                    </ng-select>
                </div>
                <div class="col-sm-6 col-md-4">
                    <label class="form-control-label" for="panNo" [ngClass]="fontService.labelClass" jhiTranslate="vendor.panno">PAN No</label>
                    <input type="text" class="form-control" name="panNo" autocomplete="off" [(ngModel)]="stautoryAccount.panNumber" id="panNo" [disabled]="viewOnly" oninput="this.value = this.value.replace(/[^A-Za-z0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                        onblur="this.value =this.value.trim();" uppercase (input)="validatePan()" maxlength="10" />
                    <div *ngIf="showPanError" class="text-danger">
                        <small [ngClass]="fontService.smallClass" jhiTranslate="error.panNoUniError">
    {{ showPanError }}
  </small>
                    </div>
                    <div *ngIf="panError" class="text-danger">
                        <small [ngClass]="fontService.smallClass" jhiTranslate="error.validpan">Please enter a valid PAN
    Number.</small>
                    </div>
                </div>
                <div class="col-sm-6 col-md-4">
                    <label class="form-control-label" for=" gstNo" [ngClass]="{
              'req': mandateGst,
              'fontService.labelClass': false
            }" jhiTranslate="user.gstNo">GST No</label>
                    <input type="text" class="form-control" name="gstNo" autocomplete="off" [(ngModel)]="stautoryAccount.gstNumber" id="gstNo" oninput="this.value = this.value.replace(/[^A-Za-z0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();"
                        uppercase (input)="validateGst()" maxlength="15" [disabled]="viewOnly" [ngClass]="{ 'is-invalid': ngSelectInvalid && !stautoryAccount.gstNumber && mandateGst }" />
                    <div *ngIf="showGstError" class="text-danger">
                        <small [ngClass]="fontService.smallClass" jhiTranslate="error.gstUniError">
    {{ showGstError }}
  </small>
                    </div>
                    <div *ngIf="gstError" class="text-danger">
                        <small [ngClass]="fontService.smallClass" jhiTranslate="error.validgst">Please enter a valid GST
    Number.</small>
                    </div>
                </div>
                <div class="col-sm-6 col-md-4">
                    <label class="form-control-label" for="tanNo" [ngClass]="{
              'req': mandateTan,
              'fontService.labelClass': true
            }" jhiTranslate="user.tanNo">TAN No</label>
                    <input type="text" name="tanNo" autocomplete="off" class="form-control" [(ngModel)]="stautoryAccount.tanNumber" oninput="this.value = this.value.replace(/[^A-Za-z0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();"
                        uppercase id="tanNo" (input)="validateTan()" maxlength="10" [disabled]="viewOnly" [ngClass]="{ 'is-invalid': ngSelectInvalid && !stautoryAccount.tanNumber && mandateTan }" />
                    <div *ngIf="tanError" class="text-danger">
                        <small [ngClass]="fontService.smallClass" jhiTranslate="error.validtan">Please enter a valid TAN
    Number.</small>
                    </div>
                </div>
                <div class="col-sm-6 col-md-4">
                    <label class="form-control-label" for="tinNo" [ngClass]="fontService.labelClass" jhiTranslate="user.tinNo">TIN No</label>
                    <input type="text" class="form-control" name="tinNo" autocomplete="off" [(ngModel)]="stautoryAccount.tinNumber" oninput="this.value = this.value.replace(/[^0-9-]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();"
                        uppercase id="tinNo" [disabled]="viewOnly" (input)="validateTin()" maxlength="11" />
                    <div *ngIf="tinError" class="text-danger">
                        <small [ngClass]="fontService.smallClass" jhiTranslate="error.validtin">Please enter a valid TIN
    Number.</small>
                    </div>
                </div>
            </div>
            <div class="row  gy-2 mt-3">
                <div class="col-12">
                    <h6 [ngClass]="fontService.headingClass" jhiTranslate="agency.account.detail.name">
                        Verification Details
                    </h6>
                </div>

                <div class="col-sm-6 col-md-4">
                    <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.ifscode" for=" ifsc">IFSC Code</label>
                    <input type="text" class="form-control" name="ifsc" autocomplete="off" maxlength="11" oninput="this.value = this.value.replace(/[^A-Za-z0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();" uppercase
                        [readonly]="readonly" [(ngModel)]="stautoryAccount.bankIfscCode" id="ifsc" [disabled]="viewOnly" [ngClass]="{ 'is-invalid': ngSelectInvalid && !stautoryAccount.bankIfscCode }" maxlength="11" (change)="accountDetailsChanged()" />
                    <div *ngIf="ifscError" class="text-danger">
                        <small [ngClass]="fontService.smallClass" jhiTranslate="error.validifsc">Please enter a valid
    IFSCode.</small>
                    </div>
                </div>
                <div class="col-sm-6 col-md-4">
                    <label class="form-control-label" [ngClass]="fontService.labelClass" jhiTranslate="vendor.bank.name" for=" bankName">Bank Name</label>
                    <p [ngClass]="fontService.regularClass" class="mb-0">{{stautoryAccount.bankName}}</p>
                </div>
                <div class="col-sm-6 col-md-4">
                    <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="agency.branch.name" for=" branchName">Branch Name</label>
                    <p [ngClass]="fontService.regularClass" class="mb-0">{{stautoryAccount.bankBranchName}}</p>
                </div>
                <div class="col-sm-6 col-md-4">
                    <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.accountno" for=" accountNo">Account Number</label>
                    <input type="text" class="form-control" name="accountNo" autocomplete="off" maxlength="25" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();" style="text-transform:uppercase"
                        [readonly]="readonly" [(ngModel)]="stautoryAccount.bankAccountNumber" (change)="accountDetailsChanged()" id="accountNo" [ngClass]="{ 'is-invalid': ngSelectInvalid && !stautoryAccount.bankAccountNumber }" [disabled]="viewOnly" />
                    <div *ngIf="showaccountNoError" class="text-danger">
                        <small [ngClass]="fontService.smallClass" jhiTranslate="error.accountNoUniError">{{ showaccountNoError }}</small>
                    </div>
                </div>
                <div class="col-sm-6 col-md-4">
                    <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.bank.account.name" for=" bankAccountName">Bank Account Name As per
  Passbook</label>
                    <input type="text" class="form-control" name="bankAccountName" autocomplete="off" maxlength="60" oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();"
                        style="text-transform:uppercase" [readonly]="readonly" [(ngModel)]="stautoryAccount.bankAccountName" id="bankAccountName" [disabled]="viewOnly" [ngClass]="{ 'is-invalid': ngSelectInvalid && !stautoryAccount.bankAccountName }" />
                </div>
                <div class="col-12 justify-content-center" *ngIf="currentEnvironment==='uat' || currentEnvironment==='production'">
                    <button pButton pRipple class="p-button-success custom-button" (click)="accountValidation()" styleClass="button" [disabled]="readonly">
<span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.verifyaccount">Verify Account</span>
</button>
                    <div *ngIf="accountInvalidError" class="text-danger">
                        <small [ngClass]="fontService.smallClass">{{"error.accountinvaliderror" | translate}}</small>
                    </div>
                    <div *ngIf="ngSelectInvalid && !verifyClicked" class="text-danger">
                        <small [ngClass]="fontService.smallClass" jhiTranslate="error.verifyaccount"> Please verify the account</small>
                    </div>
                </div>
                <div class="col-12 justify-content-center" *ngIf="currentEnvironment!=='uat' && currentEnvironment!=='production'">
                    <button pButton pRipple class="p-button-success custom-button" (click)="accountValidation()" styleClass="button" [disabled]="readonly || viewOnly">
<span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.verifyaccount">Verify Account</span>
</button>
                    <div *ngIf="accountInvalidError" class="text-danger">
                        <small [ngClass]="fontService.smallClass">{{"error.accountinvaliderror" | translate}}</small>
                    </div>
                    <div *ngIf="ngSelectInvalid && !verifyClicked" class="text-danger">
                        <small [ngClass]="fontService.smallClass" jhiTranslate="error.verifyaccount"> Please verify the account</small>
                    </div>
                </div>
                <div *ngIf="statusButton && currentEnvironment!=='uat' && currentEnvironment!=='production'" class="col-12 justify-content-center">
                    <button pButton pRipple class="p-button-success custom-button" (click)="accountValidationStatus()" styleClass="button" [disabled]="viewOnly">
<span class="button-label" [ngClass]="fontService.buttonClass" >Check Account Status</span>
</button>
                    <div *ngIf="accountInvalidError" class="text-danger">
                        <small [ngClass]="fontService.smallClass">{{"error.accountinvaliderror" | translate}}</small>
                    </div>
                    <div *ngIf="ngSelectInvalid && !verifyClicked" class="text-danger">
                        <small [ngClass]="fontService.smallClass" jhiTranslate="error.verifyaccount"> Please verify the account</small>
                    </div>
                </div>
                <div class="text-end">
                    <button *ngIf="!stautoryAccount.id && !viewOnly" pButton pRipple icon="pi pi-check" class="p-button-text custom-button" (click)="saveData()">
            <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.addbutton"></span>
          </button>
                    <button *ngIf="stautoryAccount.id && !viewOnly" pButton pRipple icon="pi pi-check" class="p-button-text custom-button" (click)="saveData()">
            <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.savebutton"></span>
          </button>
                    <button *ngIf="approveView" pButton pRipple icon="pi pi-check" class="p-button-success custom-button" (click)="approve()">
            <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.approvebutton"></span>
          </button>
                    <button *ngIf="approveView" pButton pRipple icon="pi pi-times" class="p-button-danger custom-button" (click)="reasonDialog=true">
          <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.rejectbutton"></span>
        </button>
                </div>
            </div>

        </ng-template>
    </p-dialog>
    <p-dialog [(visible)]="reasonDialog" [style]="{ width: '500px', height: '350px' }" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog">
        <p-header [ngClass]="fontService.headingClass">
            Reject Dialog:
        </p-header>
        <div class="form-group mb-4">
            <label class="form-control-label req" for="reason" [ngClass]="fontService.labelClass">Reject Reason</label><br />
            <textarea id="reason" name="reason" rows="3" [(ngModel)]="rejectionReason" cols="100" maxlength="150" oninput="this.value = this.value.replace(/[^A-Za-z0-9 ,-.]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();"></textarea>
            <div *ngIf="requiredError" class="mt-2">
                <small class="text-danger">please mention the reason for reject</small>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <button pButton pRipple class="p-button-danger">
      <span
        class="button-label"
        [ngClass]="fontService.buttonClass"
        label="Reject"
        (click)="rejectDialog()"
        >Reject</span
      >
    </button>
        </ng-template>

    </p-dialog>
</div>
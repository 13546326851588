import { Component, OnInit } from '@angular/core';
import { FontService } from 'src/app/shared/font-service/font.service';
import { Deduction } from './deductions.model';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { DeductionsService } from './deductions.service';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { SearchResult } from '../shared/models/search-result';

@Component({
  selector: 'app-deductions',
  templateUrl: './deductions.component.html',
  styleUrls: ['./deductions.component.scss']
})
export class DeductionsComponent implements OnInit {
  deductionData:Deduction;
  paymentMethod='Work';
  cols:any;
  date: Date;
  searchResult: SearchResult<Deduction> = new SearchResult<Deduction>();
  itemsPerPage: any = 10;
  page: number;
  filterProps: any;
  startDate:string;
  endDate:string;
  isSearch: boolean;
  
  constructor(public fontService:FontService,
    public deductionsService:DeductionsService,
    private spinner: NgxSpinnerService,
    private datePipe: DatePipe,
    ) { }

  ngOnInit(): void {
    this.cols = [
      {
        field: 'districtName',
        header: 'District',
        isSelectcolumn: true,
        isFilterable: false
      },
      {
        field: 'voucherNumber',
        header: 'Voucher No',
        isFilterable: false,
        isSelectcolumn: true,
        jhiTranslate: 'tableHead.voucher.no'
      },
      {
        field: 'workId',
        header: 'Work Id',
        isFilterable: false,
        isSelectcolumn: true,
      },
      {
        field: 'workName',
        header: 'Work Name',
        isFilterable: false,
        isSelectcolumn: true,
      },
      {
        field: 'billAmount',
        header: 'Bill Value',
        isFilterable: false,
        isSelectcolumn: true,
      },
      {
        field: 'grossAmount',
        header: 'Gross Amount',
        isFilterable: false,
        isSelectcolumn: true,
      },
      {
        field: 'paymentType',
        header: 'Payment Type',
        isFilterable: false,
        isSelectcolumn: false,
      },
      {
        field: 'partCount',
        header: 'Part Count',
        isFilterable: false,
        isSelectcolumn: false,
      },
      {
        field: 'vendorName',
        header: 'Vendor Name',
        isFilterable: false,
        isSelectcolumn: true,
      },
      {
        field: 'vendorGSTNumber',
        header: 'Vendor GST Number',
        isFilterable: false,
        isSelectcolumn: true,
      },
      {
        field: 'vendorPANNumber',
        header: 'Vendor PAN Number',
        isFilterable: false,
        isSelectcolumn: true,
      },
      {
        field: 'withHeldAmount',
        header: 'WithHeld Amount',
        isFilterable: false,
        isSelectcolumn: true,
      },
      {
        field: 'cummulativeWithHeldAmount',
        header: 'Cummulative WithHeld Amount',
        isFilterable: false,
        isSelectcolumn: true,
      },
      {
        field: 'materialSupplyAmount',
        header: 'Material Supply Amount',
        isFilterable: false,
        isSelectcolumn: true,
      },
      {
        field: 'miscellaneousAmount',
        header: 'Miscellaneous Deduction Amount',
        isFilterable: false,
        isSelectcolumn: true,
      },
      {
        field: 'miscellaneousAdditionAmount',
        header: 'Miscellaneous Addition Amount',
        isFilterable: false,
        isSelectcolumn: true,
      },
      {
        field: 'tdsAmount',
        header: 'TDS Amount (IT)',
        isFilterable: false,
        isSelectcolumn: false,
      },
      {
        field: 'gstAmount',
        header: 'TDS on GST Amount',
        isFilterable: false,
        isSelectcolumn: false,
      },
      {
        field: 'lwfAmount',
        header: 'LWF Amount',
        isFilterable: false,
        isSelectcolumn: false,
      }
    ];
    }

    onSearch(){
      this.spinner.show();
      this.isSearch=true;
      let month=this.date.getMonth();
      let year=this.date.getFullYear();
      const lastDay = new Date(year, month+1, 0).getDate();
      this.startDate=this.datePipe.transform(new Date(year, month, 1), 'yyyy-MM-dd HH:mm:ss.SSS');
      this.endDate=this.datePipe.transform(new Date(year, month+1, 0, 23, 59, 59, 999), 'yyyy-MM-dd HH:mm:ss.SSS');
      this.loadPage(1);
    }

    loadPage(page?: number, dontNavigate?: boolean): void {
      this.spinner.show();
      const pageToLoad: number = page ?? this.page ?? 1;
  
      let query = {
        page: pageToLoad - 1,
        size: this.itemsPerPage,
      };
   
      this.deductionsService.getDeductionList({ ...query },this.paymentMethod,this.startDate,this.endDate).subscribe(
        (res: HttpResponse<Deduction[]>) => {
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        },
        () => {
          this.onError();
          this.spinner.hide();
        }
      );
    }
  
  
    protected onSuccess(
      data: Deduction[] | null,
      headers: HttpHeaders,
      page: number,
      navigate: boolean
    ): void {
      this.searchResult.total = Number(headers.get('X-Total-Count'));
      this.spinner.hide();
      this.page = page;
      this.searchResult.items = data ?? [];
      this.searchResult = { ...this.searchResult };
    }
  
    protected onError(): void {}
  
    onLazyLoad(event) {
      if(!this.isSearch){
        let date=new Date();
        let month=date.getMonth();
        let year=date.getFullYear();
        const lastDay = new Date(year, month+1, 0).getDate();
        this.startDate=this.datePipe.transform(new Date(year, month, 1), 'yyyy-MM-dd HH:mm:ss.SSS');
      this.endDate=this.datePipe.transform(new Date(year, month+1, 0, 23, 59, 59, 999), 'yyyy-MM-dd HH:mm:ss.SSS');
      this.date=new Date(year,month, 1);
      }
      this.itemsPerPage = event.size;
      this.loadPage(event.page);
    }
}
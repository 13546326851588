<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <p-toolbar styleClass="toolbar">
        <ng-template pTemplate="left">
          <h5 [ngClass]="fontService.headingClass" jhiTranslate="funds.funds.reciept.list.name">Funds Sanction List
          </h5>
        </ng-template>
        <ng-template pTemplate="right">
          <button pButton pRipple icon="pi pi-plus" class="p-button-secondary custom-button  new-button" (click)="openNew()"
            styleClass="button">
            <span class="button-label" [ngClass]="fontService.buttonClass"
              jhiTranslate="common.newbutton"></span></button>
        </ng-template>
      </p-toolbar>
    </div>
  </div>
  <div class="row">
    <div #customerTable id="entities" class="col-12 px-4">
      <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" (searchParam)="onLazyLoad($event)"
        (emitRow)="onSelectRow($event)" [enableViewIcon]="true" [disableDeleteIcon]="true" [disableEditIcon]="true"></custom-filter-table>
    </div>
  </div>
  <p-dialog #dialog [(visible)]="fundsTransactionDialog" [modal]="true" (onHide)="hideDialog()" styleClass="ib-dialog"
    [style]="{ width: '850px' }">
    <p-header [ngClass]="fontService.headingClass">
      {{"funds.add.funds.reciept.name" | translate}}
    </p-header>
    <ng-template pTemplate="content">
      <div class="col-12">
        <div class="form-inner-panel">
          <div class="row gy-2">
            <div class="col-12">
              <h6 [ngClass]="fontService.headingClass" jhiTranslate="funds.department.details.name">Department Details
              </h6>
            </div>
            <div class="col-sm-6 col-md-4">
              <label class="form-control-label req" for="department" [ngClass]="fontService.labelClass"
                jhiTranslate="funds.department.name">Department</label>
              <ng-select [appendTo]="'.p-dialog'" id="department" bindLabel="name" appearance="outline" [clearable]="true"
                [closeOnSelect]="true" class="form-control" [items]="departmentList"
                [(ngModel)]="fundsTransaction.departmentDto" [disabled]="viewOnly"
                [ngClass]="{ 'is-invalid': ngSelectInvalid && !fundsTransaction.departmentDto }">
              </ng-select>
            </div>
          </div>
          <div class="row gy-2 mt-3">
            <div class="col-12">
              <h6 [ngClass]="fontService.headingClass" jhiTranslate="funds.fund.transaction.details.name">Fund
                Transaction Details</h6>
            </div>
            <div class="col-sm-6 col-md-4">
              <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass"
                jhiTranslate="funds.scheme.name">Scheme</label>
              <ng-select [appendTo]="'.p-dialog'" id="scheme" [(ngModel)]="fundsTransaction.schemeDto" [items]="schemeList" bindLabel="name"
                appearance="outline" [closeOnSelect]="true" class="form-control" [clearable]="true" [disabled]="viewOnly"
                [ngClass]="{ 'is-invalid': ngSelectInvalid && !fundsTransaction.schemeDto }">
              </ng-select>
            </div>
            <div class="col-sm-6 col-md-4">
              <label class="form-control-label req" for="date" [ngClass]="fontService.labelClass"
                jhiTranslate="funds.select.fund.source.name">Fund Source</label>
              <ng-select [appendTo]="'.p-dialog'" id="scheme" [(ngModel)]="fundsTransaction.fundSource" [items]="fundSourceList" bindLabel="name"
                appearance="outline" [closeOnSelect]="true" class="form-control" [clearable]="true" [disabled]="viewOnly"
                [ngClass]="{ 'is-invalid': ngSelectInvalid && !fundsTransaction.fundSource }">
              </ng-select>
            </div>
            <div class="col-sm-6 col-md-4">
              <label class="form-control-label req" for="date" [ngClass]="fontService.labelClass"
                jhiTranslate="funds.fin.year.name">Fin Year</label>
              <ng-select [appendTo]="'.p-dialog'" id="gender" bindLabel="name" [items]="finYear" appearance="outline" [disabled]="viewOnly"
                [(ngModel)]="fundsTransaction.finYearDto" (change)="onFinYearChange()" [closeOnSelect]="true" [clearable]="true"
                class="form-control" [ngClass]="{ 'is-invalid': ngSelectInvalid && !fundsTransaction.finYearDto }">
              </ng-select>
            </div>
            <div>
              <p class="mt-2" *ngIf="noFundError" style="font-size: 14px; color: #ff0000">
                {{noFundError}}
              </p>
            </div>
            <div class="col-sm-6 col-md-4">
              <label class="form-control-label" for="totalBudget"
              [ngClass]="fontService.labelClass">Total Budget Amount</label>
              <input type="text" id="totalBudget" name="totalBudget" autocomplete="off"
                maxlength="25" class="form-control" [(ngModel)]="totalBudget" [disabled]="true"/>
              </div>
            <div class="col-sm-6 col-md-4">
              <label class="form-control-label" for="allocatedBudget" 
              [ngClass]="fontService.labelClass">Amount Received So Far</label>
              <input
                type="text" id="allocatedBudget" name="allocatedBudget" autocomplete="off"
                maxlength="25" class="form-control" [(ngModel)]="allocatedBudget" [disabled]="true"/>
            </div>
            <div class="col-sm-6 col-md-4">
              <label class="form-control-label" for="balanceBudget" 
              [ngClass]="fontService.labelClass">Balance To Be Received</label>
              <input
                type="text" id="balanceBudget" name="balanceBudget" autocomplete="off"
                maxlength="25" class="form-control" [(ngModel)]="balanceBudget" [disabled]="true"/>
            </div>
            <div class="col-sm-6 col-md-4">
              <label class="form-control-label req" for="openingBalance"
              [ngClass]="fontService.labelClass" jhiTranslate="funds.amount.received.name">Amount Received</label
              >
              <input type="text" id="openingBalance" name="openingBalance" autocomplete="off"
                oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                maxlength="25" [disabled]="viewOnly" class="form-control"                
                [(ngModel)]="fundsTransaction.sanctionAmount" (blur)="checkLimit()"
                [ngClass]="{ 'is-invalid': ngSelectInvalid && !fundsTransaction.sanctionAmount }"
              />
            </div>
            <div class="col-sm-6 col-md-4">
              <label class="form-control-label req" for="date" [ngClass]="fontService.labelClass"
                jhiTranslate="funds.date.of.transaction.name">Date of Transaction</label>
              <p-calendar styleClass="datepicker" class="same_line_fix" [(ngModel)]="fundsTransaction.transactionDate"
                [ngClass]="{ 'is-invalid': ngSelectInvalid && !fundsTransaction.transactionDate }"
                appendTo="body" [disabled]="viewOnly"></p-calendar>
            </div>
            <div class="col-sm-6 col-md-4">
              <label class="form-control-label req" for="openingBalance"
              [ngClass]="fontService.labelClass" jhiTranslate="funds.reference.no.name">Reference No</label
              >
              <input type="text" class="form-control"
                oninput="this.value = this.value.replace(/[^A-Za-z0-9-_/]/g, '').replace(/(\..*)\./g, '$1');"
                maxlength="15" [(ngModel)]="fundsTransaction.transactionRefNo"
                name="openingBalance" autocomplete="off" id="openingBalance" [disabled]="viewOnly"
                [ngClass]="{ 'is-invalid': ngSelectInvalid && !fundsTransaction.transactionRefNo }"
                (ngModelChange)="checkRefNo()" />
                <div *ngIf="transactionRefNoError" class="text-danger">
                  <small [ngClass]="fontService.smallClass" jhiTranslate="error.transactionRefNoError" >{{transactionRefNoError}}</small>
                </div>
            </div>
            <div class="col-sm-6 col-md-4">
              <label class="form-control-label" for="openingBalance"
              [ngClass]="fontService.labelClass" jhiTranslate="funds.go.no.for.the.transaction">
              Go no. for the transaction</label
              >
              <input type="text" class="form-control" oninput="this.value = this.value.replace(/[^A-Za-z0-9-_]/g, '').replace(/(\..*)\./g, '$1');"
                maxlength="15" [(ngModel)]="fundsTransaction.sanctionGoNo" name="openingBalance" autocomplete="off"
                id="openingBalance" [disabled]="viewOnly"/>
            </div>
            <div class="col-md-12">
              <label class="form-control-label req" for="description" [ngClass]="fontService.labelClass"
                jhiTranslate="funds.description">Description</label>
                <textarea id="description" name="description" rows="2" [(ngModel)]="fundsTransaction.description" [disabled]="viewOnly"
                oninput="this.value = this.value.replace(/[^A-Za-z0-9 ,-.]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                onblur="this.value =this.value.trim();" cols="100" [ngClass]="{'is-invalid':ngSelectInvalid && !fundsTransaction.description}"></textarea>
            </div>
          </div>
          <div class="row mt-3">
            <label class="form-control-label" for="upload" [ngClass]="fontService.labelClass">File Upload</label><br>
            <div class="col-md-12">
              <small [ngClass]="fontService.regularClass" jhiTranslate="funds.note">Note : Allowed file types are jpg, jpeg, png, pdf and
                Maximum file size should be 1MB</small>
            </div>            
            <div class="col-md-6">
              <p-fileUpload *ngIf="!isFileTNUploaded" (onUpload)="onUploadfile2($event)" [customUpload]="true"
                auto="true" (uploadHandler)="onUploadfile2($event)" multiple="false"
                [showUploadButton]="false" [disabled]="isFileTNUploaded || viewOnly"
                [showCancelButton]="false"
                chooseLabel="{{'common.upload'|translate}}">
              </p-fileUpload>
              <div class="row mt-2" *ngIf="!isFileTNUploaded && ngSelectInvalid">
                <small class="text-danger">Upload the File</small>
              </div>
              <div class="row mt-2" *ngIf="isFileTNUploaded">
                <div class="col">
                  <a  class="cursor-pointer" (click)="viewDocument()">{{fundsTransaction.fileNameTwo}}</a>
                  <i *ngIf="!viewOnly"  class="pi pi-times add-margin" (click)="removeDocument()"></i>
                </div>
              </div>
            </div>
         </div>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="footer">
      <button pButton pRipple icon="pi pi-times" class="p-button-text custom-button" (click)="hideDialog()"><span
          class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.cancelbutton"></span></button>
      <button *ngIf="!viewOnly" pButton pRipple icon="pi pi-check" class="p-button-text custom-button" [disabled]="noFundError" (click)="saveData()"><span
          class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.savebutton"></span></button>
    </ng-template>
  </p-dialog>
  <p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
  <p-dialog
  [(visible)]="viewDoc"
  [style]="{ width: '1000px', height: '800px' }"
  [modal]="true"
  [baseZIndex]="1000"
  styleClass="ib-dialog"
>
  <ng-template pTemplate="body">
  <div style='position: relative; height: 100%;'>
    <ngx-extended-pdf-viewer *ngIf="vFileExt === 'pdf'" [src]="vFileUrl"></ngx-extended-pdf-viewer>
    <img *ngIf="vFileExt !== 'pdf'"  [src]="vFileUrl" style="display: block;" height="800px" />
  </div>
</ng-template>
</p-dialog>
</div>
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { LevelMasterService } from './level-master.service';
import { LevelMaster, LevelMasterDto } from './level-master';
import { SearchResult } from 'src/app/shared/models/search-result';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { LevelTypeService } from '../level-type/level-type.service';
import { DepartmentListService } from '../department-list/department-list.service';
import { SchemeListService } from '../scheme-list/scheme-list.service';
import { DepartmentList } from '../department-list/department-list';
import { SchemeList } from '../scheme-list/scheme-list';
import { LevelType } from '../level-type/level-type';
import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { Dialog } from 'primeng/dialog';
import { NotificationService } from 'src/app/alert/notification.service';

@Component({
  selector: 'app-level-master',
  templateUrl: './level-master.component.html',
  styleUrls: ['./level-master.component.scss'],
})
export class LevelMasterComponent implements OnInit, OnInit, AfterViewInit {

  @ViewChild('dialog') dialog: Dialog;
  levelMasterDialog: boolean = false;
  cols: any[];
  filterProps: any;
  searchResult: SearchResult<LevelMaster> = new SearchResult<LevelMaster>();
  levelMaster?: LevelMasterDto;
  selectedLimitSet: LevelMaster[] = [];
  submitted?: boolean;
  selectedMapping: string;
  itemsPerPage: number = 10;
  page: number;
  departmentList: DepartmentList[];
  schemeList: SchemeList[];
  levelType: LevelType[];
  departmentDto: any;
  schemeDto: any;
  stateList: LevelMasterDto[];
  stateListDto: LevelMasterDto;
  districtLevelList: LevelMasterDto[];
  districtListDto: LevelMasterDto;
  blockLevelList: LevelMasterDto[];
  BlockLevelDto: LevelMasterDto;
  tamilText: any;

  //required fields property
  ngSelectInvalid: boolean = false;
  codeError: string;

  constructor(
    private levelMasterService: LevelMasterService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private schemeListService: SchemeListService,
    private levelTypeService: LevelTypeService,
    private departmentListService: DepartmentListService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService
  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.updateDialogFont();
      this.changeLangLoadHeader()
    });
  }

  ngOnInit() {
    this.loadPage(1);
    this.changeLangLoadHeader()
  }

  changeLangLoadHeader(){
    const lang = this.translateService.currentLang;
    if(lang === 'en') {
      this.cols = [
        {
          field: 'levelZeroCode',
          header: 'State Code',
          jhiTranslate: 'tableHead.state.code.name',
          isFilterable: true,
          width: 5,
          isSelectcolumn: true,
        },
        {
          field: 'levelOneCode',
          header: 'District Code',
          jhiTranslate: 'tableHead.district.code.name',
          isFilterable: true,
          width: 25,
          isSelectcolumn: true,
        },
        {
          field: 'levelOneName',
          header: 'District',
          jhiTranslate: 'tableHead.district.name',
          isFilterable: true,
          width: 15,
          isSelectcolumn: true,
        },
        {
          field: 'levelTwoCode',
          header: 'Block Code',
          jhiTranslate: 'tableHead.block.code.name',
          isFilterable: true,
          width: 25,
          isSelectcolumn: true,
        },
        {
          field: 'levelTwoName',
          header: 'Block',
          jhiTranslate: 'tableHead.block.name',
          isFilterable: true,
          width: 25,
          isSelectcolumn: true,
        },
        
        {
          field: 'levelThreeCode',
          header: 'Village Code',
          jhiTranslate: 'tableHead.village.code.name',
          isFilterable: true,
          width: 25,
          isSelectcolumn: true,
        },
        {
          field: 'levelThreeName',
          header: 'Village',
          jhiTranslate: 'tableHead.village.name',
          isFilterable: true,
          width: 25,
          isSelectcolumn: true,
        },
        
      ];
    }
    else{
      this.cols = [
        {
          field: 'levelZeroCode',
          header: 'State Code',          
          jhiTranslate: 'tableHead.state.code.name',
          isFilterable: true,
          width: 5,
          isSelectcolumn: true,
        },
        {
          field: 'levelOneCode',
          header: 'District Code',
          jhiTranslate: 'tableHead.district.code.name',
          isFilterable: true,
          width: 25,
          isSelectcolumn: true,
        },
        {
          field: 'levelOneNameTn',
          header: 'District TN',
          jhiTranslate: 'tableHead.district.name',
          isFilterable: true,
          width: 15,
          isSelectcolumn: true,
        },
        {
          field: 'levelTwoCode',
          header: 'Block Code',
          jhiTranslate: 'tableHead.block.code.name',
          isFilterable: true,
          width: 25,
          isSelectcolumn: true,
        },
        {
          field: 'levelTwoNameTn',
          header: 'Block TN',
          jhiTranslate: 'tableHead.block.name',
          isFilterable: true,
          width: 15,
          isSelectcolumn: true,
        },
        {
          field: 'levelThreeCode',
          header: 'Village Code',
          jhiTranslate: 'tableHead.village.code.name',
          isFilterable: true,
          width: 25,
          isSelectcolumn: true,
        },
        {
          field: 'levelThreeNameTn',
          header: 'Village TN',
          jhiTranslate: 'tableHead.village.name',
          isSortable: false,
          isFilterable: true,
          width: 15,
          isSelectcolumn: true,
        },
      ];
    }

  }

  ngAfterViewInit() {
    this.updateDialogFont();
    
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }

  openNew() {
    this.submitted = false;
    this.levelMasterDialog = true;
    this.levelMaster = new LevelMasterDto();
    this.loadValue();
  }

  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;

    let filterQuery = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    this.levelMasterService
      .filter({ ...filterQuery }, this.filterProps)
      .subscribe(
        (res: HttpResponse<LevelMaster[]>) => {
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        },
        () => {
          this.onError();
        }
      );
  }

  protected onSuccess(
    data: LevelMaster[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;
    if (navigate) {
      this.router.navigate(['/level-master'], {
        queryParams: {
          page: this.page - 1,
          size: this.itemsPerPage,
        },
      });
    }
    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void { }

  hideDialog() {

    this.levelMaster = new LevelMasterDto();
    this.departmentDto = null;
    this.schemeDto = null;
    this.levelType=null;
    this.stateList=null;
    this.BlockLevelDto = new LevelMasterDto()
    this.districtListDto = new LevelMasterDto()
    this.stateListDto = new LevelMasterDto()
    this.stateListDto=null;
    this.districtListDto=null;
    this.districtLevelList=null;
    this.BlockLevelDto=null;
    this.ngSelectInvalid = false;
    this.levelMasterDialog = false;
    this.submitted = false;
  }

  saveData() {
    const isFormValid =
      this.departmentDto &&
      this.schemeDto &&
      this.levelMaster.levelTypeDto &&
      this.stateListDto &&
      this.levelMaster.name &&
      this.levelMaster.code

    if (!isFormValid) {
      this.ngSelectInvalid = true;
      return;
    }
    this.ngSelectInvalid = false;
    if (this.levelMaster.levelTypeDto.name === "State") {
      this.levelMaster.parentId = 0
    }
    else if (this.levelMaster.levelTypeDto.name === "District") {
      this.levelMaster.parentId = this.stateListDto.id
    }
    else if (this.levelMaster.levelTypeDto.name === "Block") {
      this.levelMaster.parentId = this.districtListDto.id
    }
    else if (this.levelMaster.levelTypeDto.name === "Village") {
      this.levelMaster.parentId = this.BlockLevelDto.id
    }
    this.levelMasterService
      .save(this.levelMaster)
      .subscribe(
        (res: HttpResponse<any>) => {
          this.levelMasterDialog = false;
          this.loadPage(this.page);
          this.notificationService.alertSuccess('Saved Successfully', '');
        },
        () => {
          this.onError();
          this.notificationService.alertError('Error!', '');
        }
      );
  }

  onLazyLoad(event) {
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

  checkCodeNo() {
    let filterQuery = {
      page: 0,
      size: 10,
    };
    const filter = [
      {
        key: 'code',
        operation: 'equals',
        value: this.levelMaster.code,
      },
    ];
    if (this.levelMaster.id) {
      filter.push({
        key: 'id',
        operation: 'notEquals',
        value: String(this.levelMaster.id),
      });
    }

    this.levelMasterService.LevelMasterDtoFilter(
      { ...filterQuery },
      filter
    ).subscribe(
      (res: HttpResponse<LevelMaster[]>) => {
        if (res.body.length != 0) {
          this.codeError = 'LGD Code Already Exists';
        } else {
          this.codeError = null;
        }
      },
      () => {
        this.onError();
      }
    );
  }

  loadValue() {
    this.departmentListService.filter().subscribe(
      (res: HttpResponse<DepartmentList[]>) => {
        this.departmentList = res.body;
      },
      () => { }
    );
    this.schemeListService.filter().subscribe(
      (res: HttpResponse<SchemeList[]>) => {
        this.schemeList = res.body;
      },
      () => { }
    );
  }

  onchangeSchema() {
    this.levelTypeService.filter().subscribe(
      (res: HttpResponse<LevelType[]>) => {
        this.levelType = res.body;
      },
      () => { }
    );
  }

  onLevelType() {
    if (this.levelMaster.levelTypeDto.name !== 'State') {
      let filterQuery = {
        page: 0,
        size: 10,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'id',
            operation: 'equals',
            value: 1,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.stateList = res.body;
          },
          () => { }
        );
    }
  }
  onStateLevelChange($event?) {
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 10,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.stateListDto.id,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;
          },
          () => { }
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 10,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.stateListDto.id,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;
          },
          () => { }
        );
    }
  }

  onDistrictLevelChange($event?) {    
    this.BlockLevelDto=null; 
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 10,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.districtListDto.id,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.blockLevelList = res.body;
          },
          () => { }
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 10,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.districtListDto.id,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.blockLevelList = res.body;
          },
          () => { }
        );
    }
  }
}

import { LevelMasterDto } from './../level-master/level-master';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { SearchResult } from 'src/app/shared/models/search-result';
import { LevelMasterService } from '../level-master/level-master.service';
import { SchemeListService } from '../scheme-list/scheme-list.service';
import { SchemeList } from '../scheme-list/scheme-list';
import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { Dialog } from 'primeng/dialog';
import { NotificationService } from 'src/app/alert/notification.service';
import { FinYear, lbcdto } from './lbc.model';
import { LbcService } from './lbc.service';
import { LimitTransListService } from '../limit-translist/limit-translist.service';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { filterQuery } from 'src/app/shared/common.model';
import { UserRoleService } from 'src/app/shared/service/user-role.service';
import { userRoles } from 'src/app/shared/models/user-role-code';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-local-body-contribution',
  templateUrl: './local-body-contribution.component.html',
  styleUrls: ['./local-body-contribution.component.scss']
})
export class LocalBodyContributionComponent implements OnInit {
  @ViewChild('dialog') dialog: Dialog;
  itemsPerPage: any = 10;
  page: number;
  filterProps: any;
  searchResult: SearchResult<lbcdto> =
    new SearchResult<lbcdto>();
  cols: any;
  lbcPaymentDialog: boolean;
  levelMasterList: any;
  agency: any;
  ComponentType: any;
  finYear: FinYear[];
  schemeList: SchemeList[];
  schemeDto: any;
  lbcdto?:lbcdto;
  lbcDetailsDto?:any;
  villageLevelList: LevelMasterDto[];
  villagLevelMasterDto: any;
  //required fields property
  ngSelectInvalid: boolean = false;
  user: any;
  paymentDue: number;
  noLbc: boolean;
  blockMasterDto: any;
  blockLevelList: LevelMasterDto[];
  districtMasterDto: any;
  districtLevelList: LevelMasterDto[];
  userContextSubscription: Subscription;
  disableAdd: boolean;
  userRoles=userRoles;

  constructor(
    private spinner: NgxSpinnerService,
    private levelMasterService: LevelMasterService,
    private schemeListService: SchemeListService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,
    private lbcService:LbcService,
    private limitTransListService:LimitTransListService,
    private loginService:SigninService,
    private userRoleService:UserRoleService

  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.updateDialogFont();
    });
  }

  ngOnInit(): void {
    this.userContextSubscription=this.userRoleService.getuserContext().subscribe(
      (res)=>{
        this.user = res;
        if (res.role.code==userRoles.roleState) {
          this.disableAdd=true;
          }
      },
      (onError) => {}
    );
    this.cols = [
      {
        field: 'villagLevelMaster',
        header: 'Village Name',
        width: 13,
        isSelectcolumn: true,
        type: 'dropDown',
        isFilterable: true,
      },
      {
        field: 'lbcTransactionAmount',
        header: 'LBC Paid Amount',
        width: 25,
        isSelectcolumn: true,
        type:'amount'
      },
      {
        field: 'transactionDate',
        header: 'Transaction Date',
        jhiTranslate: 'tableHead.transaction.date',
        width: 13,
        type:'date',
        isSelectcolumn: true,
        isFilterable: false,
      },
    ];
    this.loadPage(1);
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }

  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;

    let query = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    this.filterProps = this.filterProps ?? [];
    this.filterProps = this.filterProps ? this.filterProps : [];
    let filquery = new filterQuery();
    filquery.key = 'activeFlag';
    filquery.operation = 'equals';
    filquery.value = 'YES';
    this.filterProps.push(filquery);
    this.lbcService.filter({ ...query }, this.filterProps)
      .subscribe(
        (res: HttpResponse<lbcdto[]>) => {
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
          this.spinner.hide();
        },
        () => {
          this.spinner.hide();
          this.onError();
        }
      );
  }
  protected onSuccess(
    data: lbcdto[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;

    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void { }

  onLazyLoad(event) {
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

  onDistrictChange($event?){
    this.blockMasterDto = null;
    let filterQuery = {
      page: 0,
      size: 20,
    };
    this.levelMasterService
      .LevelMasterDtoFilter({ ...filterQuery }, [
        {
          key: 'parentId',
          operation: 'equals',
          value: this.districtMasterDto.id
        },
      ])
      .subscribe(
        (res: HttpResponse<LevelMasterDto[]>) => {
          this.blockLevelList = res.body;
        },
        () => {}
      );
  }

  onBlockChange($event?){
    this.villagLevelMasterDto = null;
    let filterQuery = {
      page: 0,
      size: 20,
    };
    this.levelMasterService
      .LevelMasterDtoFilter({ ...filterQuery }, [
        {
          key: 'parentId',
          operation: 'equals',
          value: this.blockMasterDto.id
        },
      ])
      .subscribe(
        (res: HttpResponse<LevelMasterDto[]>) => {
          this.villageLevelList = res.body;
        },
        () => {}
      );
  }

  // loadVillage($event?) {  
  //     this.villagLevelMasterDto = null;
  //     let payload={
  //       levelMasterId: 12,
  //       schemeId: this.lbcdto.schemeDto.id,
  //       finYearId: this.lbcdto.finYearDto.id,
  //     }
  //     if ($event) {
  //       this.levelMasterService
  //         .villageListByScheme(payload)
  //         .subscribe(
  //           (res: HttpResponse<LevelMasterDto[]>) => {
  //             this.villageLevelList = res.body;
  //           },
  //           () => {}
  //         );
  //     } else {
  //       this.levelMasterService
  //         .villageListByScheme(payload)
  //         .subscribe(
  //           (res: HttpResponse<LevelMasterDto[]>) => {
  //             this.villageLevelList = res.body;
  //           },
  //           () => {}
  //         );
  //     }
  //   }

  async openNew() {
    this.lbcPaymentDialog = true;
    this.lbcdto = new lbcdto();
    this.loadValue();
  }

  loadValue() {
    this.schemeListService.filter().subscribe(
      (res: HttpResponse<SchemeList[]>) => {
        this.schemeList = res.body;
      },
      () => {
        this.onError();
      }
    );
    this.limitTransListService.getFinYear().subscribe(
      (res: HttpResponse<FinYear[]>) => {
        this.finYear = res.body;
      },
      () => { }
    );
  }

  onchangeFinYear($event?) {
    this.districtMasterDto = null;
    let filterQuery = {
      page: 0,
      size: 40,
    };
    this.levelMasterService
      .LevelMasterDtoFilter({ ...filterQuery }, [
        {
          key: 'parentId',
          operation: 'equals',
          value: 1
        },
      ])
      .subscribe(
        (res: HttpResponse<LevelMasterDto[]>) => {
          this.districtLevelList = res.body;
        },
        () => {}
      );
  }

  getAmount(){
    this.noLbc=false;
    this.limitTransListService.getAmountAndLBC({
      levelMasterId: this.lbcdto.villagLevelMasterDto.id,
      schemeId: this.lbcdto.schemeDto.id,
      finYearId: this.lbcdto.finYearDto.id,
    })
    .subscribe(
      (res: HttpResponse<lbcdto>) => {
        if(!res.body){
          this.notificationService.alertWarn("Contribution amount is not opted for the chosen village",'');
          this.noLbc=true;
        }        
        this.lbcDetailsDto=res.body;
      },
      () => {}
    );
  }


  hideDialog() {
    this.lbcdto=new lbcdto();
    this.ngSelectInvalid = false;
    this.lbcPaymentDialog = false;
  }

  

  checkLimit(){
    if(this.lbcDetailsDto.lbcFlag=='Yes'){
      this.paymentDue=this.lbcDetailsDto.lbcAmount-this.lbcDetailsDto.eligibleLbcAmount
      if(this.lbcdto.lbcTransactionAmount>this.paymentDue){
        this.notificationService.alertError("Entered amount is greater than the contribution amount. You have to pay just Rs. : "+this.paymentDue,'');
      }
    }
  }

  saveData() {
    const isFormValid =
      this.lbcdto.schemeDto &&
      this.lbcdto.finYearDto 
    if (!isFormValid) {
      this.ngSelectInvalid = true;
      return;
    }
    this.ngSelectInvalid = false;
    this.lbcdto.blockMasterId=this.blockMasterDto.id;
    this.lbcService
      .save(this.lbcdto)
      .subscribe(
        (res: HttpResponse<any>) => {
          this.lbcPaymentDialog = false;
          this.loadPage(this.page);
          this.notificationService.alertSuccess('Saved Successfully', '');
        },
        () => {
          this.onError();
          this.notificationService.alertError('Error!', '');
        }
      );

  }

  ngOnDestroy() {
    // Unsubscribe to prevent memory leaks
    if (this.userContextSubscription) {
      this.userContextSubscription.unsubscribe();
    }
  }
}


<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass">
                        Bulk Payment Voucher
                    </h5>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="container-fluid pb-3">
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col">
                        <div class="card p-3">
                            <div class="row">
                                <h6 [ngClass]="fontService.headingClass" jhiTranslate="payments.add.voucher.generation.name">
                                    Add Voucher Generation
                                </h6>
                                <div class="col-lg-3 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "payments.voucher.number.name" | translate }}:<br />{{ bulkVoucher?.parentVoucher?.voucherNumber }}
                                    </p>
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "date.name" | translate }}:<br />{{ this.bulkVoucher?.parentVoucher?.transactionDate | date:'dd/MM/yyyy'}}
                                    </p>
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "implementing.agency.name" | translate }}:
                                        <br />{{this.bulkVoucher?.parentVoucher?.implementingAgency .name }}
                                    </p>
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "payments.gst.number.name" | translate }}:<br />{{ this.bulkVoucher?.parentVoucher?.implementingAgency.gstNo }}
                                    </p>
                                </div>
                            </div>
                            <div class="row voucherfont">
                                <div class="col-lg-3 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "payments.department.name" | translate }}:<br />{{ departmentName }}
                                    </p>
                                </div>

                                <div class="col-lg-3 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "payments.scheme.name" | translate }}:<br />{{ schemeName }}
                                    </p>
                                </div>


                                <div class="col-lg-3 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        Level Type:<br />{{ this.bulkVoucher?.parentVoucher?.levelMaster?.levelType?.name }}
                                    </p>
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        Level Master:<br />{{ this.bulkVoucher?.parentVoucher?.levelMaster?.name }}
                                    </p>
                                </div>



                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="card">
                    <div class="row">
                        <div class="col-md-12 mt-2">
                            <p-table *ngIf="!this.bulkVoucher?.parentVoucher?.id" [value]="vendorList" [paginator]="true" [rows]="20" styleClass="p-datatable-gridlines" [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [rowsPerPageOptions]="[10, 25, 50]">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>S.No</th>
                                        <th></th>
                                        <th>Voucher Number</th>
                                        <th *ngIf="paymentMethod === 'Work'">Work Id</th>
                                        <th *ngIf="paymentMethod === 'Work'">Name Of Work</th>
                                        <th>Vendor Name</th>
                                        <th>Vendor Ifsc Code</th>
                                        <th>Vendor Account No</th>
                                        <th>Gross Amount</th>
                                        <th>Transaction Amount</th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-index="rowIndex" let-list>
                                    <tr>
                                        <td>{{index+1}}</td>
                                        <td>
                                            <p-checkbox (onChange)="VoucherCheckStatus(list)" [(ngModel)]="list.isDefault" binary="true"></p-checkbox>
                                        </td>
                                        <td>{{ list.voucherNo }}</td>
                                        <td *ngIf="paymentMethod === 'Work'">{{ list.workId }}</td>
                                        <td *ngIf="paymentMethod === 'Work'">{{ list.nameOfWork}}</td>
                                        <td>{{ list.commercialVendorName}}</td>
                                        <td>{{ list.commercialIfscode}}</td>
                                        <td>{{ list.commercialAccountNo}}</td>
                                        <td>{{ list.netAmount}}</td>
                                        <td>{{list.transactionAmount}}</td>
                                        <td> <button pButton pRipple type="button" label="View" class="p-button" (click)="viewVoucherDetails(list.id)">
                                            </button></td>
                                        <td><button pButton pRipple (click)="revert(list.id)" class="p-button-warning custom-button">
                                                <span class="button-label" [ngClass]="fontService.buttonClass"
                                                    jhiTranslate="common.revertbutton"></span>
                                            </button></td>
                                        <td>
                                            <button pButton pRipple (click)="rejectReason = null;currentvendorId=list.id;reasonDialog=true" class="p-button-danger custom-button">
                                                <span class="button-label" [ngClass]="fontService.buttonClass"
                                                    jhiTranslate="common.rejectbutton"></span>
                                            </button>
                                        </td>

                                    </tr>
                                </ng-template>
                            </p-table>
                            <p-table *ngIf="bulkVoucher?.parentVoucher?.id" [value]="bulkVoucher.parentVoucherBillDescList" [paginator]="true" [rows]="20" styleClass="p-datatable-gridlines" [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [rowsPerPageOptions]="[10, 25, 50]">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>S.No</th>
                                        <th>Voucher Number</th>
                                        <th *ngIf="paymentMethod === 'Work'">Work Id</th>
                                        <th *ngIf="paymentMethod === 'Work'">Name Of Work</th>
                                        <th>Vendor Name</th>
                                        <th>Vendor Ifsc Code</th>
                                        <th>Vendor Account No</th>
                                        <th>Gross Amount</th>
                                        <th>Transaction Amount</th>
                                        <th></th>

                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-index="rowIndex" let-list>
                                    <tr>
                                        <td>{{index+1}}</td>
                                        <td>{{ list.childVoucherNumber }}</td>
                                        <td *ngIf="paymentMethod === 'Work'">{{ list.workId }}</td>
                                        <td *ngIf="paymentMethod === 'Work'">{{ list.workName}}</td>
                                        <td>{{ list.vendorName}}</td>
                                        <td>{{ list.vendorIFSCCode}}</td>
                                        <td>{{ list.vendorAccountNumber}}</td>
                                        <td>{{ list.netAmount}}</td>
                                        <td>{{list.transactionAmount}}</td>
                                        <td> <button pButton pRipple type="button" label="View" class="p-button" (click)="viewVoucherDetails(list.vendorTransactionId)">
                                            </button></td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                    <p-toolbar>
                        <div class="p-toolbar-group-start"></div>
                        <div class="p-toolbar-group-end">
                            <button *ngIf="!enableSignButton && bulkVoucher?.parentVoucherBillDescList?.length !== 0 && !bulkVoucher?.parentVoucher?.signMessage" pButton pRipple class="p-button-success custom-button margin" (click)="genVocher()" styleClass="button">
                                <span class="button-label" [ngClass]="fontService.buttonClass">Approve Bulk
                                    Voucher</span>
                            </button>
                            <button *ngIf="enableSignButton" pButton pRipple class="p-button-success custom-button margin" (click)="submitForm()" styleClass="button">
                                <span class="button-label" [ngClass]="fontService.buttonClass"
                                    >Perform e-Sign District Collector</span>
                            </button>
                        </div>

                    </p-toolbar>
                    <p-toolbar>
                        <div class="p-toolbar-group-start"></div>
                        <div class="p-toolbar-group-end">
                            <button *ngIf="bulkVoucher?.parentVoucher?.signMessage && !enableSignButton" pButton pRipple label="Download Pdf" (click)="getPdf()" class="p-button-success"></button>
                        </div>
                    </p-toolbar>

                </div>
            </div>

        </div>

    </div>


    <p-dialog [(visible)]="paymentSucessDialog" [style]="{ width: '500px', height: '225px' }" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog">
        <p-header [ngClass]="fontService.headingClass">
            Payment Alert Box
        </p-header>
        <h1 style="text-align: center;font-size: 18px;">{{ paymentSucessText }}</h1>
        <ng-template pTemplate="footer">
            <button pButton pRipple class="p-button-text custom-button" (click)="paymentSucessDialog = false">
                <span class="button-label" [ngClass]="fontService.buttonClass" label="Ok" (click)="redirect()">Ok</span>
            </button>
        </ng-template>
    </p-dialog>

    <p-dialog [(visible)]="reasonDialog" [style]="{ width: '500px', height: '350px' }" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog">
        <p-header [ngClass]="fontService.headingClass">
            Reject Dialog:
        </p-header>
        <div class="form-group mb-4">
            <label class="form-control-label req" for="reason" [ngClass]="fontService.labelClass">Reject
                Reason</label><br />
            <textarea id="reason" name="reason" rows="3" [(ngModel)]="rejectReason" cols="100" maxlength="150" oninput="this.value = this.value.replace(/[^A-Za-z0-9 ,-.]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();"></textarea>
            <div *ngIf="requiredError" class="mt-2">
                <small class="text-danger">please mention the reason for reject</small>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <button pButton pRipple class="p-button-danger">
                <span class="button-label" [ngClass]="fontService.buttonClass" label="Reject"
                    (click)="rejectDialog()">Reject</span>
            </button>
        </ng-template>

    </p-dialog>
<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass">Limit Status Report</h5>
                </ng-template>
                <ng-template pTemplate="right">

                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <p-toolbar>
        <div class="p-toolbar-group-start">
            <div class="row px-4 g-2">
                <div class="col-sm">
                    <label class="form-control-label" for="scheme">Fin Year</label>
                    <ng-select id="scheme" bindLabel="name" [(ngModel)]="finYearDto" [items]="finYear" appearance="outline" [closeOnSelect]="true" [clearable]="false" class="form-control">
                    </ng-select>
                </div>
                <div class="col-sm">
                    <label class="form-control-label" for="scheme">District</label>
                    <ng-select id="scheme" bindLabel="name" [(ngModel)]="district" [items]="districtLevelList" appearance="outline" [closeOnSelect]="true" [clearable]="false" class="form-control">
                    </ng-select>
                </div>
                <div class="col-sm">
                    <label class="form-control-label" for="dateOfBirth">From Date</label><br />
                    <p-calendar dateFormat="dd.mm.yy" [(ngModel)]="fromDate" styleClass="datepicker"></p-calendar>
                </div>

                <div class="col-sm">
                    <label class="form-control-label" for="dateOfBirth">To Date</label><br />
                    <p-calendar dateFormat="dd.mm.yy" [(ngModel)]="toDate" styleClass="datepicker"></p-calendar>
                </div>
                <div class="col-sm">
                    <p></p>
                    <button pButton pRipple (click)="changeFinYear()" class="p-button-success align-items-end">
                <span class="button-label">Search</span>
              </button>
                </div>
                <div class="col-sm">
                    <p></p>
                    <button pButton pRipple (click)="fromDate=null;toDate=null;district=null;changeFinYear()" class="p-button-danger align-items-end">
                <span class="button-label">Clear</span>
              </button>
                </div>
            </div>
            <div class="row">

            </div>
        </div>
    </p-toolbar>

    <p-tabView [(activeIndex)]="activeIndex">
        <p-tabPanel header="District">
            <div class="row">
                <p-table [value]="districtlimitList" [tableStyle]="{ 'min-width': '100%' }" [resizableColumns]="true" responsiveLayout="scroll">
                    <ng-template pTemplate="header">
                        <tr>
                            <th [ngClass]="fontService.regularClass" id="fy">District Name</th>
                            <th [ngClass]="fontService.regularClass" id="amount">Allocation Amount</th>
                            <th [ngClass]="fontService.regularClass" id="amount">Authorized Amount</th>
                            <th [ngClass]="fontService.regularClass" id="Samount">Expenditure</th>
                            <th [ngClass]="fontService.regularClass" id="Samount">Balance</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-list>
                        <tr>
                            <td>{{list?.levelMasterName}}</td>
                            <td>
                                <p-button (click)="loadDistrictlimit(list?.levelMasterId,1,'Allocated')" styleClass="p-button-link">{{list.allocatedAmount | currency:'INR':'':'1.2-2':'en-IN'}}</p-button>
                            </td>
                            <td>
                                <p-button (click)="loadDistrictlimit(list?.levelMasterId,1,'Authorized')" styleClass="p-button-link">{{list.authorizedAmount | currency:'INR':'':'1.2-2':'en-IN'}}</p-button>
                            </td>
                            <td>
                                <p-button (click)="loadUlimit(list?.levelMasterId,1,'Utilized')" styleClass="p-button-link">{{list.utilizedAmount | currency:'INR':'':'1.2-2':'en-IN'}}</p-button>
                            </td>
                            <td>
                                {{list.balance | currency:'INR':'':'1.2-2':'en-IN'}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </p-tabPanel>
        <!-- <p-tabPanel header="BLOCK">
            <p-tabView [(activeIndex)]="activeIndexBlock">
                <p-tabPanel header="District">
                    <div class="row">
                        <p-table [value]="districtPaymentList" [tableStyle]="{ 'min-width': '100%' }" [resizableColumns]="true" responsiveLayout="scroll">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th [ngClass]="fontService.regularClass" id="fy">District Name</th>
                                    <th [ngClass]="fontService.regularClass" id="amount">Success Count</th>
                                    <th [ngClass]="fontService.regularClass" id="amount">Failed Count</th>
                                    <th [ngClass]="fontService.regularClass" id="Samount">Reject Count</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-list>
                                <tr>
                                    <td>{{list?.levelMasterName}}</td>
                                    <td>
                                        <p-button [label]="list?.count1" (click)="loadDistrictWiseSuccessVoucher(list?.id)" styleClass="p-button-link"></p-button>
                                    </td>
                                    <td>
                                        <p-button [label]="list?.count2" (click)="loadDistrictWiseFailedVoucher(list?.id)" styleClass="p-button-link"></p-button>
                                    </td>
                                    <td>
                                        <p-button [label]="list?.count3" (click)="loadDistrictWiseRejectVoucher(list?.id)" styleClass="p-button-link"></p-button>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </p-tabPanel>
                <p-tabPanel header="BLOCK">
                    <div class="row">
                        <p-table [value]="districtPaymentList" [tableStyle]="{ 'min-width': '100%' }" [resizableColumns]="true" responsiveLayout="scroll">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th [ngClass]="fontService.regularClass" id="fy">Block Name</th>
                                    <th [ngClass]="fontService.regularClass" id="amount">Success Count</th>
                                    <th [ngClass]="fontService.regularClass" id="amount">Failed Count</th>
                                    <th [ngClass]="fontService.regularClass" id="Samount">Reject Count</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-list>
                                <tr>
                                    <td>{{list?.levelMasterName}}</td>
                                    <td>
                                        <p-button [label]="list?.count1" (click)="loadDistrictWiseSuccessVoucher(list?.id)" styleClass="p-button-link"></p-button>
                                    </td>
                                    <td>
                                        <p-button [label]="list?.count2" (click)="loadDistrictWiseFailedVoucher(list?.id)" styleClass="p-button-link"></p-button>
                                    </td>
                                    <td>
                                        <p-button [label]="list?.count3" (click)="loadDistrictWiseRejectVoucher(list?.id)" styleClass="p-button-link"></p-button>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </p-tabPanel>
            </p-tabView>
        </p-tabPanel> -->
    </p-tabView>

    <p-dialog [(visible)]="VoucherDialog" [style]="{ width: '1000px', height: '3000px' }" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog">
        <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchLimitList" (searchParam)="onLazyLoad($event)" [disableDeleteIcon]="true" [paginator]="true"></custom-filter-table>
    </p-dialog>

    <p-dialog [(visible)]="UtilizedDialog" [style]="{ width: '1000px', height: '3000px' }" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog">
        <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchUtilizedList" (searchParam)="onLazyLoad($event)" [disableDeleteIcon]="true" [paginator]="true"></custom-filter-table>
    </p-dialog>



</div>

import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, ViewChild, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FontService } from 'src/app/shared/font-service/font.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserContext, UserRoleService } from 'src/app/shared/service/user-role.service';
import {DashboardCard, FundTransactions, ProjectSummary, ProjectSummaryByLevel} from './dashboard';
import { DashboardService } from './dashboard.service';
import { FundsService } from 'src/app/module/funds/funds.service';
import { Funds } from 'src/app/module/funds/funds';
import enIN from '@angular/common/locales/en-IN';
import {registerLocaleData } from '@angular/common';
import { LevelMaster } from 'src/app/module/level-master/level-master';
import { LimitTransListService } from 'src/app/module/limit-translist/limit-translist.service';
import { FinYear } from 'src/app/module/limit-translist/limit-translist.module';
import { filterQuery } from 'src/app/shared/common.model';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
  dashboardCardDetails:DashboardCard;
  funds: Funds[];
  fundTransactions:FundTransactions;
  activeIndex = 0;
  fundLimitActiveIndex = 0;
  exprLimitActiveIndex = 0;
  successExprLimitActiveIndex = 0;
  balLimitActiveIndex = 0;
  workActiveIndex = 0;
  limitFundByYear: any;
  fundExpenditure: FundTransactions;
  fundSuccessfullTransaction: FundTransactions;
  fundBalance: FundTransactions;
  expenditureByYear: any;
  balanceByYear: any;
  projectSummary: ProjectSummary;
  projectSummaryByLevel:ProjectSummaryByLevel;
  levelMasterId=1;
  successExpenditureByYear: any;
  finYear: FinYear[];
  finYearDto:FinYear;

  
  constructor(private dashboardService:DashboardService, private fundsService: FundsService,
    public fontService:FontService, private limitTransListService: LimitTransListService,) { 
    registerLocaleData(enIN);
  }
  
  ngOnInit(): void {
    this.limitTransListService.getFinYear().subscribe(
      (res: HttpResponse<FinYear[]>) => {
        this.finYear = res.body;
        this.finYearDto = this.finYear[0];
        this.onFinyearChange();
         
      },
      () => { }
    );
  }

  onFinyearChange() {
    this.dashboardService.getDashboardCardDetails(this.levelMasterId,this.finYearDto.id).subscribe(
      (res)=>{
        this.dashboardCardDetails=res.body;
        this.activeIndex = 0;
        this.onCardTabChange();
      },
      (error)=>{}
    )
   
  }
  getFunds() {
    let filter = {
      page:0,
      size: 10,
    };
    let filquery = new filterQuery();
    filquery.key = 'finYear.id';
    filquery.operation = 'equals';
    filquery.value =this.finYearDto.id ;
    let filterProps = []
    filterProps.push(filquery);
    
    this.fundsService.filter({ ...filter }, filterProps).subscribe(
      (res: HttpResponse<Funds[]>) => {
        this.funds = res.body;
      },
      () => {
       
      }
    );
  }
  

  

  loadfundLimitDistrict(id){
    this.fundLimitActiveIndex=1;
    this.dashboardService.limitFundByYear(id,undefined,this.levelMasterId).subscribe(
      (res)=>{
        this.limitFundByYear=res.body;
      },
      (error)=>{}
    )
  }

  loadExpenditureDistrict(id){
    this.exprLimitActiveIndex=1;
    this.dashboardService.expenditureByYear(id,undefined,this.levelMasterId).subscribe(
      (res)=>{
        this.expenditureByYear=res.body;
      },
      (error)=>{}
    )
  }

  loadSuccessExpenditureDistrict(id){
    this.successExprLimitActiveIndex=1;
    this.dashboardService.SuccessTransactionExpenditureByYear(id,undefined,this.levelMasterId).subscribe(
      (res)=>{
        this.successExpenditureByYear=res.body;
      },
      (error)=>{}
    )
  }

  loadBalanceDistrict(id){
    this.balLimitActiveIndex=1;
    this.dashboardService.balanceByYear(id,undefined,this.levelMasterId).subscribe(
      (res)=>{
        this.balanceByYear=res.body;
      },
      (error)=>{}
    )
  }

  loadWorkDistrict(id){
    this.workActiveIndex=1;
    this.dashboardService.getProjectSummaryByLevel(id,undefined,this.levelMasterId).subscribe(
      (res)=>{
        this.projectSummaryByLevel=res.body;
      },
      (error)=>{}
    )
  }

  onCardTabChange(){
    switch (this.activeIndex) {
      case 0:
        this.getFunds();
      break;
      case 1:
        this.loadfundLimitDistrict(this.finYearDto.id);
      break;
      case 2:
        this.loadWorkDistrict(this.finYearDto.id);
      break;
      case 3:
        this.loadExpenditureDistrict(this.finYearDto.id);
      break;
      case 4:
        this.loadSuccessExpenditureDistrict(this.finYearDto.id);
      break;
      case 5:
        this.loadBalanceDistrict(this.finYearDto.id);
      break;
        
    }
    this.fundLimitActiveIndex=0;
    this.limitFundByYear=null;
    this.exprLimitActiveIndex=0;
    this.successExprLimitActiveIndex=0;
    this.expenditureByYear=null;
    this.balLimitActiveIndex=0;
    this.balanceByYear=null;
    this.workActiveIndex=0;
    this.projectSummaryByLevel=null;
    
  }

  onfundLimitTabChange(event: any){
    if (event.index === 0){
      this.limitFundByYear=null;
    }
  }
  onExpenditureTabChange(event: any){
    if (event.index === 0){
      this.expenditureByYear=null;
    }
  }

  onSuccessExpenditureTabChange(event: any){
    if (event.index === 0){
      this.successExpenditureByYear=null;
    }
  }

  onBalanceTabChange(event: any){
    if (event.index === 0){
      this.balanceByYear=null;
    }
  }
  onWorkTabChange(event: any){
    if (event.index === 0){
      this.projectSummaryByLevel=null;
    }
  }

  formatCash = n => {
    return +(n / 1e7).toFixed(3);
  };
  
  formatCashLakh = n => {
    return +(n / 1e5).toFixed(3) ;
  }; 
  
}
<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5>{{currentElement?.report_heading_name}}</h5>
                </ng-template>

            </p-toolbar>
        </div>
    </div>
    <p-toolbar>
        <div class="p-toolbar-group-start">
            <div class="row px-4 g-2">
                <div *ngIf="currentElement?.enable_District" class="col-sm">
                    <label class="form-control-label" for="scheme">District</label>
                    <ng-select id="scheme" bindLabel="name" [(ngModel)]="district" [items]="this.districtLevelList" appearance="outline" [closeOnSelect]="true" (change)="onDistrictLevelChange()" [clearable]="false" class="form-control">
                    </ng-select>
                </div>
                <div *ngIf="currentElement?.enable_Block" class="col-sm">
                    <label class="form-control-label" for="scheme">Block</label>
                    <ng-select id="scheme" [(ngModel)]="BlockLevelDto" [items]="blockLevelList" bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="false" class="form-control">
                    </ng-select>
                </div>
                <div *ngIf="currentElement?.enable_date" class="col-sm">
                    <label class="form-control-label" for="dateOfBirth">From Date</label><br />
                    <p-calendar dateFormat="dd.mm.yy" [(ngModel)]="fromDate" styleClass="datepicker"></p-calendar>
                </div>

                <div *ngIf="currentElement?.enable_date" class="col-sm">
                    <label class="form-control-label" for="dateOfBirth">To Date</label><br />
                    <p-calendar dateFormat="dd.mm.yy" [(ngModel)]="toDate" styleClass="datepicker"></p-calendar>
                </div>

                <div *ngIf="currentElement?.enable_finyear" class="col-sm">
                    <label class="form-control-label req" for="date" jhiTranslate="funds.fin.year.name">Fin Year</label>
                    <ng-select id="gender" bindLabel="name" [items]="finYear" appearance="outline" [(ngModel)]="finYearDto" [closeOnSelect]="true" [clearable]="false" class="form-control">
                    </ng-select>
                </div>
                <div *ngIf="currentElement?.enable_status" class="col-sm">
                    <label class="form-control-label" for="status" >Status</label>
                    <ng-select id="gender" bindLabel="name" [items]="statusList" appearance="outline" [(ngModel)]="selectedStatus" [closeOnSelect]="true" [clearable]="false" class="form-control">
                    </ng-select>
                </div>
                <div *ngIf="currentElement?.enable_search" class="col-sm">
                    <p></p>
                    <button pButton pRipple (click)="loadPage(1)" class="p-button-success align-items-end">
                <span class="button-label">Search</span>
              </button>
                </div>
            </div>
            <div class="row">

            </div>
        </div>
    </p-toolbar>
    <div class="row">
        <div #customerTable id="entities" class="col-12 px-4">
            <custom-filter-table [columnsValues]="cols" [fileName]="currentElement.report_heading_name" [filterSearchResult]="searchResult" (searchParam)="onLazyLoad($event)" [lazyLoadOnInit]="false" [disableDeleteIcon]="true" [showProfileButton]="false" [disableEditIcon]="true"></custom-filter-table>
        </div>
    </div>
</div>
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common';

// Ngx & Others
import { httpInterceptorProviders } from './auth/interceptor';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { missingTranslationHandler } from './config/translation.config';
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

// PrimeNg
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { ChartModule } from 'primeng/chart';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import { MenubarModule } from 'primeng/menubar';
import { MessageService } from 'primeng/api';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { RippleModule } from 'primeng/ripple';
import { SliderModule } from 'primeng/slider';
import { TableModule } from 'primeng/table';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { TimelineModule } from 'primeng/timeline';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { CascadeSelectModule } from 'primeng/cascadeselect';
import { BadgeModule } from 'primeng/badge';
import { TreeTableModule } from 'primeng/treetable';


import { SharedModule } from './shared/shared.module';

// Services
import { AccountSnaService } from './module/account-sna/account-sna.service';
import { AgencyService } from './module/agency/agency.service';
import { CommercialVendorService } from './module/commercial-vendors/commercial-vendors.service';
import { ComponentTypeService } from './module/component-type/component-type.service';
import { DepartmentListService } from './module/department-list/department-list.service';
import { FundsService } from './module/funds/funds.service';
import { FundsTransactionService } from './module/funds-transaction/funds-transaction.service';
import { LevelMasterService } from './module/level-master/level-master.service';
import { LevelTypeService } from './module/level-type/level-type.service';
import { LimitSetService } from './module/limit-set/limit-set.service';
import { MajorComponentService } from './module/major-component/major-component.service';
import { MinorComponentService } from './module/minor-component/minor-component.service';
import { OpeningBalanceService } from './module/opening-balance/opening-balance.service';
import { PersonalVendorService } from './module/personal-vendors/personal-vendors.service';
import { ProfileService } from './module/profile/profie.service';
import { SchemeListService } from './module/scheme-list/scheme-list.service';
import { SubComponentService } from './module/sub-component/sub-component.service';
import { UserService } from './module/user/user.service';
import { ValidationService } from './shared/validation/validation.service';
import { ZonalService } from './module/zonal/zonal.service';
import { NotificationService } from './alert/notification.service';


// Components
import { AccountSnaComponent } from './module/account-sna/account-sna.component';
import { AgencyComponent } from './module/agency/agency.component';
import { CommercialVendorsComponent } from './module/commercial-vendors/commercial-vendors.component';
import { ComponentMasterComponent } from './module/component-master/component-master.component';
import { ComponentTypeComponent } from './module/component-type/component-type.component';
import { DashboardComponent } from './dashBoards/dashboard/dashboard.component';
import { DepartmentListComponent } from './module/department-list/department-list.component';
import { FooterComponent } from './layout/footer/footer.component';
import { FundsComponent } from './module/funds/funds.component';
import { FundsTransactionComponent } from './module/funds-transaction/funds-transaction.component';
import { LevelMasterComponent } from './module/level-master/level-master.component';
import { LevelTypeComponent } from './module/level-type/level-type.component';
import { LimitSetComponent } from './module/limit-set/limit-set.component';
import { LimitTranslistComponent } from './module/limit-translist/limit-translist.component';
import { MajorComponentComponent } from './module/major-component/major-component.component';
import { MinorComponentComponent } from './module/minor-component/minor-component.component';
import { NavbarComponent } from './layout/navbar/navbar.component';
import { PaymentModuleComponent } from './payments/payment-module/payment-module.component';
import { ComponentNameComponent } from './component-name/component-name.component';
import { TabViewModule } from 'primeng/tabview';

import { PaymentVoucherComponent } from './payments/payment-voucher/payment-voucher.component';
import { PersonalVendorsComponent } from './module/personal-vendors/personal-vendors.component';
import { ProfileComponent } from './module/profile/profile.component';
import { SchemeListComponent } from './module/scheme-list/scheme-list.component';
import { SigninComponent } from './auth/signin/signin.component';
import { SubComponentComponent } from './module/sub-component/sub-component.component';
import { UserComponent } from './module/user/user.component';
import { AlertComponent } from './alert/alert.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { UppercaseInputDirective } from './shared/directives/uppercase-input.directive';

//devExtreme
import { DxChartModule,DxButtonModule } from 'devextreme-angular';
import { DxPieChartModule } from 'devextreme-angular';
import { DxSankeyModule } from 'devextreme-angular';
import { EmployeeComponent } from './module/employee/employee.component';
import { ElectedRepresentativeComponent } from './module/elected-representative/elected-representative.component';
import { LocalBodyContributionComponent } from './module/local-body-contribution/local-body-contribution.component';
import { ProjectComponent } from './project/project.component';
import { VendorPaymentModuleComponent } from './payments/vendor-payment-module/vendor-payment-module.component';
import { VendorPaymentVoucherComponent } from './payments/vendor-payment-voucher/vendor-payment-voucher.component';
import { DynamicReportTableComponent } from './dynamic-report-table/dynamic-report-table.component';
import { StatutoryPaymentComponent } from './payments/statutory-payment/statutory-payment.component';
import { DistrictDashboardComponent } from './dashBoards/dashboard/district-dashboard/district-dashboard.component';
import { MigratePaymentComponent } from './payments/migrate-payment/migrate-payment.component';
import { MigratePaymentModuleComponent } from './payments/migrate-payment-module/migrate-payment-module.component';
import { MigrateVendorPaymentModuleComponent } from './payments/migrate-vendor-payment-module/migrate-vendor-payment-module.component';
import { MigrateVendorPaymentVoucherComponent } from './payments/migrate-vendor-payment-voucher/migrate-vendor-payment-voucher.component';
import { LimitAllocationComponent } from './module/limit-allocation/limit-allocation.component';
import { StatutoryPaymentModuleComponent } from './payments/statutory-payment-module/statutory-payment-module.component';
import { StatutoryPaymentVoucherComponent } from './payments/statutory-payment-voucher/statutory-payment-voucher.component';
import { StatutoryAccountConfigComponent } from './module/statutory-account-config/statutory-account-config.component';
import { FailureTransactionComponent } from './payments/failure-transaction/failure-transaction.component';
import { DeductionsComponent } from './deductions/deductions.component';
import { VendorDeductionsComponent } from './deductions/vendor-deductions/vendor-deductions.component';
import { DisputedPaymentListComponent } from './payments/disputed-payment-list/disputed-payment-list.component';
import { PaymentStatusComponent } from './drillDown-report/payment-status/payment-status.component';
import { VoucherStatusComponent } from './drillDown-report/voucher-status/voucher-status.component';
import { VendorReportComponent } from './drillDown-report/vendor-report/vendor-report.component';
import { LimitReportComponent } from './drillDown-report/limit-report/limit-report.component';
import { BlukPaymentTableComponent } from './payments/bluk-payment-table/bluk-payment-table.component';
import { BlukPaymentVoucherComponent } from './payments/bluk-payment-voucher/bluk-payment-voucher.component';
import { VendorMappingTableComponent } from './module/vendor-mapping-table/vendor-mapping-table.component';
import { VendorMappingPageComponent } from './module/vendor-mapping-page/vendor-mapping-page.component';
import { MiscellaneousPaymentTableComponent } from './payments/miscellaneous-payment-table/miscellaneous-payment-table.component';
import { MiscellaneousPaymentVoucherComponent } from './payments/miscellaneous-payment-voucher/miscellaneous-payment-voucher.component';
import { WrongTransactionComponent } from './payments/wrong-transaction/wrong-transaction.component';
import { DashBoardReportComponent } from './dashBoards/dash-board-report/dash-board-report.component';

@NgModule({
  declarations: [
    AppComponent,
    SigninComponent,
    DashboardComponent,
    NavbarComponent,
    FooterComponent,
    ProfileComponent,
    UserComponent,
    AgencyComponent,
    PersonalVendorsComponent,
    CommercialVendorsComponent,
    AccountSnaComponent,
    DepartmentListComponent,
    SchemeListComponent,
    FundsComponent,
    FundsTransactionComponent,
    LimitSetComponent,
    LevelTypeComponent,
    LevelMasterComponent,
    ComponentMasterComponent,
    ComponentTypeComponent,
    MajorComponentComponent,
    MinorComponentComponent,
    SubComponentComponent,
    PaymentModuleComponent,
    PaymentVoucherComponent,
    LimitTranslistComponent,
    AlertComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    UppercaseInputDirective,
    EmployeeComponent,
    ElectedRepresentativeComponent,
    LocalBodyContributionComponent,
    ProjectComponent,
    ComponentNameComponent,
    VendorPaymentModuleComponent,
    VendorPaymentVoucherComponent,
    DynamicReportTableComponent,
    StatutoryPaymentComponent,
    DistrictDashboardComponent,
    MigratePaymentComponent,
    MigratePaymentModuleComponent,
    MigrateVendorPaymentModuleComponent,
    MigrateVendorPaymentVoucherComponent,
    LimitAllocationComponent,
    FailureTransactionComponent,
    StatutoryPaymentModuleComponent,
    StatutoryPaymentVoucherComponent,
    StatutoryAccountConfigComponent,
    DeductionsComponent,
    VendorDeductionsComponent,
    DisputedPaymentListComponent,
    PaymentStatusComponent,
    VoucherStatusComponent,
    VendorReportComponent,
    LimitReportComponent,
    BlukPaymentTableComponent,
    BlukPaymentVoucherComponent,
    VendorMappingTableComponent,
    VendorMappingPageComponent,
    MiscellaneousPaymentTableComponent,
    MiscellaneousPaymentVoucherComponent,
    WrongTransactionComponent,
    DashBoardReportComponent
  ],
  imports: [
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgxWebstorageModule.forRoot(),
    ButtonModule,
    MenubarModule,
    TieredMenuModule,
    RippleModule,
    TabViewModule,
    DxSankeyModule,
    TableModule,
    CalendarModule,
    SliderModule,
    DialogModule,
    MultiSelectModule,
    ContextMenuModule,
    DropdownModule,
    TreeTableModule,
    TimelineModule,
    ToastModule,
    InputTextModule,
    ProgressBarModule,
    FileUploadModule,
    ToolbarModule,
    RatingModule,
    RadioButtonModule,
    InputNumberModule,
    ConfirmDialogModule,
    InputTextareaModule,
    ChartModule,
    DxButtonModule,
    DxChartModule,
    DxPieChartModule,
    NgSelectModule,
    BadgeModule,
    NgxSpinnerModule,
    NgxExtendedPdfViewerModule,
    CascadeSelectModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useFactory: missingTranslationHandler,
      },
    }),
  ],

  providers: [
    httpInterceptorProviders,
    ProfileService,
    MessageService,
    ConfirmationService,
    AgencyService,
    UserService,
    ZonalService,
    PersonalVendorService,
    CommercialVendorService,
    AccountSnaService,
    DepartmentListService,
    SchemeListService,
    FundsService,
    OpeningBalanceService,
    FundsTransactionService,
    LimitSetService,
    LevelTypeService,
    LevelMasterService,
    ComponentTypeService,
    MajorComponentService,
    MinorComponentService,
    SubComponentService,
    ValidationService,
    NotificationService,
    DatePipe
  ],
  bootstrap: [AppComponent]

})
export class AppModule {
  constructor(

    translateService: TranslateService,
    sessionStorageService: SessionStorageService
  ) {
    // registerLocaleData(locale);
    translateService.setDefaultLang('en');
    // if user have changed language and navigates away from the application and back to the application then use previously choosed language
    const langKey = sessionStorageService.retrieve('locale') ?? 'en';
    translateService.use('en');
  }
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}



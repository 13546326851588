<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass">Disputed Transaction List</h5>
                </ng-template>
                <ng-template pTemplate="right">

                </ng-template>
            </p-toolbar>
        </div>
    </div>

    <p-tabView [(activeIndex)]="activeIndex" (onChange)="onTabChange($event)">
        <p-tabPanel header="Vendor Tranasction">
            <div *ngIf="activeIndex === 0" class="row">
                <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchVendorResult" (searchParam)="onLazyLoad($event)" [disableDeleteIcon]="true" [disablePaymentEditIcon]="true" [paginator]="false"></custom-filter-table>
            </div>
        </p-tabPanel>
        <p-tabPanel header="Statutory Tranaction">
            <div class="row">
                <custom-filter-table [columnsValues]="statutorycols" [filterSearchResult]="searchStatutoryResult" [paginator]="false"></custom-filter-table>
            </div>
        </p-tabPanel>
    </p-tabView>



</div>
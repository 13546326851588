import { LevelTypeDto } from './../limit-set/limit-set';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AgencyService } from './agency.service';
import { Agency, AgencyDto } from './agency';
import { SearchResult } from 'src/app/shared/models/search-result';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { DepartmentListService } from '../department-list/department-list.service';
import { SchemeListService } from '../scheme-list/scheme-list.service';
import { DepartmentList } from '../department-list/department-list';
import { SchemeList } from '../scheme-list/scheme-list';
import { LevelTypeService } from '../level-type/level-type.service';
import { LevelType } from '../level-type/level-type';
import { LimitTransListService } from '../limit-translist/limit-translist.service';
import { ZonalService } from '../zonal/zonal.service';
import { filterQuery } from 'src/app/shared/common.model';
import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Dialog } from 'primeng/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/alert/notification.service';
import { LevelMasterDto } from '../level-master/level-master';
import { LevelMasterService } from '../level-master/level-master.service';

@Component({
  selector: 'app-agency',
  templateUrl: './agency.component.html',
  styleUrls: ['./agency.component.scss'],
})
export class AgencyComponent implements OnInit, AfterViewInit {
  @ViewChild('dialog') dialog: Dialog;
  // @ViewChild('department', ) department: NgSelectComponent;

  agencyDialog: boolean = false;
  cols: any[];
  zonalDto: any;
  searchResult: SearchResult<Agency> = new SearchResult<Agency>();
  agency?: AgencyDto;
  submitted?: boolean;
  selectedMapping: string;
  itemsPerPage: any = 10;
  page: number;
  filterProps: any;
  departmentList: DepartmentList[];
  schemeList: SchemeList[];
  levelType: LevelType[];
  levelTypeDto: LevelType;
  levelMasterList: any;
  levelMasterDto: any;
  zonalList: any;
  // validation fields properties
  public showMobileError: boolean = false;
  public emailError = false;
  public emailBranchError = false;
  public showGstError = false;
  public showIfscError = false;
  public showBMobile = false;
  //required fields property
  ngSelectInvalid = false;
  showNameError: any;
  showMobileNoError: string;
  showEmailError: string;
  showEmailUniError: string;
  gstUniError: string;
  viewOnly: boolean;
  department: string;
  scheme: string;

  constructor(
    private agencyService: AgencyService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private departmentListService: DepartmentListService,
    private schemeListService: SchemeListService,
    private levelTypeService: LevelTypeService,
    private limitTransListService: LimitTransListService,
    private zonalService: ZonalService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,
    private levelMasterService: LevelMasterService
  ) {}

  ngOnInit() {
    this.cols = [
      {
        field: 'department',
        header: 'Dept Code',
        jhiTranslate: 'tableHead.department.name',
        isSortable: true,
        isFilterable: false,
        width: 10,
        isSelectcolumn: true,
        type: 'dropDown',
      },
      {
        field: 'scheme',
        header: 'Name',
        jhiTranslate: 'tableHead.scheme.name',
        isSortable: true,
        isFilterable: false,
        width: 25,
        isSelectcolumn: true,
        type: 'dropDown',
      },
      {
        field: 'name',
        header: 'Agency Name',
        jhiTranslate: 'tableHead.agency.name',
        isSortable: true,
        isFilterable: false,
        width: 15,
        isSelectcolumn: true,
      },
      {
        field: 'mobile',
        header: 'Mobile No',
        jhiTranslate: 'tableHead.mobile.number',
        isSortable: false,
        isFilterable: false,
        width: 13,
        isSelectcolumn: true,
      },
    ];
    this.updateDialogFont();
  }

  ngAfterViewInit() {
    this.updateDialogFont();
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }

  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;

    let query = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    this.filterProps = this.filterProps ?? [];
    this.filterProps = this.filterProps ? this.filterProps : [];
    let filquery = new filterQuery();
    filquery.key = 'activeFlag';
    filquery.operation = 'equals';
    filquery.value = 'YES';
    this.filterProps.push(filquery);
    this.agencyService.filter({ ...query }, this.filterProps).subscribe(
      (res: HttpResponse<Agency[]>) => {
        this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
      },
      () => {
        this.onError();
      }
    );
  }

  protected onSuccess(
    data: Agency[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;
    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void {}

  onLazyLoad(event) {
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

  vendorFilter($event) {
    let filter = new filterQuery();
    filter.key = 'name';
    filter.operation = 'contains';
    filter.value = $event.term;
    let filterquery = [];
    filterquery.push(filter);
    this.zonalService.filter({ size: 10, page: 0 }, filterquery).subscribe(
      (res: HttpResponse<any>) => {
        this.zonalList = res.body;
      },
      () => {}
    );
  }

  openNew() {
    this.agency = new AgencyDto();
    this.submitted = false;
    this.departmentListService.filter().subscribe(
      (res: HttpResponse<DepartmentList[]>) => {
        this.departmentList = res.body;
        this.department=res.body[0].name;
      },
      () => {
        this.onError();
      }
    );
    this.schemeListService.filter().subscribe(
      (res: HttpResponse<SchemeList[]>) => {
        this.schemeList = res.body;
        this.scheme=res.body[0].name;
      },
      () => {
        this.onError();
      }
    );
    this.onchangeSchema();
    this.agencyDialog = true;
  }

  onDeparmentChange() {
    this.schemeListService.filter().subscribe(
      (res: HttpResponse<SchemeList[]>) => {
        this.schemeList = res.body;
        this.scheme=res.body[0].name;
      },
      () => {
        this.onError();
      }
    );
  }

  onchangeSchema() {
    this.levelTypeService.filter().subscribe(
      (res: HttpResponse<LevelType[]>) => {
        // this.levelType = res.body;
        this.levelType = res.body.filter(item => item.name === 'State' || item.name === 'District' );

      },
      () => {}
    );
  }

  onLevelTypeChange($event?) {
    this.levelMasterDto = null;
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 10,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'levelType.id',
            operation: 'equals',
            value: this.levelTypeDto.id,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.levelMasterList = res.body;
          },
          () => {}
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 10,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'levelType.id',
            operation: 'equals',
            value: this.levelTypeDto.id,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.levelMasterList = res.body;
          },
          () => {}
        );
    }
  }

  hideDialog() {
    this.showMobileError = false;
    this.emailError = false;
    this.emailBranchError = false;
    this.showGstError = false;
    this.showIfscError = false;
    this.showBMobile = false;
    this.ngSelectInvalid = false;
    this.agency = new AgencyDto();
    this.levelTypeDto = new LevelType();
    this.levelTypeDto = null;
    this.levelType = null;
    this.levelMasterDto = null;
    this.levelMasterList = null;
    this.agencyDialog = false;
    this.showNameError = null;
    this.showMobileNoError = null;
    this.showEmailError = null;
    this.showEmailUniError = null;
    this.gstUniError = null;
    this.viewOnly=false;
  }

  validateMobile() {
    this.showMobileError = !this.validationService.isMobileValid(
      this.agency.mobile
    );
    !this.agency.mobile ? (this.showMobileError = false) : true;
    return !this.showMobileError && !!this.agency.mobile;
  }

  validateEmail() {
    this.emailError = !this.validationService.isEmailValid(this.agency.email);
    !this.agency.email ? (this.emailError = false) : true;
    return !this.emailError && !!this.agency.email;
  }

  validateGst() {
    this.showGstError = !this.validationService.isGstValid(this.agency.gstNo);
    !this.agency.gstNo ? (this.showGstError = false) : true;
    return !this.showGstError && !!this.agency.gstNo;
  }

  validateIfsc() {
    if (this.agency.ifscCode) {
      this.showIfscError = !this.validationService.isIfscValid(
        this.agency.ifscCode
      );

      return !this.showIfscError;
    } else {
      this.showIfscError = false;
      return true;
    }
  }

  validateBmobile() {
    if (this.agency.branchMobNo) {
      this.showBMobile = !this.validationService.isMobileValid(
        this.agency.branchMobNo
      );
      return !this.showBMobile;
    } else {
      this.showBMobile = false;
      return true;
    }
  }

  validateBemail() {
    if (this.agency.branchEmail) {
      this.emailBranchError = !this.validationService.isEmailValid(
        this.agency.branchEmail
      );
      return !this.emailBranchError;
    } else {
      this.emailBranchError = false;
      return true;
    }
  }

  saveAgency() {
    const isFormValid =
      this.validateMobile() &&
      this.validateEmail() &&
      this.validateGst() &&
      this.validateIfsc() &&
      this.validateBmobile() &&
      this.validateBemail() 
      // this.agency.departmentDto &&
      // this.agency.schemeDto &&
      // this.agency.name &&
      // this.agency.email &&
      // this.agency.gstNo &&
      // this.levelMasterDto;

    if (!isFormValid) {
      this.ngSelectInvalid = true;
      return;
    }
    if (
      this.showNameError ||
      this.showMobileNoError ||
      this.showEmailUniError ||
      this.gstUniError
    ) {
      return;
    }

    this.ngSelectInvalid = false;
    this.agency.leveltype = this.levelTypeDto?.id;
    this.agency.levelMaster = this.levelMasterDto?.id;

    this.agencyService.save(this.agency).subscribe(
      (res: HttpResponse<Agency[]>) => {
        this.agencyDialog = false;
        this.loadPage(this.page);
        this.notificationService.alertSuccess('Saved Successfully', '');
        this.hideDialog();
      },
      () => {
        this.onError();
        this.notificationService.alertError('Error!', '');
      }
    );
  }

  async checkName() {
    const filterData = await this.getfilterdata('name', this.agency.name);
    if (filterData.length !== 0) {
      this.showNameError = 'Name Already Exists';
    } else {
      this.showNameError = null;
    }
  }

  async checkmobile() {
    const filterData = await this.getfilterdata('mobile', this.agency.mobile);
    if (filterData.length !== 0) {
      this.showMobileNoError = 'Phone Already Exists';
    } else {
      this.showMobileNoError = null;
    }
  }

  async checkemail() {
    const filterData = await this.getfilterdata('email', this.agency.email);
    if (filterData.length !== 0) {
      this.showEmailUniError = 'Email Already Exists';
    } else {
      this.showEmailUniError = null;
    }
  }

  async checkgstNo() {
    const filterData = await this.getfilterdata('gstNo', this.agency.gstNo);
    if (filterData.length !== 0) {
      this.gstUniError = 'GST Already Exists';
    } else {
      this.gstUniError = null;
    }
  }

  getfilterdata(fields, value): Promise<any> {
    return new Promise<any>((resolve) => {
      let filterQuery = {
        page: 0,
        size: 10,
      };
      const filter = [
        {
          key: fields,
          operation: 'equals',
          value: value.toUpperCase(),
        },
        {
          key: 'activeFlag',
          operation: 'equals',
          value: 'YES',
        },
      ];
      if (this.agency.id) {
        filter.push({
          key: 'id',
          operation: 'notEquals',
          value: this.agency.id,
        });
      }
      this.agencyService.validFilter({ ...filterQuery }, filter).subscribe(
        (res: HttpResponse<Agency[]>) => {
          resolve(res.body);
        },
        () => {
          this.onError();
          resolve(null);
        }
      );
    });
  }

  onSelectRow($event) {
    if ($event.type === 'select' || $event.type === 'view') {
      if($event.type === 'view'){
        this.viewOnly=true;
      }
      this.agencyService.getAgency($event.value.id).subscribe(
        (res: HttpResponse<any>) => {
          this.agency = res.body;
          this.agencyDialog = true;
          this.department=this.agency.departmentDto.name;
          this.scheme=this.agency.schemeDto.name;
          // this.departmentListService.filter().subscribe(
          //   (res: HttpResponse<DepartmentList[]>) => {
          //     this.departmentList = res.body;
          //   },
          //   () => {
          //     this.onError();
          //   }
          // );
          this.onchangeSchema();
          this.loadLevelMasterList(res.body.leveltype);
          this.loadLevelMaster(res.body.levelMaster);
        },
        () => {
          this.onError();
        }
      );
    } else {
      this.agencyService.delete($event.value.id).subscribe(
        (res: HttpResponse<any>) => {
          this.loadPage(this.page);
        },
        () => {
          this.onError();
        }
      );
    }
  }
  loadLevelMaster(id){
    let filterQuery = {
      page: 0,
      size: 10,
    };
    this.levelMasterService
      .LevelMasterDtoFilter({ ...filterQuery }, [
        {
          key: 'id',
          operation: 'equals',
          value: id,
        }
      ])
      .subscribe(
        (res: HttpResponse<LevelMasterDto[]>) => {
          this.levelMasterDto=res.body[0];
        },
        () => {}
      );
  }
  loadLevelMasterList(id){
    this.levelTypeService.getbyId(id).subscribe(
      (res)=>{
        this.levelTypeDto=res.body;
      },
      ()=>{}
    )
    let filterQuery = {
      page: 0,
      size: 10,
    };
    this.levelMasterService
      .LevelMasterDtoFilter({ ...filterQuery }, [
        {
          key: 'levelType.id',
          operation: 'equals',
          value: id,
        }
      ])
      .subscribe(
        (res: HttpResponse<LevelMasterDto[]>) => {
          this.levelMasterList = res.body;
        },
        () => {}
      );
  }
}

import { CommercialVendors } from "src/app/module/commercial-vendors/commercial-vendors";
import { LevelMaster } from "src/app/module/level-master/level-master";

export class WrongTransaction {
    revertType: string;
    voucherNo: string;
    commercialVendor: CommercialVendors;
    grossAmount: number;
    transactionAmount: number;
    levelMaster: LevelMaster;
    description: string;
    snaTransactionDate: Date;
    transactionReferenceNumber: string;
    mandatoryDocument: string;
    id:number;
}


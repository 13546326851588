<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass">
                        <p>Payment Voucher (Wrong Transaction)</p>
                    </h5>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="row">
                <div class="col">
                    <div class="card p-3">
                        <div class="row">
                            <div class="col-sm-6 col-md-3">
                                <label class="form-control-label" [ngClass]="fontService.labelClass" for="name">Voucher No
                                </label>
                                <input type="text" class="form-control" [(ngModel)]="wrongTransaction.voucherNo" (change)="changeVoucherNo()" name="name" autocomplete="off" maxlength="60" onblur="this.value =this.value.trim();" id="name" />
                            </div>

                            <div class="col-lg-3 col-md-6 col-sm-4">
                                <p [ngClass]="fontService.regularBoldClass">
                                    Level Type:<br />{{ this.wrongTransaction.levelMaster?.levelType?.name }}
                                </p>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-4">
                                <p [ngClass]="fontService.regularBoldClass">
                                    Level Master:<br />{{ this.wrongTransaction?.levelMaster?.name }}
                                </p>
                            </div>


                            <div class="col-lg-3 col-md-6 col-sm-4">
                                <p [ngClass]="fontService.regularBoldClass">
                                    Work Id:<br />{{ this.voucher?.workId }}
                                </p>
                            </div>


                        </div>
                        <div class="row mt-3">
                            <div class="col-lg-3 col-md-6 col-sm-4">
                                <p [ngClass]="fontService.regularBoldClass">
                                    Work Name:<br />{{this.voucher?.nameOfWork}}
                                </p>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-4">
                                <p [ngClass]="fontService.regularBoldClass">
                                    Vendor Name:<br />{{ this.voucher?.commercialVendorName}}
                                </p>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-4">
                                <p [ngClass]="fontService.regularBoldClass">
                                    Vendor IfscCode :<br />{{this.voucher?.commercialIfscode }}
                                </p>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-4">
                                <p [ngClass]="fontService.regularBoldClass">
                                    Vendor Account No:<br />{{ this.voucher?.commercialAccountNo }}
                                </p>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-lg-3 col-md-6 col-sm-4">
                                <p [ngClass]="fontService.regularBoldClass">
                                    Gross Amount :<br />{{wrongTransaction.grossAmount}}
                                </p>
                            </div>

                            <div class="col-lg-3 col-md-6 col-sm-4">
                                <p [ngClass]="fontService.regularBoldClass">
                                    Transaction Amount<br />{{ this.wrongTransaction.transactionAmount }}
                                </p>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-4">
                                <p [ngClass]="fontService.regularBoldClass">
                                    Payment Status:<br />{{ wrongTransaction.revertType}}
                                </p>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-4">
                                <p [ngClass]="fontService.regularBoldClass">
                                    Payment Date:<br />{{ this.voucher?.paymentDate | date:'dd/MM/yyyy' }}
                                </p>
                            </div>
                        </div>
                        <div *ngIf="this.wrongTransaction.revertType === 'O.K.'" class="row mt-3">

                            <div class="col-sm-6 col-md-3">
                                <label class="form-control-label req" for="openingBalance" [ngClass]="fontService.labelClass">Transaction Reference No</label
                                >
                                <input type="text" class="form-control"
                                  oninput="this.value = this.value.replace(/[^A-Za-z0-9-_/]/g, '').replace(/(\..*)\./g, '$1');"
                                  maxlength="50" [(ngModel)]="wrongTransaction.transactionReferenceNumber"
                                  name="openingBalance" autocomplete="off" id="openingBalance" [ngClass]="{'is-invalid':ngSelectInvalid &&
                                  !wrongTransaction.transactionReferenceNumber}"
                                />
                              </div>
                              <div class="col-sm-6 col-md-3">
                                <label class="form-control-label req" for="date" [ngClass]="fontService.labelClass"
                                  jhiTranslate="funds.date.of.transaction.name">Date of Transaction</label>
                                <p-calendar styleClass="datepicker" class="same_line_fix" [(ngModel)]="wrongTransaction.snaTransactionDate" [ngClass]="{'is-invalid':ngSelectInvalid &&
                                !wrongTransaction.snaTransactionDate}" appendTo="body"></p-calendar>
                            </div>
                            <div class="col-lg-6">
                                <label class="form-control-label pt-md-0 pt-sm-2 pt-xs-2 req" for="scheme" [ngClass]="fontService.labelClass">Mandatory Document</label>
                                <div>
                                    <small class="mb-2" [ngClass]="fontService.regularClass" jhiTranslate="funds.note">Note : Allowed file types are jpg, jpeg, png, pdf and
                              Maximum file size should be 1MB</small>
                                </div>
                                <p-fileUpload (onUpload)="onUpload($event)" [customUpload]="true" [disabled]="isFileUploaded" (uploadHandler)="onUpload($event)" auto="true" multiple="false" [showUploadButton]="false" [showCancelButton]="false" chooseLabel="{{'common.upload'|translate}}">
                                </p-fileUpload>
                                <div class="row mt-2" *ngIf="isFileUploaded">
                                    <div class="col">
                                        <a [ngClass]="fontService.regularClass" class="cursor-pointer" (click)="viewDocument()">{{uFilename}}</a>
                                        <i class="pi pi-times add-margin" (click)="removeDocument()"></i>
                                        <i class="pi pi-download add-margin" (click)="downloadDocument()"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-8 mt-3">
                                <label class="form-control-label req" for="reason" [ngClass]="fontService.labelClass">Description</label><br />
                                <textarea id="reason" name="reason" rows="3" [(ngModel)]="wrongTransaction.description" cols="30" [ngClass]="{'is-invalid':ngSelectInvalid &&
                                !wrongTransaction.description}" maxlength="150" oninput="this.value = this.value.replace(/[^A-Za-z0-9 ,-.]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();"></textarea>
                            </div>
                        </div>

                        <p-toolbar>
                            <div class="p-toolbar-group-start"></div>
                            <div class="p-toolbar-group-end">

                                <button *ngIf="voucher" pButton pRipple icon="pi pi-check" class="p-button-raised ms-4 p-button-success" (click)="save()">
                                    <span class="button-label" style="padding-right: inherit;" [ngClass]="fontService.buttonClass">Revert
                                    </span>
                                </button>

                            </div>
                        </p-toolbar>
                    </div>
                </div>
            </div>



        </div>
    </div>
</div>

<p-dialog [(visible)]="viewDoc" [style]="{ width: '1000px', height: '800px' }" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog">
    <ng-template pTemplate="body">
        <div style='position: relative; height: 100%;'>
            <ngx-extended-pdf-viewer *ngIf="vFileExt === 'pdf'" [src]="vFileUrl"></ngx-extended-pdf-viewer>
            <img *ngIf="vFileExt !== 'pdf'" [src]="vFileUrl" style="display: block;" height="800px" />
        </div>
    </ng-template>
</p-dialog>
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
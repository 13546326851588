<div class="px-4 pt-2">
    <div class="row g-3 mb-3">
        <div class="col-lg-2 col-md-4">
            <ng-select id="gender" bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true" class="form-control" [items]="finYear" [(ngModel)]="finYearDto" (ngModelChange)="onFinyearChange()">
            </ng-select>
        </div>
        <div class="row g-3 mb-3">
            <div class="col-lg-2 col-md-4 col-sm-3 first-container">
                <div class="row mb-2">
                    <p-card [style]="{backgroundColor: '#e8c7f2'}" (click)="activeIndex = 0;onCardTabChange()" styleClass="card-hover">
                        <p class="size" [ngClass]="fontService.regularClass">Budget Allotment
                            <br><span style="font-size: 16px;"> {{formatCash(this.dashboardCardDetails?.budgetAmount)}}
                            Crore</span>
                        </p>
                    </p-card>
                </div>
                <div class="row mb-2">
                    <p-card (click)="activeIndex = 1;onCardTabChange()" [style]="{backgroundColor: '#a8e3ff'}" styleClass="card-hover">
                        <p class="size" [ngClass]="fontService.regularClass">Funds Recieved
                            <br><span style="font-size: 16px;">
                            {{formatCash(this.dashboardCardDetails?.fundReceivedAmount)}} Crore</span>
                        </p>
                    </p-card>
                </div>
                <div class="row mb-2" *ngIf="!isStateLogin">
                    <p-card (click)="activeIndex = 2;onCardTabChange()" [style]="{ backgroundColor: '#7DD8D0'}" styleClass="card-hover">
                        <p class="size" [ngClass]="fontService.regularClass">Work Details
                            <br><span style="font-size: 16px;"> {{this.dashboardCardDetails?.workCount}} No's</span>
                        </p>
                    </p-card>
                </div>
                <div class="row mb-2">
                    <p-card (click)="activeIndex = 3;onCardTabChange()" [style]="{ backgroundColor: '#bcbfeb'}" styleClass="card-hover">
                        <p class="size" [ngClass]="fontService.regularClass">Expenditure
                            <br><span style="font-size: 16px;"> {{formatCash(this.dashboardCardDetails?.expenditureAmount)}}
                            Crore</span>
                        </p>
                    </p-card>
                </div>
                <div class="row mb-2">
                    <p-card (click)="activeIndex = 4;onCardTabChange()" [style]="{ backgroundColor: '#bcbfeb'}" styleClass="card-hover">
                        <p class="size" [ngClass]="fontService.regularClass">Expenditure of Successful Transactions
                            <br><span style="font-size: 16px;">
                            {{formatCash(this.dashboardCardDetails?.successFulTransactionAmount)}} Crore</span>
                        </p>
                    </p-card>
                </div>
                <div class="row mb-2">
                    <p-card (click)="activeIndex = 5;onCardTabChange()" [style]="{ backgroundColor: '#a8e3ff'}" styleClass="card-hover">
                        <p class="size" [ngClass]="fontService.regularClass">Balance
                            <br><span style="font-size: 16px;"> {{formatCash(this.dashboardCardDetails?.balanceAmount)}}
                            Crore</span>
                        </p>
                    </p-card>
                </div>

            </div>
            <div class="col-lg-10 col-md-8 col-sm-9 first-container">
                <p-tabView class="dashboardCard" [(activeIndex)]="activeIndex">
                    <p-tabPanel header="Index0">
                        <h5>Allotment</h5>
                        <p-divider type="dashed"></p-divider>
                        <p-table [value]="funds" [tableStyle]="{ 'min-width': '100%' }" [resizableColumns]="true" responsiveLayout="scroll">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th [ngClass]="fontService.regularClass" id="fy">FINANCIAL YEAR</th>
                                    <th [ngClass]="fontService.regularClass" id="adminAllotment">Admin Allocation Amount(Rs. in CR)</th>
                                    <th [ngClass]="fontService.regularClass" id="progAllotment">Programme Allocation Amount(Rs. in CR)</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-fund>
                                <tr>
                                    <td>{{ fund?.finYear?.name}}</td>
                                    <td>
                                        <span>{{ formatCash(fund?.adminAllocationAmount)}} Crore</span>
                                    </td>
                                    <td>
                                        <span>{{ formatCash(fund?.programmeAllocationAmount)}} Crore</span>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </p-tabPanel>
                    <p-tabPanel header="Index1">
                        <h5>Funds Recieved</h5>
                        <p-divider type="dashed"></p-divider>

                        <div *ngIf="limitFundByYear" class="col-12 px-4">
                            <p-table [value]="limitFundByYear" [tableStyle]="{ 'min-width': '100%' }" [resizableColumns]="true" responsiveLayout="scroll">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th [ngClass]="fontService.regularClass" id="fy" *ngIf="!isStateLogin">
                                            District</th>
                                        <th [ngClass]="fontService.regularClass" id="fy" *ngIf="isStateLogin">Name
                                        </th>
                                        <th [ngClass]="fontService.regularClass" id="total">Total Fund Recieved</th>
                                        <th [ngClass]="fontService.regularClass" id="amount">Programme Amount(Rs. in lakh)
                                        </th>
                                        <th [ngClass]="fontService.regularClass" id="amount">Admin Amount(Rs. in lakh)
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-list>
                                    <tr>
                                        <td>{{ list?.levelMasterName}} </td>
                                        <td>{{ formatCashLakh(list?.totalAmount)}} Lakh</td>
                                        <td>{{ formatCashLakh(list?.programmeAmount)}} Lakh</td>
                                        <td>{{ formatCashLakh(list?.adminAmount)}} Lakh</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </p-tabPanel>
                    <p-tabPanel header="Index2">
                        <h5>Work Details</h5>
                        <p-divider type="dashed"></p-divider>

                        <div *ngIf="projectSummaryByLevel" class="col-12 px-4">
                            <p-table [value]="projectSummaryByLevel" [tableStyle]="{ 'min-width': '100%' }" [resizableColumns]="true" responsiveLayout="scroll">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th [ngClass]="fontService.regularClass" id="fy">District</th>
                                        <th [ngClass]="fontService.regularClass" id="fy">Total Cost(Rs. in CR)</th>
                                        <th [ngClass]="fontService.regularClass" id="Samount">Utilised Cost(Rs. in CR)
                                        </th>
                                        <th [ngClass]="fontService.regularClass" id="amount">Balance Cost(Rs. in CR)
                                        </th>
                                        <th [ngClass]="fontService.regularClass" id="fy">Total Work</th>
                                        <th [ngClass]="fontService.regularClass" id="Samount">Completed</th>
                                        <th [ngClass]="fontService.regularClass" id="amount">Not completed</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-list>
                                    <tr>
                                        <td>{{ list?.levelmasterName}} </td>
                                        <td>{{ formatCash(list?.totalCost)}} Crore</td>
                                        <td>{{ formatCash(list?.utilizedCost)}} Crore</td>
                                        <td>{{ formatCash(list?.balanceCost)}} Crore</td>
                                        <td>{{ list?.totalWork}}</td>
                                        <td>{{ list?.completed}}</td>
                                        <td>{{ list?.notCompleted}}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>

                    </p-tabPanel>
                    <p-tabPanel header="Index3">
                        <h5>Expenditure</h5>
                        <p-divider type="dashed"></p-divider>

                        <div *ngIf="expenditureByYear" class="col-12 px-4">
                            <p-table [value]="expenditureByYear" [tableStyle]="{ 'min-width': '100%' }" [resizableColumns]="true" responsiveLayout="scroll">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th [ngClass]="fontService.regularClass" id="fy">District</th>
                                        <th [ngClass]="fontService.regularClass" id="amount">Total Fund Recieved(Rs. in lakh)</th>
                                        <th [ngClass]="fontService.regularClass" id="amount">Programme Fund Expenditure(Rs. in lakh)</th>
                                        <th [ngClass]="fontService.regularClass" id="amount">Admin Fund Expenditure(Rs. in lakh)</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-list>
                                    <tr>
                                        <td>{{ list?.levelMasterName}} </td>
                                        <td>{{ formatCashLakh(list?.totalAmount)}} Lakh</td>
                                        <td>{{ formatCashLakh(list?.programmeAmount)}} Lakh</td>
                                        <td>{{ formatCashLakh(list?.adminAmount)}} Lakh</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </p-tabPanel>
                    <p-tabPanel header="Index4">
                        <h5>Expenditure of Successful Transactions</h5>
                        <p-divider type="dashed"></p-divider>

                        <div *ngIf="successExpenditureByYear" class="col-12 px-4">
                            <p-table [value]="successExpenditureByYear" [tableStyle]="{ 'min-width': '100%' }" [resizableColumns]="true" responsiveLayout="scroll">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th [ngClass]="fontService.regularClass" id="fy">District</th>
                                        <th [ngClass]="fontService.regularClass" id="amount">Total Fund Recieved(Rs. in CR)</th>
                                        <th [ngClass]="fontService.regularClass" id="Samount">Programme Fund Expenditure(Rs. in CR)</th>
                                        <th [ngClass]="fontService.regularClass" id="Samount">Admin Fund Expenditure(Rs. in CR)</th>
                                        <th [ngClass]="fontService.regularClass" id="Samount">Total Expenditure(Rs. in CR)</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-list>
                                    <tr>
                                        <td>{{ list?.levelMasterName}} </td>
                                        <td>{{ formatCashLakh(list?.totalAmount)}} Crore</td>
                                        <td>{{ formatCash(list?.programmeAmount)}} Crore</td>
                                        <td>{{ formatCash(list?.adminAmount)}} Crore</td>
                                        <td>{{ formatCash(list?.adminAmount + list?.programmeAmount)}} Crore
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </p-tabPanel>
                    <p-tabPanel header="Index5">
                        <h5>Balance</h5>
                        <p-divider type="dashed"></p-divider>

                        <div *ngIf="balanceByYear" class="col-12 px-4">
                            <p-table [value]="balanceByYear" [tableStyle]="{ 'min-width': '100%' }" [resizableColumns]="true" responsiveLayout="scroll">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th [ngClass]="fontService.regularClass" id="fy">District</th>
                                        <th [ngClass]="fontService.regularClass" id="amount">Total Fund Recieved(Rs. in lakh)</th>
                                        <th [ngClass]="fontService.regularClass" id="amount">Total Expenditure(Rs. in lakh)</th>
                                        <th [ngClass]="fontService.regularClass" id="amount">Balance Programme Fund(Rs. in lakh)</th>
                                        <th [ngClass]="fontService.regularClass" id="amount">Balance Admin Fund(Rs. in lakh)</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-list>
                                    <tr>
                                        <td>{{ list?.levelMasterName}} </td>
                                        <td>{{ formatCashLakh(list?.totalAmount)}} Lakh</td>
                                        <td>{{ formatCashLakh(list?.amount)}} Lakh</td>
                                        <td>{{ formatCashLakh(list?.programmeAmount)}} Lakh</td>
                                        <td>{{ formatCashLakh(list?.adminAmount)}} Lakh</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>

                    </p-tabPanel>
                </p-tabView>
            </div>

        </div>
import { Injectable, isDevMode } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NavbarService } from '../layout/navbar/navbar.service';
import { HttpResponse } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class UserRouteAccessService  {
  constructor(private router: Router, private sessionStorageService: SessionStorageService,
    private navbarService: NavbarService,
    private localStorageService: LocalStorageService,) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const token: string | null =
      this.localStorageService.retrieve('authenticationToken') ?? this.sessionStorageService.retrieve('authenticationToken');
      
      const auth:boolean | false= this.localStorageService.retrieve('auth');

      if(token && auth){
        return true
      }
      else{
        if (token) {
          this.navbarService.logout().subscribe(
            (res: HttpResponse<any>) => {
            this.localStorageService.clear();
            this.sessionStorageService.clear();
          },
          (err) => {}
        );
        } else{
          this.localStorageService.clear();
          this.sessionStorageService.clear();
        }
        this.router.navigate(['/login']);
        return false
      }
  }
}

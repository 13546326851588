import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentService } from '../payment-voucher/payment-voucher.service';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { FontService } from 'src/app/shared/font-service/font.service';
import { filterQuery } from 'src/app/shared/common.model';
import { BulkVoucher } from '../payment-voucher/payment-voucher.model';
import { SearchResult } from 'src/app/shared/models/search-result';
import { Subscription } from 'rxjs';
import { UserRoleService } from 'src/app/shared/service/user-role.service';

@Component({
  selector: 'app-bluk-payment-table',
  templateUrl: './bluk-payment-table.component.html',
  styleUrls: ['./bluk-payment-table.component.scss'],
})
export class BlukPaymentTableComponent implements OnInit {
  page: number;
  itemsPerPage: number = 10;
  searchResult: SearchResult<BulkVoucher> = new SearchResult<BulkVoucher>();
  userContextSubscription: Subscription;
  user: any;
  cols: any;
  paymentMethod: string;

  constructor(
    private userRoleService: UserRoleService,
    private route: ActivatedRoute,
    protected router: Router,
    protected paymentService: PaymentService,
    public fontService: FontService,
    public activatedRoute:ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.paymentMethod = this.activatedRoute.snapshot.queryParamMap.get('type');
    this.cols = [
      {
        field: 'voucherNumber',
        header: 'Voucher No',
        isFilterable: true,
        isSelectcolumn: true,
        jhiTranslate: 'tableHead.voucher.no',
      },
      {
        field: 'transactionDate',
        header: 'Voucher Date',
        isSortable: true,
        isSelectcolumn: true,
        type: 'date',
      },

      {
        field: 'paymentMethod',
        header: 'Payment Method',
        isSelectcolumn: true,
        isFilterable: true,
      },

      {
        field: 'signMessage',
        header: 'Status',
        isSelectcolumn: true,
        isFilterable: true,
      },
    ];
  }

  loadPage(page?: number, dontNavigate?: boolean): void {
    // this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    let Query = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
      levelMasterId: this.user.levelMaster.id,
      paymentMethod:this.paymentMethod,
      activeFlag:'Yes'
    };
    this.paymentService.getparentVoucherlist({ ...Query }).subscribe(
      (res: HttpResponse<BulkVoucher[]>) => {
        this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
      },
      () => {
        this.onError();
      }
    );
  }

  protected onSuccess(
    data: BulkVoucher[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    // this.spinner.hide();
    this.page = page;

    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void {}

  onLazyLoad(event) {
    this.userContextSubscription = this.userRoleService
      .getuserContext()
      .subscribe(
        (res) => {
          this.user = res;
          this.itemsPerPage = event.size;
          this.loadPage(event.page);
        },
        () => {}
      );
  }

  openNew() {
    this.paymentService.getVoucher().subscribe(
      (res: HttpResponse<any>) => {
        this.router.navigate(['/blukpaymentVoucher'], {
          relativeTo: this.route,
          queryParams: {
            VoucherNo: res.body,
            type:this.paymentMethod,
          },
        });
      },
      () => {}
    );
  }
  onSelectRow(event) {
    if (event.type === 'select') {
      this.router.navigate(['/blukpaymentVoucher'], {
        queryParams: {
          id: event.value.id,
          type:this.paymentMethod
        },
      });
    } else {
      this.paymentService.deletedVocher(event.value.id).subscribe(
        (res: HttpResponse<any>) => {
          this.loadPage(this.page);
        },
        () => {
          this.onError();
        }
      );
    }
  }
}

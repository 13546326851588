import { AfterViewInit, Component, HostListener, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AccountSnaService } from './account-sna.service';
import { AccountSna, AccountSnaDto } from './account-sna';
import { SearchResult } from "src/app/shared/models/search-result";
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { DepartmentListService } from '../department-list/department-list.service';
import { SchemeListService } from '../scheme-list/scheme-list.service';
import { DepartmentList } from '../department-list/department-list';
import { SchemeList } from '../scheme-list/scheme-list';
import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { Dialog } from 'primeng/dialog';
import { NotificationService } from 'src/app/alert/notification.service';
import { NgSelectComponent } from '@ng-select/ng-select';
@Component({
  selector: 'app-account-sna',
  templateUrl: './account-sna.component.html',
  styleUrls: ['./account-sna.component.scss']
})
export class AccountSnaComponent implements OnInit, AfterViewInit {

  @ViewChild('dialog') dialog: Dialog;

  accountSnaDialog: boolean = false;
  cols: any[];
  searchResult: SearchResult<AccountSna> = new SearchResult<AccountSna>();
  accountSna?: AccountSnaDto;
  selectedPersonalVendors: AccountSna[] = [];
  submitted?: boolean;
  selectedMapping: string;
  departmentList: DepartmentList[];
  schemeList: SchemeList[];

  itemsPerPage: any = 1;
  page: number;
  filterProps: any;

  // validation fields properties
  public showMobileError = false;
  public emailError = false;
  public ifscError = false;
  today: Date;

  //required fields property
  ngSelectInvalid: boolean = false;
  
  @ViewChildren(NgSelectComponent) ngSelectComponents: QueryList<NgSelectComponent>;
  viewOnly: boolean;
  @HostListener('document:wheel', ['$event'])
  onWheel(event: WheelEvent) {
    const targetElement = event.target as HTMLElement;
    const isInsideNgSelect = targetElement.closest('.ng-option');
    if (!isInsideNgSelect) {
      this.ngSelectComponents.forEach((ngSelect: NgSelectComponent) => {
      ngSelect.close();
    });
  }
}

  constructor(
    private accountSnaService: AccountSnaService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private departmentListService: DepartmentListService,
    private schemeListService: SchemeListService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService

  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.updateDialogFont();
    });
  }


  ngOnInit() {
    //this.accountSnaService.getaccountSnas().then(data => this.accountSnas = data);
    this.loadPage(1);
    this.cols = [
      { field: 'schemeBean', header: 'Scheme Name', jhiTranslate: 'tableHead.scheme.name', isSortable: false, isFilterable: false, width: 10, isSelectcolumn: true, type: "dropDown" },
      { field: 'shortName', header: 'Short Name',jhiTranslate: 'tableHead.short.name', isSortable: false, isFilterable: false, width: 25, isSelectcolumn: true },
      { field: 'code', header: 'Code',jhiTranslate: 'tableHead.code.name', isSortable: false, isFilterable: false, width: 15, isSelectcolumn: true },
      { field: 'departmentBean', header: 'Department Name',jhiTranslate: 'tableHead.department.name', isSortable: false, isFilterable: false, width: 13, isSelectcolumn: true, type: "dropDown" },
      { field: 'name', header: 'Name', jhiTranslate: 'tableHead.name',isSortable: false, isFilterable: false, width: 13, isSelectcolumn: true },
      { field: 'amount', header: 'Amount',jhiTranslate: 'tableHead.amount', isSortable: false, isFilterable: false, width: 13, isSelectcolumn: true, type:'amount' },
    ];
  }

  ngAfterViewInit() {
    this.updateDialogFont();
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }

  openNew() {
    this.accountSna = new AccountSnaDto();
    this.accountSnaDialog = true;
    this.loadValue()
  }

  hideDialog() {
    this.showMobileError = false;
    this.emailError = false;   
    this.ifscError = false;
    this.ngSelectInvalid = false;
    this.accountSna = new AccountSnaDto();    
    this.accountSnaDialog = false;
    this.submitted = false;
    this.viewOnly=false;
  }

  loadValue() {
    this.departmentListService
      .filter()
      .subscribe(
        (res: HttpResponse<DepartmentList[]>) => {
          this.departmentList = res.body
        },
        () => {
          this.onError();
        }
      );
    this.schemeListService
      .filter()
      .subscribe(
        (res: HttpResponse<SchemeList[]>) => {
          this.schemeList = res.body
        },
        () => {
          this.onError();
        }
      );
  }


  validateMobile() {
    this.showMobileError = !this.validationService.isMobileValid(this.accountSna.managerMobNo);
    !this.accountSna.managerMobNo ? this.showMobileError = false : true;

    return !this.showMobileError && !!this.accountSna.managerMobNo;
  }

  validateEmail() {
    this.emailError = !this.validationService.isEmailValid(this.accountSna.branchEmail)
    !this.accountSna.branchEmail ? this.emailError = false : true;
    return !this.emailError && !!this.accountSna.branchEmail;

  }

  validateIfsc() {
    this.ifscError = !this.validationService.isIfscValid(this.accountSna.ifscCode)
    !this.accountSna.ifscCode ? this.ifscError = false : true;
    return !this.ifscError && !!this.accountSna.ifscCode;


  }

  saveSna() {
    const isFormValid =
      this.validateMobile() &&
      this.validateEmail() &&
      this.validateIfsc() &&
      this.accountSna.departmentDto &&
      this.accountSna.schemeDto &&
      this.accountSna.name &&
      this.accountSna.code &&
      this.accountSna.shortName &&
      this.accountSna.accountNumber &&
      this.accountSna.ifscCode &&
      this.accountSna.branch &&
      this.accountSna.address &&
      this.accountSna.managerMobNo &&
      this.accountSna.managerName &&
      this.accountSna.branchEmail

    if (!isFormValid) {
      this.ngSelectInvalid = true;
      return;
    }

    this.ngSelectInvalid = false;
    this.accountSnaService
      .save(this.accountSna)
      .subscribe(
        (res: HttpResponse<AccountSnaDto[]>) => {
          this.accountSnaDialog = false;
          this.loadPage(this.page);
          this.notificationService.alertSuccess('Saved Successfully', '');
        },
        () => {
          this.onError();
          this.notificationService.alertError('Error!', '');
        }
      );
  }

  onSelectRow($event) {
    if ($event.type === 'select' || $event.type === 'view') {
      if($event.type === 'view'){
        this.viewOnly=true;
      }
      this.accountSnaService
        .getSna($event.value.id)
        .subscribe(
          (res: HttpResponse<any>) => {
            this.accountSna = res.body
            this.accountSnaDialog = true;
            this.loadValue()
          },
          () => {
            this.onError();
          }
        );
    }
    else {
      this.accountSnaService
        .delete($event.value.id)
        .subscribe(
          (res: HttpResponse<any>) => {
            this.loadPage(this.page)
          },
          () => {
            this.onError();
          }
        );
    }
  }

  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show()
    const pageToLoad: number = page ?? this.page ?? 1;

    let filterQuery = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    this.filterProps = this.filterProps ?? []
    this.accountSnaService
      .filter({ ...filterQuery }, this.filterProps)
      .subscribe(
        (res: HttpResponse<AccountSna[]>) => {
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        },
        () => {
          this.onError();
        }
      );
  }

  protected onSuccess(
    data: AccountSna[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide()
    this.page = page;
    if (navigate) {
      this.router.navigate(["/account-sna"], {
        queryParams: {
          page: this.page - 1,
          size: this.itemsPerPage,

        },
      });
    }
    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void {
  }

  onLazyLoad(event) {
    this.filterProps = event.filterDefinitions
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }
}

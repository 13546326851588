<div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <p-toolbar styleClass="toolbar">
          <ng-template pTemplate="left">
            <h5 [ngClass]="fontService.headingClass" >Local Body Contribution Payment List</h5>
          </ng-template>
          <ng-template pTemplate="right">
            <button *ngIf="!disableAdd" pButton pRipple icon="pi pi-plus" class="p-button-secondary custom-button  new-button" (click)="openNew()"
              styleClass="button">
              <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.newbutton">
              </span>
              </button>
          </ng-template>
        </p-toolbar>
      </div>
    </div>
    <div class="row">
      <div #customerTable id="entities" class="col-12 px-4">
        <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" (searchParam)="onLazyLoad($event)"
          [disableDeleteIcon]="true" [disableEditIcon]="true"></custom-filter-table>
      </div>
    </div>
    <p-dialog #dialog [(visible)]="lbcPaymentDialog" [modal]="true" (onHide)="hideDialog()" styleClass="ib-dialog"
      [style]="{ width: '850px' }">
      <p-header [ngClass]="fontService.headingClass">
       Add LBC Payment
      </p-header>
      <ng-template pTemplate="content">
            <div class="row gy-2">
              <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass"
                jhiTranslate="iACompo.limitTrans.scheme.name">Scheme</label>
                <ng-select [appendTo]="'.p-dialog'" id="scheme" [(ngModel)]="lbcdto.schemeDto" [items]="schemeList" bindLabel="name"
                  appearance="outline" [closeOnSelect]="true" class="form-control" [clearable]="true"
                  [ngClass]="{ 'is-invalid': ngSelectInvalid && !lbcdto.schemeDto }">
                </ng-select>
              </div>
              <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" for="gender" [ngClass]="fontService.labelClass"
                jhiTranslate="iACompo.limitTrans.fin.year.name">Fin Year</label>
                <ng-select [appendTo]="'.p-dialog'" id="gender" bindLabel="name" [items]="finYear" appearance="outline"
                  [(ngModel)]="lbcdto.finYearDto" [closeOnSelect]="true" [clearable]="true" class="form-control"
                  [ngClass]="{ 'is-invalid': ngSelectInvalid && !lbcdto.finYearDto }" (change)="onchangeFinYear()">
                </ng-select>
              </div>
              <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" for="district" [ngClass]="fontService.labelClass" jhiTranslate="dashBoard.district">District</label>
                <ng-select id="district" [(ngModel)]="districtMasterDto" [items]="this.districtLevelList" bindLabel="name" appearance="outline" (search)="onchangeFinYear($event)" 
                (change)="onDistrictChange()" [closeOnSelect]="true" [clearable]="false" class="form-control">
                </ng-select>
            </div>
              <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" for="block" [ngClass]="fontService.labelClass" jhiTranslate="dashBoard.block">Block</label>
                <ng-select id="block" [(ngModel)]="blockMasterDto" [items]="this.blockLevelList" bindLabel="name" appearance="outline" (search)="onDistrictChange($event)" 
                (change)="onBlockChange()" [closeOnSelect]="true" [clearable]="false" class="form-control">
                </ng-select>
            </div>
                <div class="col-sm-6 col-md-4">
                    <label class="form-control-label" for="scheme" [ngClass]="fontService.labelClass"
                        jhiTranslate="dashBoard.village">Village</label>
                    <ng-select id="scheme" [(ngModel)]="lbcdto.villagLevelMasterDto" [items]="this.villageLevelList"
                        bindLabel="name" appearance="outline" (change)="getAmount()" (search)="onBlockChange($event)"
                        [closeOnSelect]="true" [clearable]="false" class="form-control">
                    </ng-select>
                </div>
              
              <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" for="amount" [ngClass]="fontService.labelClass"
                jhiTranslate="iACompo.limitTrans.amount">Payment Amount</label>
                <input type="text" id="amount" name="amount" autocomplete="off" [disabled]="noLbc"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                  class="form-control" [(ngModel)]="lbcdto.lbcTransactionAmount" (blur)="checkLimit()"
                  [ngClass]="{ 'is-invalid': ngSelectInvalid && !lbcdto.lbcTransactionAmount }" />
              </div>
             
                 
              </div>
            <div class="text-end">
            <button pButton pRipple (click)="saveData()" icon="pi pi-check" class="p-button-text custom-button">
              <span
            class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.savebutton">
              </span>
            </button>
            </div>
      </ng-template>
    </p-dialog>
    <p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
  </div>